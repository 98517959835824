import React, { useEffect, useState } from 'react';
import { useCollection } from 'react-ketting';
import Loading from '../Loading';
import Error from '../Error';

// eslint-disable-next-line react/prop-types
const withUseCollection =
  (WrappedComponent) =>
  // eslint-disable-next-line react/prop-types
  ({ resource, ...props }) => {
    const [collection, setCollection] = useState([]);
    const { loading, error, items } = useCollection(resource);

    useEffect(() => {
      setCollection(items);

      return () => {
        setCollection([]);
      };
    }, [items]);

    if (loading) return <Loading />;
    if (error) return <Error msg={error.message} />;

    // eslint-disable-next-line react/prop-types
    const { children } = props;

    const newProps = {
      resource,
      collection,
    };

    return (
      <WrappedComponent {...newProps} {...props}>
        {children}
      </WrappedComponent>
    );
  };

export default withUseCollection;
