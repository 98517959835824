import { atom } from 'recoil';

const currentFormAtom = atom({
  key: 'currentFormState',
  default: {
    dynamicData: { updatedTimestamp: 0 },
    deepDependencies: { updatedTimestamp: 0 },
  },
});

export default currentFormAtom;
