import { atom, DefaultValue } from 'recoil';

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }
    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

const keycloakAtom = atom({
  key: 'keycloakState',
  default: {
    realm: 'initialiser',
    clientId: 'neolink',
    url: 'https://auth.neolink.link/auth/',
    token: 'Ceci est un token',
    refreshToken: '',
    uid: '',
    text: 'Test recoil :)',
    redirectionRealms: true,
  },
  effects_UNSTABLE: [localStorageEffect('current_user')],
});

export default keycloakAtom;
