import _pick from 'lodash/pick';

const getUsedFormData = (formData, fields) => {
  // for the case of a single input form
  if (fields.length === 0 && typeof formData !== 'object') {
    return formData;
  }

  const data = _pick(formData, fields);
  if (Array.isArray(formData)) {
    return Object.keys(data).map((key) => data[key]);
  }

  return data;
};

export default getUsedFormData;
