const traverseObject = (o, fn) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const i in o) {
    if (i) {
      fn.apply(this, [i, o[i]]);
      if (o[i] !== null && typeof o[i] === 'object') {
        traverseObject(o[i], fn);
      }
    }
  }
};

export default traverseObject;
