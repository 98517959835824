import jsonLogic from 'json-logic-js';
import findYByX from '../../../objectManipulation/findYByX';
import findByKeyValue from '../../../objectManipulation/findByKeyValue';
import safelyParseJSON from '../../../safelyParseJSON';

const averageOnAnswers = (array) => {
  const cleanArray = array.filter(
    (element) => element !== false && element !== ''
  );
  if (cleanArray.length > 0) {
    let sum = 0;
    cleanArray.map((value) => {
      sum += +value;
      return value;
    });
    return sum / cleanArray.length;
  }
  return 0;
};

const countKeyInArray = (array, key) => {
  const cleanArray = array.filter((element) => element === key);
  return cleanArray.length;
};

const extensionRating = (formdata, jsonSchema, recursiveAssignFormData) => {
  jsonLogic.add_operation('averageOnAnswers', averageOnAnswers);
  jsonLogic.add_operation('countKeyInArray', countKeyInArray);

  const rateJsonLogics = findYByX(
    jsonSchema,
    'idJsonLogicRateFormula',
    'jsonLogicRateFormula'
  );

  const copyRateJsonLogics = rateJsonLogics;

  copyRateJsonLogics.sort(
    (a, b) =>
      b.content['@source'].split('.').length -
      a.content['@source'].split('.').length
  );

  Promise.all(
    copyRateJsonLogics.map(async (rateJsonLogic, index) => {
      const elements = findByKeyValue(formdata, rateJsonLogic?.content?.rating);
      elements.map(async (_element, indexElement) => {
        const collection = elements.length > 1;
        Object.keys(rateJsonLogic?.content?.jsonLogicRateData).forEach(
          (key) => {
            let result = [];
            if (key === 'collectionOfItems') {
              result = findByKeyValue(formdata, rateJsonLogic.content.rating);
            } else {
              result = findByKeyValue(formdata, key);
            }
            if (result.length > 0) {
              if (typeof result[indexElement] === 'object') {
                copyRateJsonLogics[index].content.jsonLogicRateData[
                  indexElement
                ] = {
                  ...rateJsonLogic?.content.jsonLogicRateData[indexElement],
                  [key]:
                    '@JsonLogic_Rating_Result' in result[indexElement]
                      ? result[indexElement]['@JsonLogic_Rating_Result']
                      : JSON.stringify(result[indexElement]),
                };
              } else {
                copyRateJsonLogics[index].content.jsonLogicRateData[
                  indexElement
                ] = {
                  ...rateJsonLogic?.content.jsonLogicRateData[indexElement],
                  [key]: result[indexElement],
                };
              }
            }

            return null;
          }
        );

        const parsedFormula = safelyParseJSON(
          copyRateJsonLogics[index].content.jsonLogicRateFormula
        );

        await recursiveAssignFormData(
          formdata,
          rateJsonLogic?.content.rating,
          collection ? indexElement : null,
          '@JsonLogic_Rating_Result',
          jsonLogic.apply(
            parsedFormula,
            copyRateJsonLogics[index].content.jsonLogicRateData[indexElement]
          )
        );
        return null;
      });
      return null;
    })
  );
};

export default extensionRating;
