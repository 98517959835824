import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  CardActionArea,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useRecoilState } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Copyright from '../../Components/Copyright';
import AppBarComponent from '../../Components/AppBar';
import keycloakAtom from '../../Atoms/keycloakAtom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const Realms = ({ setApiUserService }) => {
  const classes = useStyles();
  const [atomKeycloak, setAtomKeycloak] = useRecoilState(keycloakAtom);
  const [redirection, setRedirection] = useState(false);
  const history = useHistory();
  // const [atomKeycloak] = useRecoilState(keycloakAtom);
  const keycloakClient = [
    {
      projet: 'Berger Levrault',
      realm: 'gru',
      url: 'https://blauth.berger-levrault.com/authc',
      clientId: 'neolink',
    },
    {
      projet: 'Berger Levrault Test',
      realm: 'gru_test',
      url: 'https://blauthtestv2.berger-levrault.fr/authc',
      clientId: 'neolink',
    },
    {
      projet: 'Neolink prod',
      realm: 'job_admin_prod',
      url: 'https://blauth.berger-levrault.com/authc',
      clientId: 'neoform',
    },
    {
      projet: 'Neolink demo',
      realm: 'job_admin_demo',
      url: 'https://blauth.berger-levrault.com/authc',
      clientId: 'neoform',
    },
    {
      projet: 'job62',
      realm: 'job62',
      url: 'https://auth.neolink.link/auth/',
      clientId: 'neolink',
    },
    {
      projet: 'job88_demo',
      realm: 'job88_demo',
      url: 'https://auth.neolink.link/auth/',
      clientId: 'neolink',
    },
    {
      projet: 'qanteenbl',
      realm: 'qanteenbl',
      url: 'https://auth.neolink.link/auth/',
      clientId: 'neolink',
    },
    {
      projet: 'senior41_demo',
      realm: 'senior41_demo',
      url: 'https://auth.neolink.link/auth/',
      clientId: 'neolink',
    },
    {
      projet: '_ctf_',
      realm: 'CTF',
      url: 'https://auth.neolink.link/auth/',
      clientId: 'neolink',
    },
  ];

  const modifierAtome = (realm, url, clientId) => {
    // Validité de 1 minute après le click
    const validityDate = new Date().setTime(
      new Date().getTime() + 60 * 1000 * 60
    );
    setRedirection(true);
    const clone = cloneDeep(atomKeycloak);
    clone.url = url;
    clone.realm = realm;
    clone.clientId = clientId;
    clone.redirectionRealms = false;
    clone.validityDate = validityDate;
    setAtomKeycloak(clone);
  };

  useEffect(() => {
    if (redirection) {
      setRedirection(false);
      history.push(
        `/?realm=${atomKeycloak.realm}&url=${atomKeycloak.url}&clientId=${atomKeycloak.clientId}`
      );
      setApiUserService(undefined);
    }
  }, [atomKeycloak]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarComponent />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container className={classes.container} maxWidth={false}>
          <Box>
            <Typography component='h1' variant='h5'>
              Consulté récemment
            </Typography>
            <Grid
              container
              alignItems='flex-start'
              direction='row'
              justifyContent='flex-start'
              spacing={2}
            >
              <Box sx={{ m: 1 }}>
                <Card>
                  <CardActionArea
                    // href={`/?realm=${projetKc.realm}&url=${projetKc.url}&clientId=${projetKc.clientId}`}
                    onClick={() => {
                      if (atomKeycloak.realm !== 'initialiser') {
                        modifierAtome(
                          atomKeycloak.realm,
                          atomKeycloak.url,
                          atomKeycloak.clientId
                        );
                      }
                    }}
                  >
                    <CardContent>
                      <Box
                        component='div'
                        overflow='hidden'
                        textOverflow='ellipsis'
                        width={1}
                      >
                        {` ${atomKeycloak.realm?.toUpperCase()}`}
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>
          </Box>
        </Container>

        <Container className={classes.container} maxWidth={false}>
          <Typography component='span' variant='h5'>
            Royaumes de connexion
          </Typography>
          <Grid
            container
            alignItems='flex-start'
            direction='row'
            justifyContent='flex-start'
          >
            {keycloakClient.map((projetKc, key) => (
              <Box key={key} sx={{ m: 1 }}>
                <Card>
                  <CardActionArea
                    // href={`/?realm=${projetKc.realm}&url=${projetKc.url}&clientId=${projetKc.clientId}`}
                    onClick={() => {
                      modifierAtome(
                        projetKc.realm,
                        projetKc.url,
                        projetKc.clientId
                      );
                    }}
                  >
                    <CardContent>
                      <Box
                        component='div'
                        overflow='hidden'
                        textOverflow='ellipsis'
                        width={1}
                      >
                        {projetKc.projet?.toUpperCase()}
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
            ))}
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
};
Realms.propTypes = {
  setApiUserService: PropTypes.func.isRequired,
};
export default Realms;
