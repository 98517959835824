/**
 * CTF: Formulaire Référentiel dynamique
 *
 * Dans le cadre de ce challenge, le jsonSchema devrait ressembler à quelque chose comme ça:
 *
 * {
 *   type: "object",
 *   required: [
 *   ],
 *   properties: {
 *     neolinkUsers: {
 *       type: "string",
 *       "@source": "neolinkUsers",
 *       "@dynamicData": {
 *         method: "GET",
 *         url: "https://jsonplaceholder.typicode.com/users",
 *         schemaJsonPath: "$[*]",
 *         bindKeysTo: "id",
 *         bindValuesTo: "name",
 *         "@dependentParams": false,
 *         "@source": "neolinkUsers",
 *       },
 *       "@verificatorData": {
 *         method: "GET",
 *         "@dependentParams": false,
 *       },
 *       title: "Liste des utilisateurs",
 *     },
 *   },
 * }
 */
export default ({ jsonSchema }) => {
  let flagName = null;

  if (jsonSchema?.properties?.neolinkUsers) {
    const dependency = jsonSchema.properties.neolinkUsers;

    if (
      dependency &&
      dependency?.['@dynamicData'] &&
      dependency?.['@dynamicData']?.method === 'GET' &&
      dependency?.['@dynamicData']?.url ===
        'https://jsonplaceholder.typicode.com/users' &&
      dependency?.['@dynamicData']?.schemaJsonPath === '$[*]' &&
      dependency?.['@dynamicData']?.bindKeysTo === 'id' &&
      dependency?.['@dynamicData']?.bindValuesTo === 'name'
    ) {
      flagName = 'dynamicRef';
    }
  }

  return flagName;
};
