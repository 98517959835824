import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { withTheme, utils } from '@rjsf/core';
import { Theme as MaterialUITheme } from '@rjsf/material-ui';
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

import { useSetRecoilState } from 'recoil';
import currentFormAtom from '../../../Atoms/currentForm';

import TemplateArray from '../NeoTemplate/Array';
import FieldSimpleTabs from '../NeoFields/Tabs/basic';
import FieldStepper from '../NeoFields/Tabs/stepper';
import findByKeyValue from '../../../Utils/objectManipulation/findByKeyValue';
import FieldAlertMessage from '../NeoFields/AlertMessage';
import FieldTimePicker from '../NeoFields/TimePicker';
import FieldDynamicList from '../NeoFields/DynamicByApi/List';
import FieldDynamicAutocomplete from '../NeoFields/DynamicByApi/Autocomplete';
import FieldDynamicBoxes from '../NeoFields/DynamicByApi/Boxes';
import Invisible from '../NeoFields/Invisible';
import FieldHtmlParser from '../NeoFields/Html/HtmlParser';
import Extensions from '../../../Utils/engine/extensions';
import neoValidators from '../../../Utils/engine/validateurs';
import neoDefaultValues from '../../../Utils/engine/defaultValues';
import getUsedFormData from '../../../Utils/objectManipulation/getUsedFormData';
import getFieldNames from '../../../Utils/objectManipulation/getFieldNames';
import neoModifiers from '../../../Utils/engine/modifiers';
import ErrorListFr from '../../ErrorListFr';
import NeoFormSkeleton from '../NeoFormSkeleton';

const localizeFr = require('ajv-i18n/localize/fr');

const Form = withTheme(MaterialUITheme);

const useStyles = makeStyles(() => ({
  '@global': {
    '.rjsf': {
      display: 'flex',
      'flex-direction': 'column',
    },
  },
}));

const NeoFormUse = (props) => {
  const { JsonSchema, UiSchema, formDataReceived, extensions } = props;
  const [extensionsExecuted, setExtensionsExecuted] = useState(false);

  const [currentJSchema, setCurrentJSchema] = useState(JsonSchema);
  const [currentUiSchema, setCurrentUiSchema] = useState(UiSchema);
  const [currentFormData, setCurrentFormData] = useState(formDataReceived);

  const setCurrentFormAtom = useSetRecoilState(currentFormAtom);

  useStyles();

  useEffect(async () => {
    await Extensions.init(
      extensions,
      currentFormData,
      JsonSchema,
      currentUiSchema,
      setCurrentUiSchema,
      currentJSchema,
      setCurrentJSchema,
      setCurrentFormAtom,
      setCurrentFormData
    );
    setExtensionsExecuted(true);
  }, []);

  if (!extensionsExecuted) {
    return <NeoFormSkeleton />;
  }
  return <NeoFormRender {...props} />;
};

NeoFormUse.propTypes = {
  JsonSchema: PropTypes.object.isRequired,
  UiSchema: PropTypes.object.isRequired,
  formDataReceived: PropTypes.object.isRequired,
  extensions: PropTypes.array,
};

const NeoFormRender = (props) => {
  const {
    id,
    callback,
    fields,
    formAccess,
    JsonSchema,
    UiSchema,
    formDataReceived,
    customSubmit,
    handleSubmit,
    handleChange,
    handleValidation,
    muiTheme,
    builder,
    extensions,
    liveValidation,
    omitExtraData,
    extraErrors,
    language,
  } = props;

  const topForm = useRef();
  const [formData, setFormData] = useState(formDataReceived);
  const [currentJSchema, setCurrentJSchema] = useState(JsonSchema);
  const [currentUiSchema, setCurrentUiSchema] = useState(UiSchema);

  const setCurrentFormAtom = useSetRecoilState(currentFormAtom);

  useEffect(() => {
    console.log(`Form ${id} is mounted.`);
    callback();
  }, []);

  useEffect(() => {
    async function asyncDefaultValue() {
      const newFormData = await neoDefaultValues(
        currentJSchema,
        formDataReceived
      );
      setFormData(newFormData);
      return newFormData;
    }
    asyncDefaultValue();
  }, [formDataReceived]);

  useEffect(() => {
    setCurrentJSchema(JsonSchema);
    handleChange(
      formData,
      currentJSchema,
      setCurrentJSchema,
      currentUiSchema,
      setCurrentUiSchema,
      setFormData
    );
  }, [JsonSchema]);

  useEffect(() => {
    setCurrentUiSchema(UiSchema);
    handleChange(
      formData,
      currentJSchema,
      setCurrentJSchema,
      currentUiSchema,
      setCurrentUiSchema,
      setFormData
    );
  }, [UiSchema]);

  const onChange = (e) => {
    Extensions.checkOnChangeExtensions(
      extensions,
      e.formData,
      JsonSchema,
      UiSchema,
      setCurrentUiSchema,
      currentJSchema,
      setCurrentJSchema,
      setCurrentFormAtom,
      setFormData
    );
    handleChange(
      e.formData,
      JsonSchema,
      setCurrentJSchema,
      UiSchema,
      setCurrentUiSchema,
      setFormData,
      formData
    );
  };

  const debounceHandleOnChange = debounce(onChange, 400);

  const transformErrorMessage = (message) => {
    if (message.includes('requiert la propriété')) {
      return 'est requis.';
    }
    return message;
  };

  const transformErrors = (errors) =>
    errors.map((error) => {
      const errorPaths = error.property.split(/'|\./);
      const paths = errorPaths.map((errorPath) =>
        errorPath.replace(/\[|\]|\./gi, '')
      );
      const clearPaths = paths.filter((x) => x);
      const reformattedPropertyPath = clearPaths.map((clearPath) => {
        const objectFound = findByKeyValue(JsonSchema, clearPath);
        if (objectFound.length >= 1) {
          const temp = objectFound.map((object) => object?.title || null);
          const clearTemp = temp.filter((x) => x);

          return clearTemp?.[0] || null;
        }
        return null;
      });
      // eslint-disable-next-line no-param-reassign
      error.property = reformattedPropertyPath.filter((x) => x).join(' / ');
      // eslint-disable-next-line no-param-reassign
      error.message = transformErrorMessage(error.message);
      return error;
    });

  const onValidate = (_formData, _errors) => {
    neoValidators(currentJSchema, _formData, currentUiSchema, _errors);

    // Attention handeValdiation doit toujours retourner _errors
    if (handleValidation) {
      return handleValidation(_formData, _errors, formDataReceived);
    }
    return _errors;
  };

  const onSubmit = (fd, js) => {
    let filteredFormData = fd;
    if (omitExtraData) {
      const retrievedSchema = utils.retrieveSchema(js, js, fd);
      const pathSchema = utils.toPathSchema(retrievedSchema, '', js, fd);
      const fieldNames = getFieldNames(pathSchema, fd);
      filteredFormData = getUsedFormData(fd, fieldNames);
    }
    neoModifiers(js, filteredFormData);
    Extensions.checkSubmitExtensions(extensions, filteredFormData, js);
    handleSubmit(filteredFormData, js);
  };

  if (!formAccess?.read && !formAccess?.write) return null;

  if (!builder) {
    const theme = createTheme(muiTheme);

    if (formAccess?.read && !formAccess?.write) {
      return (
        <ThemeProvider theme={theme}>
          <div ref={topForm} />
          <Form
            id={id}
            {...(formAccess?.write && formAccess.write
              ? { disabled: false }
              : { disabled: true })}
            {...(language && language !== 'fr'
              ? {}
              : { localizeErrors: localizeFr, ErrorList: ErrorListFr })}
            ArrayFieldTemplate={TemplateArray}
            extraErrors={extraErrors}
            fields={fields}
            formData={formData}
            liveValidate={liveValidation}
            schema={currentJSchema}
            transformErrors={transformErrors}
            uiSchema={currentUiSchema}
            validate={onValidate}
            onChange={(e) => debounceHandleOnChange(e)}
            onError={() => {
              if (topForm?.current) {
                topForm.current.scrollIntoView({ behavior: 'smooth' });
              }
            }}
            onSubmit={(e) => onSubmit(e.formData, currentJSchema)}
          >
            <></>
          </Form>
        </ThemeProvider>
      );
    }
    if (customSubmit) {
      return (
        <ThemeProvider theme={theme}>
          <div ref={topForm} />
          <Form
            id={id}
            {...(formAccess?.write && formAccess.write
              ? { disabled: false }
              : { disabled: true })}
            {...(language && language !== 'fr'
              ? {}
              : {
                  localizeErrors: localizeFr,
                  ErrorList: ErrorListFr,
                })}
            ArrayFieldTemplate={TemplateArray}
            extraErrors={extraErrors}
            fields={fields}
            formData={formData}
            liveValidate={liveValidation}
            schema={currentJSchema}
            transformErrors={transformErrors}
            uiSchema={currentUiSchema}
            validate={onValidate}
            onChange={(e) => debounceHandleOnChange(e)}
            onError={() => {
              if (topForm?.current) {
                topForm.current.scrollIntoView({ behavior: 'smooth' });
              }
            }}
            onSubmit={(e) => onSubmit(e.formData, currentJSchema)}
          >
            {customSubmit}
          </Form>
        </ThemeProvider>
      );
    }
    return (
      <ThemeProvider theme={theme}>
        <div ref={topForm} />
        <Form
          id={id}
          {...(formAccess.includes('w')
            ? { disabled: false }
            : { disabled: true })}
          {...(language && language !== 'fr'
            ? {}
            : { localizeErrors: localizeFr, ErrorList: ErrorListFr })}
          ArrayFieldTemplate={TemplateArray}
          extraErrors={extraErrors}
          fields={fields}
          formData={formData}
          liveValidate={liveValidation}
          schema={currentJSchema}
          transformErrors={transformErrors}
          uiSchema={currentUiSchema}
          validate={onValidate}
          onChange={(e) => debounceHandleOnChange(e)}
          onError={() => {
            if (topForm?.current) {
              topForm.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          onSubmit={(e) => onSubmit(e.formData, currentJSchema)}
        />
      </ThemeProvider>
    );
  }

  if (customSubmit) {
    return (
      <>
        <div ref={topForm} />
        <Form
          id={id}
          {...(language && language !== 'fr'
            ? {}
            : { localizeErrors: localizeFr, ErrorList: ErrorListFr })}
          ArrayFieldTemplate={TemplateArray}
          extraErrors={extraErrors}
          fields={fields}
          formData={formData}
          liveValidate={liveValidation}
          schema={currentJSchema}
          transformErrors={transformErrors}
          uiSchema={currentUiSchema}
          validate={onValidate}
          onChange={(e) => debounceHandleOnChange(e)}
          onError={() => {
            if (topForm?.current) {
              topForm.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          onSubmit={(e) => onSubmit(e.formData, currentJSchema)}
        >
          {customSubmit}
        </Form>
      </>
    );
  }
  return (
    <>
      <div ref={topForm} />
      <Form
        id={id}
        ref={topForm}
        {...(language && language !== 'fr'
          ? {}
          : { localizeErrors: localizeFr, ErrorList: ErrorListFr })}
        ArrayFieldTemplate={TemplateArray}
        extraErrors={extraErrors}
        fields={fields}
        formData={formData}
        liveValidate={liveValidation}
        schema={currentJSchema}
        transformErrors={transformErrors}
        uiSchema={currentUiSchema}
        validate={onValidate}
        onChange={(e) => debounceHandleOnChange(e)}
        onError={() => {
          if (topForm?.current) {
            topForm.current.scrollIntoView({ behavior: 'smooth' });
          }
        }}
        onSubmit={(e) => onSubmit(e.formData, currentJSchema)}
      />
    </>
  );
};

NeoFormRender.defaultProps = {
  id: '',
  callback: () => {},
  formAccess: {
    read: true,
    write: true,
  },
  fields: {
    NeoSimpleTabs: FieldSimpleTabs,
    NeoStepper: FieldStepper,
    NeoAlertMessage: FieldAlertMessage,
    NeoDynamicList: FieldDynamicList,
    NeoDynamicAutocomplete: FieldDynamicAutocomplete,
    NeoDynamicBoxes: FieldDynamicBoxes,
    NeoHtmlParser: FieldHtmlParser,
    NeoInvisible: Invisible,
    NeoTimePicker: FieldTimePicker,
  },
  handleChange: () => {},
  handleValidation: (_formData, _errors, _formDataReceived) => _errors,
  muiTheme: {
    palette: {
      common: { black: 'rgba(0, 0, 0, 1)', white: '#fff' },
      background: { paper: 'rgba(245, 247, 249, 1)', default: '#fafafa' },
      primary: {
        light: 'rgba(105, 198, 228, 1)',
        main: 'rgba(0, 56, 90, 1)',
        dark: 'rgba(105, 198, 228, 1)',
        contrastText: 'rgba(255, 255, 255, 1)',
      },
      secondary: {
        light: 'rgba(234, 101, 54, 1)',
        main: 'rgba(234, 101, 54, 1)',
        dark: 'rgba(212, 70, 20, 1)',
        contrastText: '#fff',
      },
      error: {
        light: '#e57373',
        main: 'rgba(243, 77, 51, 1)',
        dark: '#d32f2f',
        contrastText: '#fff',
      },
      text: {
        primary: 'rgba(78, 78, 78, 1)',
        secondary: 'rgba(0, 80, 120, 1)',
        disabled: 'rgba(0, 0, 0, 0.38)',
        hint: 'rgba(0, 0, 0, 0.38)',
      },
    },
  },
  builder: false,
  liveValidation: false,
  omitExtraData: false,
};

NeoFormRender.propTypes = {
  id: PropTypes.string,
  callback: PropTypes.func,
  fields: PropTypes.object,
  formAccess: PropTypes.object,
  JsonSchema: PropTypes.object.isRequired,
  UiSchema: PropTypes.object,
  formDataReceived: PropTypes.object,
  customSubmit: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  handleValidation: PropTypes.func,
  muiTheme: PropTypes.object,
  builder: PropTypes.bool,
  extensions: PropTypes.array,
  liveValidation: PropTypes.bool,
  omitExtraData: PropTypes.bool,
  extraErrors: PropTypes.object,
  language: PropTypes.string,
};

export default NeoFormUse;
