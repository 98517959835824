import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { useSetRecoilState } from 'recoil';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import BlockIcon from '@material-ui/icons/Block';
import snackbarAtom from '../../../Atoms/snackbarAtom';
import Menu from '../../Menu';
import Builder from '../../Menu/Builder';
import NeoBuilderStyle from './NeoBuilderStyle';
import PopupDialog from '../../PopupDialog';
import editAction from './Utils/FieldActions/edit';
import copyAction from './Utils/FieldActions/copy';
import deleteAction from './Utils/FieldActions/delete';
import exportAction from './Utils/FieldActions/export';
import dragAndDropAction from './Utils/BuilderActions/dragAndDrop';
import cancelAction from './Utils/BuilderActions/cancel';
import renameAction from './Utils/BuilderActions/rename';

const NeoBuilder = ({
  localMetaSpecParent,
  builderZoomParent,
  handlePreExportField,
  handleExportField,
  handleSaveBuilder,
  importFieldBuilderSchemas,
  unsaved,
}) => {
  const classes = NeoBuilderStyle();
  const { t } = useTranslation();
  const { unsavedChange, setUnsavedChange, savedBuilderSchema, savedFormName } =
    unsaved;
  const { localMetaSpec, setLocalMetaSpec } = localMetaSpecParent;
  const { builderZoom, setBuilderZoom } = builderZoomParent;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({});

  const setAtomSnackbar = useSetRecoilState(snackbarAtom);

  // Init field actions
  const editElement = editAction(
    localMetaSpec,
    setLocalMetaSpec,
    importFieldBuilderSchemas,
    setBuilderZoom,
    setDialogConfig,
    setDialogOpen,
    setAtomSnackbar
  );

  const copyElement = copyAction(
    editElement,
    localMetaSpec,
    setLocalMetaSpec,
    setAtomSnackbar
  );

  const deleteElement = deleteAction(
    localMetaSpec,
    setLocalMetaSpec,
    setAtomSnackbar
  );

  const flagElement = exportAction(
    handlePreExportField,
    handleExportField,
    setDialogConfig,
    setDialogOpen,
    setAtomSnackbar
  );

  // Init builder actions
  const { onDragEnd } = dragAndDropAction(
    editElement,
    localMetaSpec,
    setLocalMetaSpec
  );

  const { onCancelAction } = cancelAction(
    localMetaSpec,
    setLocalMetaSpec,
    savedFormName,
    savedBuilderSchema,
    setUnsavedChange,
    setAtomSnackbar
  );

  const { onChangeFormName } = renameAction(
    localMetaSpec,
    setLocalMetaSpec,
    savedFormName,
    unsavedChange,
    setUnsavedChange
  );

  return (
    <>
      <Grid container className={classes['container-form-header']}>
        <Grid item className={classes['container-form-info']} sm={8} xs={12}>
          <TextField
            id='outlined-disabled'
            label={t('NeoBuilder.Edit.FormName')}
            size='small'
            value={localMetaSpec.formName}
            variant='outlined'
            onChange={(e) => onChangeFormName(e)}
          />
        </Grid>

        <Grid item className={classes['container-form-actions']} sm={4} xs={12}>
          {(unsavedChange && (
            <Tooltip title={t('NeoBuilder.Edit.CancelChanges')}>
              <Button
                className={classes.cancelButton}
                color='secondary'
                variant='contained'
                onClick={() => {
                  onCancelAction();
                }}
              >
                <BlockIcon fontSize='small' />
              </Button>
            </Tooltip>
          )) || (
            <Button
              disabled
              className={classes.cancelButton}
              color='secondary'
              variant='contained'
            >
              <BlockIcon fontSize='small' />
            </Button>
          )}

          <Button
            className={classes.saveButton}
            color='primary'
            disabled={!unsavedChange}
            startIcon={<SaveIcon />}
            variant='contained'
            onClick={() => handleSaveBuilder(localMetaSpec)}
          >
            {t('NeoBuilder.Edit.Save')}
          </Button>
        </Grid>
      </Grid>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container>
          {localMetaSpec.menuOrder.map((menuId, index) => {
            const menu = localMetaSpec.menus[menuId];
            const elements = menu.elements.map((element) => element);

            if (menu?.type === 'builder') {
              return (
                <Builder
                  actions={{
                    edit: editElement,
                    flag: flagElement,
                    copy: copyElement,
                    delete: deleteElement,
                  }}
                  builderZoom={builderZoom}
                  classes={classes}
                  elements={
                    builderZoom?.blueprint
                      ? builderZoom.blueprint.elements
                      : elements
                  }
                  key={index}
                  localMetaSpec={localMetaSpec}
                  menu={builderZoom?.blueprint || menu}
                  setBuilderZoom={setBuilderZoom}
                />
              );
            }
            return (
              <Menu
                actions={{
                  edit: editElement,
                  copy: copyElement,
                  delete: deleteElement,
                  flag: flagElement,
                }}
                classes={classes}
                elements={elements}
                key={index}
                menu={menu}
              />
            );
          })}
        </Grid>
        <PopupDialog config={dialogConfig} open={dialogOpen} />
      </DragDropContext>
    </>
  );
};

NeoBuilder.propTypes = {
  handleExportField: PropTypes.func,
  handlePreExportField: PropTypes.func,
  handleSaveBuilder: PropTypes.func.isRequired,
  importFieldBuilderSchemas: PropTypes.func.isRequired,
  unsaved: PropTypes.shape({
    unsavedChange: PropTypes.bool.isRequired,
    setUnsavedChange: PropTypes.func.isRequired,
    savedBuilderSchema: PropTypes.object.isRequired,
    savedFormName: PropTypes.string.isRequired,
    setSavedFormName: PropTypes.func.isRequired,
  }).isRequired,
  localMetaSpecParent: PropTypes.shape({
    localMetaSpec: PropTypes.object.isRequired,
    setLocalMetaSpec: PropTypes.func.isRequired,
  }).isRequired,
  builderZoomParent: PropTypes.shape({
    builderZoom: PropTypes.any.isRequired,
    setBuilderZoom: PropTypes.func.isRequired,
  }).isRequired,
};

export default NeoBuilder;
