/**
 * CTF: Utiliser un champ partagé par copie
 */
export default ({ formBuilder }) => {
  let flagName = null;

  if (formBuilder?.blueprint?.elements?.length > 0) {
    const { elements } = formBuilder.blueprint;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      if (element.id === 'neolinkNumTelMobileFrancais') {
        const preEditAction = element.blueprint.actions.find(
          (x) => x.id === 'preedit'
        );

        if (preEditAction?.formData?.method === 'copy') {
          flagName = 'useSharedFieldByCopy';
        }
      }
    }
  }

  return flagName;
};
