import React, { useState, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { RecoilRoot, useRecoilState } from 'recoil';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import './index.css';
import './i18n';
import snackbarAtom from './Atoms/snackbarAtom';
import keycloakAtom from './Atoms/keycloakAtom';
import ConnectedLayout from './Layouts/Connected';
import Realms from './Layouts/Realms';
import PrivateRoute from './Components/PrivateRoute/PrivateRoute';
import FlagPrinter from './Components/FlagPrinter';
import NeoBrowserView from './Views/NeoBrowserView';
import FormPreviewView from './Views/FormPreviewView';
import UserService from './Utils/service/UserService';

const theme = createTheme({
  palette: {
    common: { black: 'rgba(78, 78, 78, 1)', white: '#fff' },
    background: { paper: 'rgba(245, 247, 249, 1)', default: '#fafafa' },
    primary: {
      light: 'rgba(122, 188, 221, 1)',
      main: 'rgba(54, 161, 214, 1)',
      dark: 'rgba(41, 122, 163, 1)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(249, 164, 36, 1)',
      main: 'rgba(249, 164, 36, 1)',
      dark: 'rgba(245, 136, 32, 1)',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(78, 78, 78, 1)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const AppContent = () => {
  const [atomSnackbar, setAtomSnackbar] = useRecoilState(snackbarAtom);
  const [atomKeycloak, setAtomKeycloak] = useRecoilState(keycloakAtom);
  const handleCloseSnackbar = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAtomSnackbar((prevAtomSnackbar) => ({
      ...prevAtomSnackbar,
      open: false,
    }));
  };

  // init component UserService
  const [apiUserService, setApiUserService] = useState();

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={null}>
        {!apiUserService && (
          <UserService
            atomKeycloak={atomKeycloak}
            setApiUserService={setApiUserService}
            setAtomKeycloak={setAtomKeycloak}
          />
        )}
        {apiUserService && (
          <Router>
            <Switch>
              <Route exact path='/realms'>
                <Realms setApiUserService={setApiUserService} />
              </Route>
              <PrivateRoute
                apiUserService={apiUserService}
                LayoutComponent={<ConnectedLayout />}
                path='/preview'
                ViewComponent={<FormPreviewView />}
              />
              <PrivateRoute
                apiUserService={apiUserService}
                LayoutComponent={<ConnectedLayout />}
                path='*'
                ViewComponent={<NeoBrowserView />}
              />
            </Switch>
          </Router>
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          autoHideDuration={6000}
          open={atomSnackbar?.open}
          onClose={handleCloseSnackbar}
        >
          <Alert
            severity={atomSnackbar?.severity}
            onClose={handleCloseSnackbar}
          >
            {atomSnackbar?.text}
          </Alert>
        </Snackbar>
        <FlagPrinter />
      </Suspense>
    </ThemeProvider>
  );
};

const App = () => (
  <RecoilRoot>
    <AppContent />
  </RecoilRoot>
);

export default App;
