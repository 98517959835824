import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const LoadingStyle = makeStyles(() => ({
  skeletonProduct: {
    margin: 16,
  },
}));

function Loading({ type }) {
  const classes = LoadingStyle();

  switch (type) {
    case 'Breadcrumb':
      return (
        <div>
          <Skeleton
            className={classes.skeletonProduct}
            height={30}
            variant='rect'
            width={250}
          />
        </div>
      );
    default:
      return (
        <div>
          <Skeleton
            className={classes.skeletonProduct}
            height={130}
            variant='rect'
            width={120}
          />
        </div>
      );
  }
}
Loading.defaultProps = {
  type: 'default',
};

Loading.propTypes = {
  type: PropTypes.string,
};

export default Loading;
