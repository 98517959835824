import { get, set, cloneDeep } from 'lodash';
import moment from 'moment';
import findYByX from '../../objectManipulation/findYByX';

const detectSpecialValues = async (jsonSchema) => {
  if (jsonSchema?.content === '@datenow') {
    if (
      jsonSchema?.defaultDateAdd === true &&
      jsonSchema?.defaultDateAddNumber &&
      jsonSchema?.defaultDateAddUnit
    ) {
      return moment()
        .add(jsonSchema?.defaultDateAddNumber, jsonSchema?.defaultDateAddUnit)
        .format('YYYY-MM-DD');
    }
    return moment().format('YYYY-MM-DD');
  }
  if (jsonSchema?.content) {
    return jsonSchema.content;
  }
  return null;
};

// const detectSpecialValues = async (jsonSchema) => {
//   if (jsonSchema?.content === '@datenow') {
//     return new Date().toISOString().slice(0, 10);
//   }
//   if (jsonSchema?.content) {
//     return jsonSchema.content;
//   }
//   return null;
// };

const defaultValues = async (jsonSchema, formData) => {
  const toSet = findYByX(jsonSchema, 'id', '@defaultValue');

  let newFormdata = cloneDeep(formData);
  await Promise.all(
    toSet.map(async (element) => {
      const defaultValue = await detectSpecialValues(element?.content);
      const formDataAnswer = get(newFormdata, element?.content?.['@source']);

      if (
        defaultValue !== null &&
        (formDataAnswer === undefined || formDataAnswer.length === 0)
      ) {
        newFormdata = set(
          newFormdata,
          element?.content?.['@source'],
          defaultValue
        );
      }
      return null;
    })
  );

  return newFormdata;
};

export default defaultValues;
