import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

const PopupDialog = ({ open, onClose, config }) => {
  if (!config) return null;

  const { title, content, actions, props } = config;

  return (
    <Dialog fullWidth maxWidth='md' open={open} onClose={onClose} {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

PopupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.node,
    actions: PropTypes.node,
    props: PropTypes.object,
  }),
};

export default PopupDialog;
