import jsonLogic from 'json-logic-js';
import moment from 'moment';
import findYByX from '../../../objectManipulation/findYByX';
import findByKeyValue from '../../../objectManipulation/findByKeyValue';
// import objectDepth from '../../../objectManipulation/objectDepth';
import safelyParseJSON from '../../../safelyParseJSON';

const concatArrayWithLineBreak = (array) => {
  const cleanArray = array.filter(
    (element) => element !== false && element !== '' && element !== null
  );
  if (cleanArray.length > 0) {
    const text = cleanArray.reduce((accumulator, currentValue) => {
      if (currentValue !== null) {
        return `${accumulator}\n${currentValue}`;
      }
      return accumulator;
    });
    return text;
  }
  return '';
};

const concatWithLineBreak = (string1, string2) => `${string1}\n${string2}`;

const transformDate = (date, format) => moment(new Date(date)).format(format);

const parseArray = (stringArray, ignoreItems = []) => {
  const array = JSON.parse(stringArray);

  if (array) {
    const cleanArray = array.filter(
      (element) => !ignoreItems.includes(element)
    );
    // eslint-disable-next-line arrow-body-style
    const text = cleanArray.reduce((accumulator, currentValue, index) => {
      if (index + 1 === cleanArray.length) {
        return `${accumulator} et ${currentValue}`;
      }
      return `${accumulator}, ${currentValue}`;
    });
    return text;
  }
  return '';
};

const parseCollectionOfItems = (stringObject, toParse) => {
  const array = safelyParseJSON(stringObject);

  if (array) {
    // eslint-disable-next-line arrow-body-style
    const text = array.map((element) => {
      const content = toParse.map((currentValue) => {
        if (element?.[currentValue]?.['@JsonLogic_Literary_Result']) {
          return `${element?.[currentValue]?.['@JsonLogic_Literary_Result']}`;
        }
        return '';
      });
      return `\n - ${content.join(', ')}`;
    });
    return text.join('');
  }
  return stringObject;
};

const extensionLiterary = (formdata, jsonSchema, recursiveAssignFormData) => {
  jsonLogic.add_operation('concatArrayWithLineBreak', concatArrayWithLineBreak);
  jsonLogic.add_operation('concatWithLineBreak', concatWithLineBreak);
  jsonLogic.add_operation('parseArray', parseArray);
  jsonLogic.add_operation('parseCollectionOfItems', parseCollectionOfItems);
  jsonLogic.add_operation('transformDate', transformDate);
  jsonLogic.add_operation('toLowerCase', (element) => element.toLowerCase());

  const literaryJsonLogics = findYByX(
    jsonSchema,
    'idJsonLogicLiteraryFormula',
    'jsonLogicLiteraryFormula'
  );

  const copyLiteraryJsonLogics = literaryJsonLogics;

  copyLiteraryJsonLogics.sort(
    (a, b) =>
      b.content['@source'].split('.').length -
      a.content['@source'].split('.').length
  );

  Promise.all(
    copyLiteraryJsonLogics.map(async (literaryJsonLogic, index) => {
      const elements = findByKeyValue(
        formdata,
        literaryJsonLogic?.content?.literary
      );
      elements.map(async (_element, indexElement) => {
        const collection = elements.length > 1;
        Object.keys(literaryJsonLogic?.content?.jsonLogicLiteraryData).forEach(
          (key) => {
            let result = [];
            if (key === 'collectionOfItems') {
              result = findByKeyValue(
                formdata,
                literaryJsonLogic.content.literary
              );
            } else {
              result = findByKeyValue(formdata, key);
            }
            if (result.length > 0) {
              if (typeof result[indexElement] === 'object') {
                copyLiteraryJsonLogics[index].content.jsonLogicLiteraryData[
                  indexElement
                ] = {
                  ...literaryJsonLogic.content.jsonLogicLiteraryData[
                    indexElement
                  ],
                  [key]:
                    '@JsonLogic_Literary_Result' in result[indexElement]
                      ? result[indexElement]['@JsonLogic_Literary_Result']
                      : JSON.stringify(result[indexElement]),
                };
              } else {
                copyLiteraryJsonLogics[index].content.jsonLogicLiteraryData[
                  indexElement
                ] = {
                  ...literaryJsonLogic.content.jsonLogicLiteraryData[
                    indexElement
                  ],
                  [key]: result[indexElement],
                };
              }
            }

            return null;
          }
        );
        const parsedFormula = safelyParseJSON(
          copyLiteraryJsonLogics[index].content.jsonLogicLiteraryFormula
        );

        await recursiveAssignFormData(
          formdata,
          literaryJsonLogic.content.literary,
          collection ? indexElement : null,
          '@JsonLogic_Literary_Result',
          jsonLogic.apply(
            parsedFormula,
            copyLiteraryJsonLogics[index].content.jsonLogicLiteraryData[
              indexElement
            ]
          )
        );
        return null;
      });
      return null;
    })
  );
};

export default extensionLiterary;
