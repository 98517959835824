import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker as MUITimePicker,
} from '@material-ui/pickers';
import { parse, format as formatAsTimeString } from 'date-fns';

// Format d'affichage et de saisie d'une heure
const timeFormat = 'HH:mm';

/**
 * Check if a date is valid or not
 *
 * @param {*} d
 * @returns
 */
const dateIsValid = (d) => {
  let isValid = false;

  // is it a date
  if (Object.prototype.toString.call(d) === '[object Date]') {
    if (!Number.isNaN(d.valueOf())) {
      isValid = true;
    }
  }

  return isValid;
};

// const getNearestQuarter = () => {
/*
if minute > 52 -> 00
if minute > 37 -> 45
if minute > 22 -> 30
if minute > 07 -> 15
else -> 00
*/
// };

/**
 * Composant TimePicker
 *
 * @param {*} props
 * @returns ReactNode
 */
const FieldTimePicker = (props) => {
  const [localTime, setLocalTime] = useState(null);

  const { formData, onChange, schema, idSchema } = props;
  const { title } = schema;
  const id = idSchema?.$id || props?.name || null;

  useEffect(() => {
    let parsedTime = null;

    if (formData) {
      parsedTime = parse(formData, timeFormat, new Date());
    }

    setLocalTime(parsedTime);
  }, []);

  /**
   * Handle Time selection
   *
   * @param {*} date
   */
  const handleAccept = (date) => {
    if (onChange instanceof Function) {
      // On ne prend que la partie "heure:minute" de la date, si valide
      const formatted = dateIsValid(date)
        ? formatAsTimeString(date, timeFormat)
        : '';

      onChange(formatted);
    }
  };

  /**
   * Handle time change
   *
   * @param {*} date
   */
  const handleChange = (date) => {
    setLocalTime(date);

    // Pour la saisie au clavier
    if (dateIsValid(date)) {
      handleAccept(date);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MUITimePicker
        clearable
        ampm={false}
        cancelLabel='Annuler'
        clearLabel='Réinitialiser'
        emptyLabel={timeFormat}
        format={timeFormat}
        id={id}
        invalidDateMessage="Format d'heure invalide"
        label={title}
        minutesStep={15}
        okLabel='Valider'
        value={localTime}
        onAccept={handleAccept}
        onChange={handleChange}
      />
    </MuiPickersUtilsProvider>
  );
};

FieldTimePicker.propTypes = {
  name: PropTypes.string,
  formData: PropTypes.any,
  onChange: PropTypes.func,
  schema: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  idSchema: PropTypes.shape({
    $id: PropTypes.string,
  }),
};

export default FieldTimePicker;
