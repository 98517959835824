import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ErrorIcon from '@material-ui/icons/Error';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';

const ErrorListFr = ({ errors }) => (
  <Paper elevation={2}>
    <Box mb={2} p={2}>
      <Typography variant='h6'>Erreurs</Typography>
      <List dense>
        {errors.map((error, i) => (
          <ListItem key={i}>
            <ListItemIcon>
              <ErrorIcon color='error' />
            </ListItemIcon>
            <ListItemText primary={error.stack} />
          </ListItem>
        ))}
      </List>
    </Box>
  </Paper>
);

ErrorListFr.propTypes = {
  errors: PropTypes.object,
};

export default ErrorListFr;
