import ApiClient from '../../Components/NeoBrowser/Utils/ApiClient';
import Challenges from './challenges';

/**
 * Loops through a category of challenges to control.
 * @param {string} category Category name of the challenges to check against
 * @param {Object} params Data to be used by the challenge
 * @returns A string corresponding to the achieved challenge's name or null if none was to be found or fulfilled
 */
const ctfChallenges = (category, params) => {
  let flagName = null;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Challenges[category].length; i++) {
    const result = Challenges[category][i](params);

    if (result) {
      flagName = result;
      break;
    }
  }

  return flagName;
};

/**
 * Searches for a challenge to be fulfilled on a create product action
 * @param {Object} params Data to be checked
 * @returns A string corresponding to the achieved challenge's name or null if none was to be found or fulfilled
 */
const ctfVerificatorOnCreateProduct = (params) => {
  let flagName = null;

  if (
    params?.createResponse?.status === 200 ||
    params?.createResponse?.status === 201
  ) {
    flagName = ctfChallenges('onCreateProduct', params);
  }

  return flagName;
};

/**
 * Searches for a challenge to be fulfilled on an edit product action
 * @param {Object} params Data to be checked
 * @returns A string corresponding to the achieved challenge's name or null if none was to be found or fulfilled
 */
const ctfVerificatorOnEditProduct = (params) => {
  let flagName = null;

  if (
    params?.editResponse?.status === 200 ||
    params?.editResponse?.status === 201
  ) {
    flagName = ctfChallenges('onEditProduct', params);
  }

  return flagName;
};

/**
 * Searches for a challenge to be fulfilled on a save form action
 * @param {Object} params Data to be checked
 * @returns A string corresponding to the achieved challenge's name or null if none was to be found or fulfilled
 */
const ctfVerificatorOnSaveForm = async ({ saveResponse, authUserService }) => {
  console.log(saveResponse);
  const flagName = await ApiClient.request({
    method: 'post',
    url: `/form/preview`,
    headers: {
      Authorization: `Bearer ${authUserService.getToken()}`,
    },
    data: {
      builderSchema: saveResponse?.data?.blueprint,
      formId: saveResponse?.data?.id,
      role: 'Default',
    },
  })
    .then(async (response) => {
      const returnVal = ctfChallenges('onSaveForm', {
        ...response.data,
        formBuilder: saveResponse.data,
      });
      return returnVal;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

  return flagName;
};

/**
 * Searches for a challenge to be fulfilled on an export form action
 * @param {Object} params Data to be checked
 * @returns A string corresponding to the achieved challenge's name or null if none was to be found or fulfilled
 */
const ctfVerificatorOnExportForm = (params) =>
  ctfChallenges('onExportForm', params);

/**
 * Searches for a challenge to be fulfilled on a publish form action
 * @param {Object} params Data to be checked
 * @returns A string corresponding to the achieved challenge's name or null if none was to be found or fulfilled
 */
const ctfVerificatorOnPublishForm = (params) =>
  ctfChallenges('onPublishForm', params);

/**
 * Searches for a challenge to be fulfilled on an export field action
 * @param {Object} params Data to be checked
 * @returns A string corresponding to the achieved challenge's name or null if none was to be found or fulfilled
 */
const ctfVerificatorOnExportField = (params) => {
  let flagName = null;

  if (params?.exportResponse?.status === 200) {
    flagName = ctfChallenges('onExportField', params);
  }

  return flagName;
};

export {
  ctfVerificatorOnCreateProduct,
  ctfVerificatorOnEditProduct,
  ctfVerificatorOnSaveForm,
  ctfVerificatorOnExportForm,
  ctfVerificatorOnPublishForm,
  ctfVerificatorOnExportField,
};
