import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

const FieldHtmlParser = ({ schema }) => {
  const { title } = schema;

  return <div>{parse(title)}</div>;
};

FieldHtmlParser.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
  }),
};

export default FieldHtmlParser;
