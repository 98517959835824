import React from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LanguageChanger from '../LanguageChanger';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
}));

const AppBarComponent = ({ apiUserService }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const doLogout = () => {
    if (apiUserService) {
      apiUserService.doLogout();
    }
  };

  return (
    <AppBar className={clsx(classes.appBar)} position='absolute'>
      <Toolbar className={classes.toolbar}>
        <Typography
          noWrap
          className={classes.title}
          color='inherit'
          component='h1'
          variant='h6'
        >
          {t('Global.SiteTitle')}
        </Typography>
        <LanguageChanger />
        {apiUserService?.getToken() && (
          <Tooltip title={t('Global.Logout')}>
            <IconButton
              aria-label={t('Global.Logout')}
              color='inherit'
              onClick={doLogout}
            >
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    </AppBar>
  );
};
AppBarComponent.propTypes = {
  apiUserService: PropTypes.object,
};
export default AppBarComponent;
