import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import { v4 as uuidv4 } from 'uuid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Droppable } from 'react-beautiful-dnd';
import Element from '../Element';

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgrey' : '',
  padding: 0,
});

const Menu = ({ menu, elements, classes, actions }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(0);

  const handleExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const groups = [];

  const pushGroups = () => {
    elements.map((field) => {
      if (field.blueprint.group && !groups.includes(field.blueprint.group))
        groups.push(field.blueprint.group);
      return groups;
    });
  };

  const dynamicMenu = () => {
    pushGroups();

    const filteredGroups = [
      'common',
      'advanced',
      'layout',
      'visual',
      'shared',
    ].filter((groupCategory) => groups.includes(groupCategory));

    const dropDisabled = {
      ...(!menu.droppable && { isDropDisabled: true }),
    };

    return (
      <Droppable
        {...dropDisabled}
        className={menu.classe}
        droppableId={menu.id}
        key={menu.id}
      >
        {({ innerRef, droppableProps, placeholder }, { isDraggingOver }) => (
          <ListItem
            className={classes.items}
            key={menu.id}
            ref={innerRef}
            {...droppableProps}
            style={getListStyle(isDraggingOver)}
          >
            {filteredGroups.map((group, groupId) => (
              <Accordion
                square
                className={classes.accordion}
                expanded={expanded === groupId}
                key={groupId}
                onChange={handleExpanded(groupId)}
              >
                <AccordionSummary
                  aria-controls='panel1a-content'
                  expandIcon={<ExpandMoreIcon />}
                  id=';panel1a-header'
                >
                  <Typography>
                    {t(`NeoBuilder.Fields.Group.${group}`)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.items}>
                  {elements.map((field, index) => {
                    if (
                      field.blueprint.group &&
                      field.blueprint.group === group
                    ) {
                      return (
                        <Element
                          actions={actions}
                          classes={classes}
                          field={field}
                          index={index}
                          key={`accordion-${index}-${field.blueprint.id}`}
                          menu={menu}
                        />
                      );
                    }
                    return null;
                  })}
                </AccordionDetails>
              </Accordion>
            ))}
            {placeholder}
          </ListItem>
        )}
      </Droppable>
    );
  };

  return (
    <List
      className={`${classes.root} ${classes[menu.class]}`}
      subheader={<ListSubheader>{menu.title}</ListSubheader>}
    >
      {dynamicMenu()}
    </List>
  );
};

Menu.propTypes = {
  menu: PropTypes.object,
  elements: PropTypes.array,
  classes: PropTypes.object,
  actions: PropTypes.any,
};

export default Menu;
