import React from 'react';
import PropTypes from 'prop-types';
import RadioField from './radio';
import CheckboxesField from './checkboxes';

const FieldAutocomplete = (props) => {
  const { schema } = props;

  if (schema?.items) {
    return <CheckboxesField {...props} />;
  }
  return <RadioField {...props} />;
};

FieldAutocomplete.propTypes = {
  schema: PropTypes.shape({
    items: PropTypes.any,
  }),
};

export default FieldAutocomplete;
