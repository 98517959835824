import { atom } from 'recoil';

const flagAtom = atom({
  key: 'flagState',
  default: {
    fire: false,
    currentChallenge: '',
  },
});

export default flagAtom;
