import extensionLiterary from './literary';
import extensionRating from './rating';
import extensionDeepDependencie from './deepDependencies';
import extensionDynamicData from './dynamicData';

function recursiveAssignFormData(
  tree,
  id,
  keyIndex = null,
  jsonLogicName,
  result,
  prevKey = '',
  results = []
) {
  const r = results;
  if (typeof tree !== 'undefined' && tree !== null) {
    Object.keys(tree).forEach((key) => {
      const node = tree[key];

      if (key === id) {
        if (keyIndex !== null) {
          if (String(keyIndex) === String(prevKey)) {
            node[jsonLogicName] = result;
          }
        } else {
          node[jsonLogicName] = result;
        }
      }

      if (typeof node === 'object') {
        recursiveAssignFormData(
          node,
          id,
          keyIndex,
          jsonLogicName,
          result,
          key,
          r
        );
      }
    });
  }
  return r;
}

const init = async (
  extensions,
  formData,
  jsonSchema,
  uiSchema,
  setCurrentUiSchema,
  currentJSchema,
  setCurrentJSchema,
  setCurrentFormAtom,
  setFormData
) => {
  if (extensions && extensions.includes('deepDependencies')) {
    await extensionDeepDependencie(
      formData,
      uiSchema,
      jsonSchema,
      setCurrentUiSchema,
      setFormData,
      setCurrentFormAtom
    );
  }
  if (extensions && extensions.includes('dynamicData')) {
    await extensionDynamicData(
      formData,
      currentJSchema,
      jsonSchema,
      setCurrentJSchema,
      setCurrentFormAtom,
      setFormData
    );
  }
};

const checkSubmitExtensions = (extensions, formdata, jsonSchema) => {
  if (extensions && extensions.includes('rating')) {
    extensionRating(formdata, jsonSchema, recursiveAssignFormData);
  }
  if (extensions && extensions.includes('literary')) {
    extensionLiterary(formdata, jsonSchema, recursiveAssignFormData);
  }
};

const checkOnChangeExtensions = (
  extensions,
  formData,
  jsonSchema,
  uiSchema,
  setCurrentUiSchema,
  currentJSchema,
  setCurrentJSchema,
  setCurrentFormAtom,
  setFormData
) => {
  if (extensions && extensions.includes('deepDependencies')) {
    extensionDeepDependencie(
      formData,
      uiSchema,
      jsonSchema,
      setCurrentUiSchema,
      setFormData,
      setCurrentFormAtom
    );
  }
  if (extensions && extensions.includes('dynamicData')) {
    extensionDynamicData(
      formData,
      currentJSchema,
      jsonSchema,
      setCurrentJSchema,
      setCurrentFormAtom,
      setFormData
    );
  }
};

export default { init, checkOnChangeExtensions, checkSubmitExtensions };
