import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';

function Error({ type, msg }) {
  switch (type) {
    case 'Breadcrumb':
      return <></>;
    default:
      return (
        <Alert severity='error' variant='filled'>
          Erreur : {msg}
        </Alert>
      );
  }
}

Error.propTypes = {
  type: PropTypes.string,
  msg: PropTypes.string,
};
export default Error;
