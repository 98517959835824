import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import withUseResource from './Hoc/withUseResource';
import NeoFormUse from '../../NeoForm/NeoFormUse';
import FieldSimpleTabs from '../../NeoForm/NeoFields/Tabs/basic';
import FieldJsonLogicEditor from '../../NeoForm/NeoFields/JsonLogicEditor';
import FieldJsonEditor from '../../NeoForm/NeoFields/JsonEditor';
import FieldAlertMessage from '../../NeoForm/NeoFields/AlertMessage';
import Invisible from '../../NeoForm/NeoFields/Invisible';
import FieldHtmlEditor from '../../NeoForm/NeoFields/Html/HtmlEditor';
import FieldHtmlParser from '../../NeoForm/NeoFields/Html/HtmlParser';

const SlimForm = ({ resourceData, handleSubmit, submitFormRef }) => {
  const { jsonSchema, uiSchema, formData } = resourceData;

  return (
    <NeoFormUse
      customSubmit={
        <Button ref={submitFormRef} style={{ display: 'none' }} type='submit'>
          submitForm
        </Button>
      }
      fields={{
        NeoSimpleTabs: FieldSimpleTabs,
        NeoJsonLogicEditor: FieldJsonLogicEditor,
        NeoJsonEditor: FieldJsonEditor,
        NeoAlertMessage: FieldAlertMessage,
        NeoHtmlEditor: FieldHtmlEditor,
        NeoHtmlParser: FieldHtmlParser,
        NeoInvisible: Invisible,
      }}
      formDataReceived={formData}
      handleSubmit={handleSubmit}
      JsonSchema={jsonSchema}
      UiSchema={uiSchema}
    />
  );
};

const SimpleForm = withUseResource(SlimForm);

SlimForm.propTypes = {
  resourceData: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitFormRef: PropTypes.object.isRequired,
};

export default SimpleForm;
