import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

const cancelAction = (
  localMetaSpec,
  setLocalMetaSpec,
  savedFormName,
  savedBuilderSchema,
  setUnsavedChange,
  setAtomSnackbar
) => {
  const { t } = useTranslation();

  const onCancelAction = () => {
    let copyLocalMetaSpec = cloneDeep(localMetaSpec);
    copyLocalMetaSpec = {
      ...copyLocalMetaSpec,
      formName: savedFormName,
      menus: {
        ...copyLocalMetaSpec.menus,
        formBuilder: cloneDeep(savedBuilderSchema),
      },
      updateTimestamp: Date.now(),
    };

    setLocalMetaSpec(copyLocalMetaSpec);
    setUnsavedChange(false);

    setAtomSnackbar((prevAtomSnackbar) => ({
      ...prevAtomSnackbar,
      open: true,
      text: t('Snackbar.CancelAllChanges'),
      severity: 'success',
    }));
  }; // fin onCancelAction

  return { onCancelAction };
};

export default cancelAction;
