/**
 * CTF: Export d'un formulaire
 */
export default ({ method }) => {
  let flagName = null;

  if (method === 'export') {
    flagName = 'exportForm';
  }

  return flagName;
};
