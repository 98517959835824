/**
 * CTF: Publier un formulaire
 */
export default ({ publishResponse }) => {
  let flagName = null;

  if (publishResponse?.data?.flash?.status === 'success') {
    flagName = 'publishForm';
  }

  return flagName;
};
