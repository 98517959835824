import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FlagIcon from '@material-ui/icons/Flag';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

const ActionsGroup = (
  parent,
  element,
  actionsFunc,
  limitActions,
  dragHandleProps
) => {
  const { t } = useTranslation();

  const { blueprint } = element;

  if (blueprint) {
    const { actions } = blueprint;
    if (actions && actions.length > 0) {
      return (
        <div>
          {actions.map((action) => {
            if (limitActions.length === 0 || limitActions.includes(action.id)) {
              switch (action.id) {
                case 'zoom':
                  return (
                    <Tooltip
                      key={uuidv4()}
                      title={t('NeoBuilder.Edit.Element.Actions.Zoom')}
                    >
                      <IconButton
                        aria-label='zoom-in'
                        onClick={() => {
                          actionsFunc.zoom(element, parent);
                        }}
                      >
                        <ZoomInIcon />
                      </IconButton>
                    </Tooltip>
                  );
                case 'zoomOut':
                  return (
                    <Tooltip
                      key={uuidv4()}
                      title={t('NeoBuilder.Edit.Element.Actions.ZoomOut')}
                    >
                      <IconButton
                        aria-label='zoom-out'
                        onClick={() => {
                          actionsFunc.zoomOut();
                        }}
                      >
                        <ZoomOutIcon />
                      </IconButton>
                    </Tooltip>
                  );
                case 'flag':
                  // eslint-disable-next-line no-case-declarations
                  const preEditAction = actions?.find(
                    (x) => x.id === 'preedit'
                  );

                  // tous champ, ou un champ partagé par "copy" peut être (re)partagé
                  if (
                    !preEditAction ||
                    preEditAction?.formData?.method === 'copy'
                  ) {
                    return (
                      <Tooltip
                        key={uuidv4()}
                        title={t('NeoBuilder.Edit.Element.Actions.Flag')}
                      >
                        <IconButton
                          aria-label='flag'
                          onClick={() => {
                            actionsFunc.flag(element, parent);
                          }}
                        >
                          <FlagIcon />
                        </IconButton>
                      </Tooltip>
                    );
                  }
                  return null;
                case 'edit':
                  return (
                    <Tooltip
                      key={uuidv4()}
                      title={t('NeoBuilder.Edit.Element.Actions.Edit')}
                    >
                      <IconButton
                        aria-label='edit'
                        onClick={() => {
                          actionsFunc.edit(element, parent);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  );
                case 'move':
                  return (
                    <Tooltip
                      key={uuidv4()}
                      title={t('NeoBuilder.Edit.Element.Actions.Move')}
                    >
                      <IconButton aria-label='move' {...dragHandleProps}>
                        <OpenWithIcon />
                      </IconButton>
                    </Tooltip>
                  );
                case 'copy':
                  return (
                    <Tooltip
                      key={uuidv4()}
                      title={t('NeoBuilder.Edit.Element.Actions.Copy')}
                    >
                      <IconButton
                        aria-label='copy'
                        onClick={() => {
                          actionsFunc.copy(element, parent);
                        }}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  );
                case 'delete':
                  return (
                    <Tooltip
                      key={uuidv4()}
                      title={t('NeoBuilder.Edit.Element.Actions.Delete')}
                    >
                      <IconButton
                        aria-label='delete'
                        onClick={() => {
                          actionsFunc.delete(element, parent);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  );
                default:
                  return '';
              }
            }
            return '';
          })}
        </div>
      );
    }
  }
  return '';
};

Element.propTypes = {
  parent: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  actionsFunc: PropTypes.any.isRequired,
  limitActions: PropTypes.array,
  dragHandleProps: PropTypes.object,
};

export default ActionsGroup;
