import React, { useEffect, useState } from 'react';
import { useLocation, Route, useHistory } from 'react-router-dom'; // Redirect  useLocation
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import NotAllowed from '../../Views/NotAllowed';
import keycloakAtom from '../../Atoms/keycloakAtom';

const PrivateRoute = ({
  LayoutComponent,
  ViewComponent,
  path,
  roleAuth,
  apiUserService,
}) => {
  /**
   * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
   *
   * @param onAuthenticatedCallback
   */
  const [atomKeycloak, setAtomKeycloak] = useRecoilState(keycloakAtom);
  const history = useHistory();
  const { search } = useLocation();
  let realm = '';
  let urlServeurKc = '';
  let clientId = '';
  const [authenticated, setAuthenticated] = useState(null);
  const [content, setContent] = useState(null);

  const realmEnGet = new URLSearchParams(search).get('realm');
  const urlEnGet = new URLSearchParams(search).get('url');
  const clientIdEnGet = new URLSearchParams(search).get('clientId');
  useEffect(async () => {
    // après la connexion, redirect sur l'url sans param get, vaut alors null
    // si rechargement basique
    realm = realmEnGet !== null ? realmEnGet : atomKeycloak.realm;
    urlServeurKc = urlEnGet !== null ? urlEnGet : atomKeycloak.url;
    clientId = clientIdEnGet !== null ? clientIdEnGet : atomKeycloak.clientId;
    const cloneAtome = atomKeycloak;
    cloneAtome.realm = realm;
    cloneAtome.url = urlServeurKc;
    cloneAtome.clientId = clientId;
    setAtomKeycloak(cloneAtome);

    if (realmEnGet !== null) {
      history.push('/');
    }
  }, [apiUserService]);

  useEffect(async () => {
    if (
      realm === 'initialiser' ||
      atomKeycloak.redirectionRealms === true ||
      typeof atomKeycloak.validityDate === 'undefined' ||
      (atomKeycloak.validityDate &&
        atomKeycloak.validityDate < new Date().setTime(new Date().getTime()))
    ) {
      history.push('/realms');
    } else if (apiUserService.getRealm() !== realm) {
      setAuthenticated(false);
    } else if (!apiUserService.isLoggedIn()) {
      setAuthenticated(false);
    } else {
      setAuthenticated(true);
    }
  }, [realm]);

  useEffect(() => {
    if (authenticated === true) {
      if (!roleAuth) {
        setContent(
          React.cloneElement(LayoutComponent, { apiUserService, ViewComponent })
        );
      } else if (apiUserService.hasRole(roleAuth)) {
        setContent(
          React.cloneElement(LayoutComponent, { apiUserService, ViewComponent })
        );
      } else {
        setContent(<NotAllowed />);
      }
    } else if (authenticated === false) {
      apiUserService.initKeycloak(setAuthenticated);
    }
  }, [authenticated]);

  return (
    // Show the component only when the user is logged in

    <Route exact path={path}>
      {content}
    </Route>
  );
};
PrivateRoute.propTypes = {
  LayoutComponent: PropTypes.node.isRequired,
  ViewComponent: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  roleAuth: PropTypes.array,
  apiUserService: PropTypes.object,
};
export default PrivateRoute;
