/**
 * CTF: Formulaire Dépendance profonde
 *
 * Dans le cadre de ce challenge, le jsonSchema devrait ressembler à quelque chose comme ça:
 *
 * {
 *   type: "object",
 *   required: [
 *   ],
 *   properties: {
 *     sport: {
 *       type: "string",
 *       "@source": "sport",
 *       title: "Je pratique le",
 *       enum: [
 *         "Basketball",
 *         "Football",
 *         "Handball",
 *       ],
 *     },
 *     details: {
 *       type: "object",
 *       "@source": "details",
 *       title: "Détails",
 *       properties: {
 *         football: {
 *           type: "string",
 *           "@source": "details.football",
 *           title: "Je supporte l'équipe de",
 *         },
 *       },
 *       deepDependencies: {
 *         football: [
 *           {
 *             id: "@deepDependencie",
 *             object: false,
 *             receiver: "football",
 *             receiverPath: "details.football",
 *             trigger: "sport",
 *             onValues: [
 *               "Football",
 *             ],
 *           },
 *         ],
 *       },
 *     },
 *   },
 * }
 */
export default ({ jsonSchema }) => {
  let flagName = null;

  if (jsonSchema?.properties?.details?.deepDependencies?.football) {
    const dependencies =
      jsonSchema.properties.details.deepDependencies.football;

    dependencies.forEach((dependency) => {
      if (
        dependency &&
        dependency?.id === '@deepDependencie' &&
        dependency?.trigger === 'sport' &&
        dependency?.onValues &&
        Array.isArray(dependency?.onValues) &&
        dependency?.onValues?.includes('Football')
      ) {
        flagName = 'formDeepDependency';
      }
    });
  }

  return flagName;
};
