import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TranslateIcon from '@material-ui/icons/Translate';

const useStyles = makeStyles(() => ({
  root: {
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
    color: 'white',
  },
}));

const LanguageChanger = () => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState(false);

  const langSwitch = [
    { code: 'en-US', title: t('Language.English') },
    { code: 'fr', title: t('Language.French') },
  ];

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const renderSelect = (language) => (
    <Button
      color='inherit'
      onClick={() => {
        setOpen(!open);
      }}
    >
      <TranslateIcon />
      <Select
        className={classes.root}
        id='select-language'
        open={open}
        value={language}
        onChange={handleChange}
        onClick={() => setOpen(!open)}
      >
        {langSwitch.map((lang, index) => (
          <MenuItem key={index} value={lang.code}>
            {lang.title}
          </MenuItem>
        ))}
      </Select>
    </Button>
  );

  return renderSelect(i18n.language);
};

export default LanguageChanger;
