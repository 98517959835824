import { get, set, cloneDeep } from 'lodash';
import jp from 'jsonpath';

import findYByX from '../../objectManipulation/findYByX';

const neoModifiers = (jsonSchema, formData) => {
  const toModify = findYByX(jsonSchema, 'id', '@modifier');

  toModify.map(async (element) => {
    let formDataAnswer = get(formData, element?.content?.receiverPath);
    let formDataAnswers = false;
    let formDataPaths = false;

    if (!formDataAnswer && element?.content?.receiverPath) {
      formDataAnswers = jp.query(formData, element.content.receiverPath);
      if (formDataAnswers.length > 0) {
        formDataPaths = jp.paths(formData, element.content.receiverPath);
      }
    }

    let path = '';
    if (formDataAnswers && formDataPaths && formDataAnswers.length > 0) {
      formDataAnswers.forEach((fdAnswer, index) => {
        formDataAnswer = fdAnswer;
        if (formDataPaths[index].length > 1) {
          formDataPaths[index].shift();
          path = formDataPaths[index].join('.');
        }

        if (formDataAnswer && element?.content?.contentModifier) {
          if (element.content.contentModifier === 'upperCase') {
            set(formData, path, formDataAnswer.toUpperCase());
          } else if (element.content.contentModifier === 'lowerCase') {
            set(formData, path, formDataAnswer.toLowerCase());
          }
        }
      });
    } else if (formDataAnswer && element?.content?.contentModifier) {
      path = cloneDeep(element?.content?.receiverPath);

      if (element.content.contentModifier === 'upperCase') {
        set(formData, path, formDataAnswer.toUpperCase());
      } else if (element.content.contentModifier === 'lowerCase') {
        set(formData, path, formDataAnswer.toLowerCase());
      }
    }
    return null;
  });
};

export default neoModifiers;
