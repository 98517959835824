import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { debounce, merge } from 'lodash';

import FormBuilderView from '../../../Views/FormBuilderView';
import withUseResource from './Hoc/withUseResource';
import ApiClient from '../Utils/ApiClient';

const SimpleEditForm = ({ browser, resourceData, resourceState }) => {
  const [formSchema, setFormSchema] = useState(null);
  const [formPublished, setFormPublished] = useState(null);
  const [selected, setSelected] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setFormSchema(resourceData?.blueprint);
    setFormPublished(resourceData?.published);
  }, []);

  useEffect(() => {
    if (resourceData?.builder?.selected) {
      setSelected(resourceData.builder.selected);
    }
  }, []);

  const handleSaveBuilder = (builderSchema) => {
    if (resourceState.links.has('submit') && builderSchema) {
      const link = resourceState.links.get('submit');
      ApiClient.request({
        method: link.method,
        url: link.href,
        data: builderSchema,
        headers: {
          Authorization: `Bearer ${browser.authUserService.getToken()}`,
        },
      })
        .then(async (response) => {
          if (response.status === 200) {
            setFormSchema(response.data.blueprint);
          }

          if (response?.data?.flash) {
            browser.flash(response.data.flash);
          }

          // CTFs sur les formulaires
          browser.flag('onSaveForm', { saveResponse: response });
        })
        .catch((error) => {
          browser.flash({
            status: 'error',
            message: error?.message || 'Erreur',
          });
        });
    }
  };

  const setWarning = (
    currentJsonSchema,
    currentUiSchema,
    currentFormData,
    response
  ) => ({
    newJsonSchema: {
      ...merge({}, currentJsonSchema, {
        properties: {
          warningId: {
            type: 'string',
            title: 'warning',
            description: t('NeoBuilder.Edit.Flag.CheckId.WarningIdExist'),
          },
          infoName: {
            type: 'string',
            title: 'info',
            description: t('NeoBuilder.Edit.Flag.CheckId.InfoNameIdExist'),
          },
        },
      }),
    },
    newUiSchema: {
      ...merge({}, currentUiSchema, {
        'ui:order': ['id', 'warningId', 'name', 'infoName', 'notes'],
        warningId: {
          'ui:field': 'NeoAlertMessage',
        },
        infoName: {
          'ui:field': 'NeoAlertMessage',
        },
        name: {
          'ui:readonly': true,
        },
      }),
    },
    newFormData: {
      ...currentFormData,
      ...{ name: response.data?.name },
    },
  });

  const cleanWarning = (
    currentJsonSchema,
    currentUiSchema,
    currentFormData
  ) => {
    const newJsonSchema = merge({}, currentJsonSchema);
    const newUiSchema = merge({}, currentUiSchema);

    delete newJsonSchema?.properties?.warningId;
    delete newJsonSchema?.properties?.infoName;

    delete newUiSchema?.warningId;
    delete newUiSchema?.infoName;
    delete newUiSchema?.name?.['ui:readonly'];
    if (newUiSchema?.['ui:order']) {
      newUiSchema['ui:order'] = ['id', 'name', 'notes'];
    }

    return {
      newJsonSchema,
      newUiSchema,
      newFormData: currentFormData,
    };
  };

  const handleCheckId = (
    currentFormData,
    currentJsonSchema,
    setCurrentJsonSchema,
    currentUiSchema,
    setCurrentUiSchema,
    setFormData,
    oldFormData
  ) => {
    if (
      currentFormData?.id &&
      currentFormData?.id !== oldFormData?.id &&
      resourceState.links.has('checkExportFieldId')
    ) {
      const link = resourceState.links.get('checkExportFieldId');
      ApiClient.request({
        method: link.method,
        url: link.href.replace('{fieldId}', currentFormData.id),
        headers: {
          Authorization: `Bearer ${browser.authUserService.getToken()}`,
        },
      })
        .then((response) => {
          if (response?.status === 200 && response?.data?.exist) {
            const { newJsonSchema, newUiSchema, newFormData } = setWarning(
              currentJsonSchema,
              currentUiSchema,
              currentFormData,
              response
            );

            // update json schema
            setCurrentJsonSchema(newJsonSchema);
            // update ui schema
            setCurrentUiSchema(newUiSchema);
            // update form data
            setFormData(newFormData);
          } else {
            const { newJsonSchema, newUiSchema, newFormData } = cleanWarning(
              currentJsonSchema,
              currentUiSchema,
              currentFormData
            );

            // update json schema
            setCurrentJsonSchema(newJsonSchema);
            // update ui schema
            setCurrentUiSchema(newUiSchema);
            // update form data
            setFormData(newFormData);
          }
        })
        .catch((error) => {
          browser.flash({
            status: 'error',
            message: error?.message || 'Erreur',
          });
        });
    }
  };

  // on limite les appels au serveur
  const debounceHandlePreExportField = useCallback(
    debounce(handleCheckId, 400),
    []
  );

  const handleExportField = async (result, fieldSchema) => {
    if (
      resourceState.links.has('createField') &&
      fieldSchema?.blueprint &&
      result?.id &&
      result?.name
    ) {
      const newField = {
        // /!\ le premier objet passé en paramètre à "merge" est modifié par
        // référence, donc on préfère lui donner un object vide dans ce cas,
        // afin de ne pas interférer directement avec le champ en cours d'export.
        ...merge({}, fieldSchema, {
          blueprint: {
            group: 'shared',
          },
        }),
        ...{
          ...result,
        },
      };

      // suppression d'une éventuelle action "preedit"
      const indexPreEdit = newField?.blueprint?.actions?.findIndex(
        (action) => action.id === 'preedit'
      );
      if (indexPreEdit !== -1) {
        newField.blueprint.actions.splice(indexPreEdit, 1);
      }

      const link = resourceState.links.get('createField');
      const res = await ApiClient.request({
        method: link.method,
        url: link.href,
        data: newField,
        headers: {
          Authorization: `Bearer ${browser.authUserService.getToken()}`,
        },
      })
        .then((response) => {
          if (response?.data?.flash) {
            browser.flash(response.data.flash);
          }

          // CTFs sur les formulaires
          browser.flag('onExportField', { newField, exportResponse: response });

          return response;
        })
        .catch((error) => {
          browser.flash({
            status: 'error',
            message: error?.message || 'Erreur',
          });
          return error.response;
        });
      return res;
    }
    return false;
  };

  const getFormRevision = async (formId) => {
    if (resourceState.links.has('revisions') && formId) {
      const link = resourceState.links.get('revisions');
      const content = await ApiClient.request({
        url: link.href,
        headers: {
          Authorization: `Bearer ${browser.authUserService.getToken()}`,
        },
      })
        .then((response) => response.data.revisions)
        .catch((error) => {
          browser.flash({
            status: 'error',
            message: error?.message || 'Erreur',
          });
          return [];
        });
      return content;
    }
    return [];
  };

  const handlePublishVersion = async (formData) => {
    if (resourceState.links.has('publish')) {
      const link = resourceState.links.get('publish');
      const done = await ApiClient.request({
        method: link.method,
        url: link.href,
        headers: {
          Authorization: `Bearer ${browser.authUserService.getToken()}`,
        },
        data: {
          author: browser.authUserService.getUsername(),
          ...formData,
        },
      })
        .then((response) => {
          if (response?.data?.flash) {
            browser.flash(response.data.flash);
          }

          browser.flag('onPublishForm', { publishResponse: response });

          return true;
        })
        .catch((error) => {
          browser.flash({
            status: 'error',
            message: error?.message || 'Erreur',
          });
          return false;
        });
      return done;
    }
    return false;
  };

  const getBuilderSchema = async (version) => {
    if (resourceState.links.has('rawSchema')) {
      const link = resourceState.links.get('rawSchema');
      const responseData = await ApiClient.request({
        method: link?.method || 'GET',
        url: link.href.replace('{version}', version),
        headers: {
          Authorization: `Bearer ${browser.authUserService.getToken()}`,
        },
      })
        .then((response) => response?.data)
        .catch((error) => {
          browser.flash({
            status: 'error',
            message: error?.message || 'Erreur',
          });
          return null;
        });
      return responseData;
    }
    return null;
  };

  const payloadUri = () => {
    if (resourceState.links.has('payload')) {
      return resourceState.links.get('payload')?.href;
    }
    return null;
  };

  /**
   * Get all builder schemas of each version of the field with ID `fieldId`.
   */
  const importFieldBuilderSchemas = async (fieldId) => {
    if (resourceState.links.has('fieldVersions')) {
      const link = resourceState.links.get('fieldVersions');
      const versions = await ApiClient.request({
        method: link?.method || 'GET',
        url: link.href.replace('{fieldId}', fieldId),
        headers: {
          Authorization: `Bearer ${browser.authUserService.getToken()}`,
        },
      })
        .then((response) => response?.data?.versions)
        .catch((error) => {
          browser.flash({
            status: 'error',
            message: error?.message || 'Erreur',
          });
          return null;
        });

      const parseVersions = (name, latest) => {
        const allV = versions.map((v) =>
          name
            ? `${v.schema.version} ${
                v?.schema?.notes && `- ${v?.schema?.notes}`
              }`
            : v.schema.version
        );

        if (latest) {
          allV[0] = name ? 'latest' : 0;
        } else {
          allV.splice(0, 1);
        }

        return allV;
      };

      const jsonSchema = {
        type: 'object',
        title: `Méthode d’utilisation du champ partagé "${
          versions.slice(-1)[0].schema.name
        }"`,
        properties: {
          method: {
            enum: ['copy', 'hosted'],
            enumNames: ['Oui', 'Non'],
            title: 'Souhaitez-vous pouvoir modifier ce champ ?',
            type: 'string',
          },
        },
        required: ['method'],
        dependencies: {
          method: {
            oneOf: [
              {
                properties: {
                  method: {
                    enum: ['copy'],
                  },
                  version: {
                    type: 'number',
                    title: 'Quelle version souhaitez-vous utiliser ?',
                    enum: parseVersions(),
                    enumNames: parseVersions(true),
                  },
                },
                required: ['version'],
              },
              {
                properties: {
                  method: {
                    enum: ['hosted'],
                  },
                  version: {
                    type: 'number',
                    title: 'Quelle version souhaitez-vous utiliser ?',
                    enum: parseVersions(false, true),
                    enumNames: parseVersions(true, true),
                  },
                  id: {
                    type: 'string',
                    title: 'Identifiant unique du champ',
                  },
                },
                required: ['version', 'id'],
              },
            ],
          },
        },
      };

      const uiSchema = {};

      const handleChangeMethodOrVersion = (formData) => {
        if (
          formData?.method &&
          formData.method === 'hosted' &&
          (formData?.version || formData?.version === 0)
        ) {
          // eslint-disable-next-line no-param-reassign
          formData.uri = versions.find(
            (v) => v.schema.version === formData.version
          )?.uri;
        } else {
          // eslint-disable-next-line no-param-reassign
          formData.uri = null;
        }
      };

      return {
        jsonSchema,
        uiSchema,
        allVersionsSchemas: versions,
        handleChangeMethodOrVersion,
      };
    }
    return null;
  };

  if (!formSchema) {
    return null;
  }

  return (
    <FormBuilderView
      actions={{
        handlePreExportField: debounceHandlePreExportField,
        handleExportField,
        handleSaveBuilder,
        handlePublishVersion,
        getFormRevision,
        getBuilderSchema,
        importFieldBuilderSchemas,
        payloadUri,
        getToken: browser.authUserService.getToken,
        unlockFlag: browser.flag,
      }}
      fields={resourceData?.project?.client?.options?.fields}
      formId={resourceData.id}
      formName={resourceData?.name}
      formPublication={{ formPublished, setFormPublished }}
      formSchema={formSchema}
      selected={selected}
    />
  );
};

const EditForm = withUseResource(SimpleEditForm);

SimpleEditForm.propTypes = {
  browser: PropTypes.object,
  resourceData: PropTypes.object,
  resourceState: PropTypes.object,
};

export default EditForm;
