import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import EditorToolbar, { modules, formats } from './EditorToolbar';
import 'react-quill/dist/quill.snow.css';

const FieldHtmlEditor = ({ schema, formData, onChange, errorSchema }) => {
  const { title } = schema;

  const [value, setValue] = useState(formData);

  const onChangeInput = (e) => {
    setValue(e);
    onChange(e, errorSchema, 2);
  };

  return (
    <Grid container direction='column'>
      <Typography color='primary' variant='subtitle1'>
        {title}
      </Typography>
      <div className='text-editor'>
        <EditorToolbar />
        <ReactQuill
          formats={formats}
          modules={modules}
          placeholder='Write something awesome...'
          theme='snow'
          value={value}
          onChange={onChangeInput}
        />
      </div>
    </Grid>
  );
};

FieldHtmlEditor.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  formData: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  errorSchema: PropTypes.any,
};

export default FieldHtmlEditor;
