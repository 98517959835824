import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import CodeIcon from '@material-ui/icons/Code';
// V5 import AddLinkIcon from '@material-ui/icons/AddLink';
import LinkIcon from '@material-ui/icons/Link';
import ShareIcon from '@material-ui/icons/Share';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import findYByX from '../../Utils/objectManipulation/findYByX';
import ActionsGroup from '../ActionsGroup';

const Element = ({
  localMetaSpec,
  menu,
  field,
  index,
  classes,
  actions,
  currentMenu,
}) => {
  const [editDraggable] = useState(
    menu.adminElements &&
      field.blueprint.actions &&
      field.blueprint.actions.includes('move')
  );
  const [elementId, setElementId] = useState(field.blueprint.id);
  const [elementTitle, setElementTitle] = useState(field.name);
  const [elementChips, setElementChips] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fieldForm = field.blueprint.actions.find(
      (x) => x.id === 'edit'
    ).formData;
    if (
      menu.adminElements &&
      typeof fieldForm !== 'undefined' &&
      fieldForm.tabs &&
      fieldForm.tabs.data &&
      (fieldForm.tabs?.data?.label ||
        fieldForm.tabs?.data?.required ||
        fieldForm.tabs?.validation?.required)
    ) {
      setElementTitle(
        `${field.name}: ${fieldForm.tabs?.data?.label} ${
          fieldForm.tabs?.data?.required || fieldForm.tabs?.validation?.required
            ? '*'
            : ''
        }`
      );
    }
    if (
      menu.adminElements &&
      typeof fieldForm !== 'undefined' &&
      fieldForm.tabs &&
      fieldForm.tabs.data &&
      fieldForm.tabs.data.id
    ) {
      setElementId(fieldForm.tabs.data.id);
    }
  });

  const setDependencyChip = (fieldForm, mode = '') => {
    const availableDep =
      mode === 'deep' ? 'availableDeepDependencies' : 'availableDependencies';
    const label =
      mode === 'deep'
        ? 'NeoBuilder.Edit.Chips.Label.DeepDependencieOf'
        : 'NeoBuilder.Edit.Chips.Label.DependencieOf';
    const tooltip =
      mode === 'deep'
        ? 'NeoBuilder.Edit.Chips.Tooltip.DeepDependencieOf'
        : 'NeoBuilder.Edit.Chips.Tooltip.DependencieOf';
    const globalTooltip =
      mode === 'deep'
        ? 'NeoBuilder.Edit.Chips.Tooltip.DeepDependencie'
        : 'NeoBuilder.Edit.Chips.Tooltip.Dependencie';

    const ref = findYByX(
      localMetaSpec || menu,
      'id',
      fieldForm.tabs.dependencie[availableDep]
    );
    let depFieldId = null;
    if (ref.length > 0) {
      ref.forEach((x) => {
        const xActions = x?.content?.actions;
        const xEditAction = xActions
          ? xActions.find((search) => search.id === 'edit')
          : null;
        if (xEditAction?.formData?.tabs?.data?.id && !depFieldId) {
          depFieldId = xEditAction.formData.tabs.data.id;
        } else if (x?.content?.id && !depFieldId) {
          depFieldId = x.content.id;
        }
      });
    }
    // eslint-disable-next-line no-unused-expressions
    depFieldId
      ? setElementChips((chips) => [
          ...chips,
          {
            icon: <LinkIcon />,
            label: t(label, { id: depFieldId }),
            tooltip: t(tooltip, { id: depFieldId }),
          },
        ])
      : setElementChips((chips) => [
          ...chips,
          {
            icon: <LinkIcon />,
            label: '',
            tooltip: t(globalTooltip),
          },
        ]);
  };

  useEffect(() => {
    const fieldForm = field.blueprint.actions.find(
      (x) => x.id === 'edit'
    ).formData;

    if (fieldForm.tabs) {
      // Chips assignements

      setElementChips([]);
      // Dependencie
      if (
        fieldForm?.tabs?.dependencie?.availableDependencies &&
        fieldForm?.tabs?.dependencie?.dependencieActivation === 'Yes'
      ) {
        setDependencyChip(fieldForm);
      }
      // Deep Dependencie
      if (
        fieldForm?.tabs?.dependencie?.availableDeepDependencies &&
        fieldForm?.tabs?.dependencie?.dependencieActivation === 'YesDeeper'
      ) {
        setDependencyChip(fieldForm, 'deep');
      }
      // Access
      if (fieldForm?.tabs?.access?.accessActivation === 'Yes') {
        setElementChips((chips) => [
          ...chips,
          {
            icon: <VisibilityOffIcon />,
            label: t('NeoBuilder.Edit.Chips.Label.Access'),
            tooltip: t('NeoBuilder.Edit.Chips.Tooltip.Access'),
          },
        ]);
      }
      // Rating
      if (fieldForm?.tabs?.rating?.activated === true) {
        if (fieldForm?.tabs?.rating?.jsonLogicRateFormula) {
          setElementChips((chips) => [
            ...chips,
            {
              icon: <CodeIcon />,
              label: t('NeoBuilder.Edit.Chips.Label.RatingFormula'),
              tooltip: t('NeoBuilder.Edit.Chips.Tooltip.RatingFormula'),
            },
          ]);
        }
      }
      // Literary
      if (fieldForm?.tabs?.literary?.activated === true) {
        if (fieldForm?.tabs?.literary?.jsonLogicLiteraryFormula) {
          setElementChips((chips) => [
            ...chips,
            {
              icon: <TextFieldsIcon />,
              label: t('NeoBuilder.Edit.Chips.Label.Literary'),
              tooltip: t('NeoBuilder.Edit.Chips.Tooltip.Literary'),
            },
          ]);
        }
      }
    }
  }, [field.blueprint.actions.find((x) => x.id === 'edit').formData]);

  // Champs partagés
  useEffect(() => {
    const preEditAction = field.blueprint.actions.find(
      (x) => x.id === 'preedit'
    );

    if (preEditAction) {
      const icon =
        preEditAction?.formData?.method === 'copy' ? (
          <ShareIcon />
        ) : (
          <DynamicFeedIcon />
        );

      const getVersionLabel = (tooltip) =>
        preEditAction?.formData?.version === 0
          ? tooltip
            ? t('NeoBuilder.Edit.Chips.Tooltip.LatestVersion')
            : '~'
          : tooltip
          ? `version ${preEditAction?.formData?.version}`
          : preEditAction?.formData?.version;

      setElementChips((chips) => [
        ...chips,
        {
          icon,
          label: t('NeoBuilder.Edit.Chips.Label.Shared', {
            id: field.id,
            version: getVersionLabel(),
          }),
          tooltip: t('NeoBuilder.Edit.Chips.Tooltip.Shared', {
            id: field.id,
            version: getVersionLabel(true),
          }),
        },
      ]);
    }
  }, [field.blueprint.actions.find((x) => x.id === 'preedit')?.formData]);

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightgrey' : '',
    height: '100%',
  });

  const chipsList = () => {
    if (elementChips) {
      return (
        <Grid container>
          {elementChips.map((chip, indexChips) => {
            if (chip.tooltip) {
              return (
                <Tooltip
                  className={classes['element-chip']}
                  key={indexChips}
                  title={chip.tooltip}
                >
                  <Chip icon={chip?.icon} label={chip?.label} size='small' />
                </Tooltip>
              );
            }
            return <Chip icon={chip?.icon} label={chip?.label} size='small' />;
          })}
        </Grid>
      );
    }
    return null;
  };

  const droppableElement = () => {
    if (menu.adminElements && field.blueprint.droppable) {
      if (field.blueprint.elements && field.blueprint.elements.length > 2) {
        return (
          <Accordion square className={classes.accordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container justifyContent='space-between'>
                <Typography align='center'>
                  {t('NeoBuilder.Edit.Element.FieldsNumber', {
                    number: field.blueprint.elements.length,
                  })}
                </Typography>
                <Typography
                  align='center'
                  color='textSecondary'
                  variant='body2'
                >
                  {t('NeoBuilder.Edit.Element.FieldsHelper')}
                </Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.items}>
              <Grid container className={classes['droppable-area']}>
                <List className={`${classes.root} ${classes[menu.class]}`}>
                  <Droppable droppableId={field.blueprint.id}>
                    {(
                      { innerRef, droppableProps, placeholder },
                      { isDraggingOver }
                    ) => (
                      <ListItem
                        className={classes.items}
                        key={field.blueprint.id}
                        ref={innerRef}
                        {...droppableProps}
                        style={getListStyle(isDraggingOver)}
                      >
                        {field.blueprint.elements.map((element, indexEl) => (
                          <Element
                            actions={actions}
                            classes={classes}
                            field={element}
                            index={indexEl}
                            key={element.blueprint.id + field.blueprint.id}
                            localMetaSpec={localMetaSpec}
                            menu={menu}
                          />
                        ))}
                        {placeholder}
                      </ListItem>
                    )}
                  </Droppable>
                </List>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      }
      // activate isDropDisabled to modify an object only when it's zoomed
      return (
        <Grid container className={classes['droppable-area']}>
          <List className={`${classes.root} ${classes[menu.class]}`}>
            <Droppable droppableId={field.blueprint.id}>
              {(
                { innerRef, droppableProps, placeholder },
                { isDraggingOver }
              ) => {
                if (
                  field.blueprint.elements &&
                  field.blueprint.elements.length > 0
                ) {
                  return (
                    <ListItem
                      className={classes.items}
                      key={field.blueprint.id}
                      ref={innerRef}
                      {...droppableProps}
                      style={getListStyle(isDraggingOver)}
                    >
                      {field.blueprint.elements.map((element, indexEl) => (
                        <Element
                          actions={actions}
                          classes={classes}
                          field={element}
                          index={indexEl}
                          key={element.blueprint.id + field.blueprint.id}
                          localMetaSpec={localMetaSpec}
                          menu={menu}
                        />
                      ))}
                      {placeholder}
                    </ListItem>
                  );
                }
                return (
                  <ListItem
                    className={classes['empty-items']}
                    key={menu.id + 1}
                    ref={innerRef}
                    {...droppableProps}
                    style={getListStyle(isDraggingOver)}
                  >
                    <Typography variant='h4'>
                      {t('NeoBuilder.Edit.DropHere')}
                    </Typography>
                    {placeholder}
                  </ListItem>
                );
              }}
            </Droppable>
          </List>
        </Grid>
      );
    }
    return '';
  };

  const dynamicElement = () => {
    let returnContent = '';
    let isHostedSharedField = false;
    // on vérifie si il s'agit d'un champ partagé ou non
    const preEditAction = field.blueprint?.actions.find(
      (x) => x.id === 'preedit'
    );

    // auquel cas on interdit le drop de nouveau elements
    // sur un champ partagé "par référence"
    if (preEditAction?.formData?.method === 'hosted') {
      isHostedSharedField = true;
    }

    if (menu.draggable) {
      returnContent = (
        <Draggable
          draggableId={field.blueprint.id}
          index={index}
          key={field.blueprint.id}
        >
          {({ innerRef, draggableProps, dragHandleProps }) => (
            <Grid
              container
              className={classes.element}
              {...draggableProps}
              {...(!editDraggable && { ...dragHandleProps })}
            >
              {menu.adminElements && (
                <Typography color='textSecondary' variant='subtitle2'>
                  {elementId}
                </Typography>
              )}
              <Grid container alignItems='baseline'>
                <ListItemText key={field.blueprint.id} ref={innerRef}>
                  {elementTitle}
                </ListItemText>
                {(menu.adminElements &&
                  currentMenu &&
                  ActionsGroup(
                    menu,
                    field,
                    actions,
                    ['edit', 'zoom', 'flag', 'copy', 'delete'],
                    dragHandleProps
                  )) ||
                  (menu.adminElements &&
                    ActionsGroup(
                      menu,
                      field,
                      actions,
                      ['zoom'],
                      dragHandleProps
                    ))}
              </Grid>
              {chipsList()}
              {isHostedSharedField ? null : droppableElement()}
            </Grid>
          )}
        </Draggable>
      );
    } else {
      returnContent = (
        <Grid container className={classes.element}>
          <div className={classes.element} id={index}>
            <List>
              <ListItemText key={field.blueprint.id}>
                {field.blueprint.title}
              </ListItemText>
              {menu.adminElements &&
                currentMenu &&
                ActionsGroup(menu, field, actions, [
                  'edit',
                  'zoom',
                  'flag',
                  'copy',
                  'delete',
                ])}
            </List>
          </div>
          {isHostedSharedField ? null : droppableElement()}
        </Grid>
      );
    }
    return returnContent;
  };

  return dynamicElement();
};

Element.defaultProps = {
  localMetaSpec: null,
};

Element.propTypes = {
  localMetaSpec: PropTypes.object,
  field: PropTypes.object,
  menu: PropTypes.object,
  index: PropTypes.any,
  classes: PropTypes.object,
  actions: PropTypes.any,
  currentMenu: PropTypes.bool,
};

export default Element;
