import React from 'react';
import PropTypes from 'prop-types';
import BasicAutocomplete from './basic';
import MultipleAutocomplete from './multiple';
import { checkQueryMinimumLength } from '../../../../../Utils/engine/triggerApi';

const dynamicLoading = (source, query, options, loading) => {
  if (
    source?.minimumChar &&
    !checkQueryMinimumLength(query, source?.stringLength)
  ) {
    return `Veuillez saisir les ${source.stringLength} premiers caractères pour lancer la recherche.`;
  }
  if (options.length === 0 && !loading) {
    return 'Aucun résultat';
  }
  return 'Chargement...';
};

const FieldAutocomplete = (props) => {
  const { schema } = props;

  if (schema?.items) {
    return <MultipleAutocomplete {...props} dynamicLoading={dynamicLoading} />;
  }
  return <BasicAutocomplete {...props} dynamicLoading={dynamicLoading} />;
};

FieldAutocomplete.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string.isRequired,
    '@dynamicData': PropTypes.object.isRequired,
    description: PropTypes.string,
    items: PropTypes.any,
  }),
  required: PropTypes.bool,
  formData: PropTypes.any,
  errorSchema: PropTypes.any,
};

export default FieldAutocomplete;
