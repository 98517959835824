// eslint-disable-next-line consistent-return
function findPath(obj, item, searchKey = 'id') {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (obj[key] && typeof obj[key] === 'object') {
      const result = findPath(obj[key], item, searchKey);
      if (result) {
        result.unshift(key);
        return result;
      }
    } else if (obj[key] === item && key === searchKey) {
      return [key];
    }
  }
}

export default findPath;
