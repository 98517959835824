import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import debounce from 'lodash/debounce';

import { useRecoilValue } from 'recoil';
import currentFormAtom from '../../../../../../Atoms/currentForm';

import {
  getOptionsAsync,
  verificatorAsync,
} from '../../../../../../Utils/engine/triggerApi';
import safelyParseJSON from '../../../../../../Utils/safelyParseJSON';

const BasicAutocomplete = ({
  schema,
  required,
  formData,
  onChange,
  errorSchema,
  dynamicLoading,
}) => {
  const currentForm = useRecoilValue(currentFormAtom);
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const parsedFormData = safelyParseJSON(formData);
  const [currentValue, setCurrentvalue] = useState(
    parsedFormData ? `${parsedFormData}` : ''
  );
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (currentValue.length > 0 && !options.length) {
      setLoading(true);
      verificatorAsync(schema['@verificatorData'], currentValue).then(
        (filteredOptions) => {
          if (filteredOptions.length > 0 && filteredOptions?.[0]?.label) {
            setData(filteredOptions[0].label);
          }
          setOptions(filteredOptions);
          setLoading(false);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (
      schema?.['@dynamicData'] &&
      schema['@dynamicData']?.['@paramsAtFetch'] &&
      schema['@dynamicData']?.['@fetched']
    ) {
      setOptions(schema['@dynamicData']['@fetched']);
    }
  }, [currentForm.dynamicData.updatedTimestamp]);

  const onChangeInput = (e, newInputValue) => {
    setData(newInputValue);
  };

  // const [inputValue, setInputValue] = React.useState('');
  const getOptionsDelayed = useCallback(
    debounce((text, callback) => {
      setOptions([]);
      getOptionsAsync(schema['@dynamicData'], text, currentValue).then(
        callback
      );
    }, 500),
    []
  );

  useEffect(() => {
    setLoading(true);
    getOptionsDelayed(`${data}`, (filteredOptions) => {
      setOptions(filteredOptions);
      setLoading(false);
    });
  }, [data, getOptionsDelayed]);

  return (
    <Autocomplete
      // disable filtering on client side
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label || option}
      getOptionSelected={(option) => option.id || option}
      loading={options.length === 0}
      loadingText={dynamicLoading(
        schema['@dynamicData'],
        data,
        options,
        loading
      )}
      options={options}
      renderInput={(params) => (
        <TextField {...params} label={schema.title} required={required} />
      )}
      value={data}
      onChange={(_event, newValue) => {
        if (newValue?.id) {
          setCurrentvalue(`${newValue.id}`);
          onChange(`${newValue.id}`, errorSchema, 2);
        } else {
          setCurrentvalue('');
          onChange('', errorSchema, 2);
        }
      }}
      onInputChange={(e, newInputValue) => onChangeInput(e, newInputValue)}
    />
  );
};

BasicAutocomplete.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string.isRequired,
    '@dynamicData': PropTypes.object.isRequired,
    '@verificatorData': PropTypes.object.isRequired,
    description: PropTypes.string,
  }),
  required: PropTypes.bool,
  formData: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errorSchema: PropTypes.any,
  dynamicLoading: PropTypes.func,
};

export default BasicAutocomplete;
