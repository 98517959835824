import axios from 'axios';
import jp from 'jsonpath';

const ApiClient = axios.create({});

const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const buildUrl = (url) => {
  if (isValidHttpUrl(url)) {
    return url;
  }
  return window.location.origin + url;
};

const checkRequiredParams = (params, query, value) => {
  let missingParams = false;
  if (params && params.length > 0) {
    params.map((param) => {
      if (param.required && param.value === '@filter' && query === '') {
        missingParams = true;
      } else if (
        !value &&
        param.required &&
        typeof param.value === 'string' &&
        param.value.includes('@value')
      ) {
        missingParams = true;
      }
      return null;
    });
  }
  return !missingParams;
};

const transformParamsToPost = (params, query, currentValue = null) => {
  const data = {};
  if (params && params.length > 0) {
    params.map((param) => {
      if (param.value === '@filter') {
        data[param.key] = query;
      } else if (param.value === '@value') {
        data[param.key] = currentValue;
      } else {
        data[param.key] = param.value;
      }
      return null;
    });
  }
  return data;
};

const transformParamsToGet = (url, params, query, currentValue = null) => {
  let newUrl = `${url}?`;
  if (params) {
    params.map((param) => {
      if (param.value === '@filter') {
        newUrl += `${param.key}=${query}&`;
      } else if (param.value === '@value') {
        newUrl += `${param.key}=${currentValue}&`;
      } else {
        newUrl += `${param.key}=${param.value}&`;
      }
      return null;
    });
  }
  return newUrl;
};

export const checkQueryMinimumLength = (query, minLength) => {
  if (minLength && query.length >= minLength) {
    return true;
  }
  return false;
};

export const getOptionsAsync = (source, query = null, value = '') => {
  if (
    source &&
    source.url &&
    source.method &&
    (query === null ||
      (query.length > 0 &&
        checkQueryMinimumLength(query, source?.stringLength)) ||
      !source?.minimumChar) &&
    checkRequiredParams(source.params, query, false) === true
  ) {
    return new Promise((resolve) => {
      ApiClient.request({
        ...(source.method === 'GET'
          ? {
              url: transformParamsToGet(
                buildUrl(source.url),
                source.params,
                query,
                value
              ),
              method: source.method,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Content-Type': 'application/json',
              },
            }
          : {
              url: buildUrl(source.url),
              method: source.method,
              data: transformParamsToPost(source.params, query, value),
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Content-Type': 'application/json',
              },
            }),
      })
        .then(async (response) => {
          if (response?.data) {
            const destArray = jp.query(response?.data, source.schemaJsonPath);

            if (destArray.length > 0) {
              resolve(
                destArray.map((element) => ({
                  ...(source?.bindKeysTo && {
                    id: element[source.bindKeysTo],
                  }),
                  ...(source?.bindValuesTo && {
                    label: element[source.bindValuesTo],
                  }),
                  ...(source?.bindDefaultCheckedTo && {
                    checked: element[source.bindDefaultCheckedTo],
                  }),
                  ...(source?.bindGroupTo && {
                    group: element[source.bindGroupTo],
                  }),
                }))
              );
            } else {
              resolve([]);
            }
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
          resolve([]);
        });
    });
  }
  return new Promise((resolve) => resolve([]));
};

export const verificatorAsync = (source, value) => {
  if (
    source &&
    source.url &&
    source.method &&
    checkRequiredParams(source.params, null, true) === true
  ) {
    return new Promise((resolve) => {
      ApiClient.request({
        ...(source.method === 'GET'
          ? {
              url: transformParamsToGet(source.url, source.params, null, value),
              method: source.method,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Content-Type': 'application/json',
              },
            }
          : {
              url: source.url,
              method: source.method,
              data: transformParamsToPost(source.params, null, value),
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Content-Type': 'application/json',
              },
            }),
      })
        .then(async (response) => {
          if (response?.data) {
            const destArray = jp.query(response?.data, source.schemaJsonPath);

            if (destArray.length > 0) {
              resolve(
                destArray.map((element) => ({
                  ...(source?.bindKeysTo && {
                    id: element[source.bindKeysTo],
                  }),
                  ...(source?.bindValuesTo && {
                    label: element[source.bindValuesTo],
                  }),
                  ...(source?.bindDefaultCheckedTo && {
                    checked: element[source.bindDefaultCheckedTo],
                  }),
                  ...(source?.bindGroupTo && {
                    group: element[source.bindGroupTo],
                  }),
                }))
              );
            } else {
              resolve([]);
            }
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
          resolve([]);
        });
    });
  }
  return new Promise((resolve) => resolve([]));
};
