import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

const deleteAction = (localMetaSpec, setLocalMetaSpec, setAtomSnackbar) => {
  const { t } = useTranslation();

  const deleteElement = (field, menu) => {
    const removeIndex = menu.elements
      .map((item) => item.blueprint.id)
      .indexOf(field.blueprint.id);

    menu.elements.splice(removeIndex, 1);

    const newMetaSpec = cloneDeep(localMetaSpec);
    newMetaSpec.updateTimestamp = Date.now();
    setLocalMetaSpec(newMetaSpec);

    setAtomSnackbar((prevAtomSnackbar) => ({
      ...prevAtomSnackbar,
      open: true,
      text: t('Snackbar.DeleteField'),
      severity: 'success',
    }));
  }; // fin deleteElement

  return deleteElement;
};

export default deleteAction;
