/**
 * CTF: Exporter un champ
 */
export default ({ newField }) => {
  let flagName = null;

  if (
    newField?.id === 'neolinkNumTelMobileFrancais' &&
    newField?.isField === true &&
    newField?.published === true &&
    newField?.blueprint?.group === 'shared'
  ) {
    flagName = 'exportNewField';
  }

  return flagName;
};
