import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Catalog from './Catalog';
import EditForm from './EditForm';
import withUseResource from './Hoc/withUseResource';

const SimpleContent = ({ resource, resourceData, resourceState, browser }) => {
  const getContent = () => {
    let initialState = [];

    if (resourceData && resourceData.type) {
      switch (resourceData.type) {
        case 'NeoFormBuilder':
          initialState.push(
            <EditForm browser={browser} key={0} resource={resource} />
          );
          break;

        default:
          initialState = [];
          break;
      }
    } else if (resourceState.links.has('collection')) {
      const links = resourceState.links.getMany('collection');
      links.forEach((link, index) => {
        initialState.push(
          <Catalog
            browser={browser}
            key={index}
            resource={resource.go(link.href)}
            title={link.title}
          />
        );
      });
    }
    return initialState;
  };

  const [content, setContent] = useState(getContent);

  useEffect(() => {
    setContent(getContent());
  }, [resourceState]);

  return content;
};

const Content = withUseResource(SimpleContent);

SimpleContent.propTypes = {
  resource: PropTypes.object.isRequired,
  resourceData: PropTypes.object.isRequired,
  resourceState: PropTypes.object.isRequired,
  browser: PropTypes.object,
};

export default Content;
