import { get } from 'lodash';

const prettierBuilderPath = (
  builder,
  builderPath,
  separator = '/',
  toShow = true
) => {
  let currentStringPath = '';
  let prettierPath = '';
  let lastTitle = '';

  if (builderPath?.length > 1) {
    builderPath.splice(-1);
    builderPath.map((position) => {
      if (currentStringPath === '') {
        currentStringPath += position;
      } else {
        currentStringPath += `.${position}`;
      }

      if (!Number.isNaN(Number(position))) {
        const currentPosition = get(builder, `${currentStringPath}.blueprint`);
        if (currentPosition?.id) {
          const currentData = currentPosition?.actions?.find(
            (x) => x.id === 'edit'
          )?.formData;
          if (prettierPath === '') {
            prettierPath += `${
              currentData?.tabs?.data?.id || currentPosition.id
            }`;
          } else {
            prettierPath += `${separator}${
              currentData?.tabs?.data?.id || currentPosition.id
            }`;
          }

          lastTitle = currentData?.tabs?.data?.label || currentPosition.title;
        }
      }
      return null;
    });
    if (toShow) {
      prettierPath += ` - ${lastTitle}`;
    }
  }

  return prettierPath;
};

export default prettierBuilderPath;
