/* eslint-disable no-param-reassign */
import { cloneDeep, get, set, isEqual } from 'lodash';
import { getOptionsAsync } from '../../triggerApi';
import findByKey from '../../../objectManipulation/findByKey';

const extensionDynamicData = async (
  formdata,
  currentJSSchema,
  _jsonSchema,
  setCurrentJSchema,
  setCurrentFormAtom,
  setFormData
) => {
  // eslint-disable-next-line no-unused-vars
  const dynamicDatas = findByKey(currentJSSchema, '@dynamicData');

  let newFormdata = cloneDeep(formdata);
  await Promise.all(
    dynamicDatas.map(async (dynamicData) => {
      const { params } = dynamicData;

      if (dynamicData?.['@dependentParams'] || dynamicData?.actOnFormdata) {
        //   if (!params || params.length === 0) {
        //     return;
        //   }

        let parsedParams = [];
        if (params && params.length > 0 && typeof params !== 'undefined') {
          const clonedParams = cloneDeep(params);
          parsedParams = clonedParams.map((param) => {
            if (param.required && param.value.includes('@value')) {
              const parsedValue = param.value.replace('@value', '');
              // console.log('path', parsedValue);
              const listeningData = get(formdata, parsedValue);
              // console.log('listeningData', listeningData);
              if (listeningData !== undefined) {
                param.value = listeningData;
              }
            }
            return param;
          });
        }

        if (
          !dynamicData['@paramsAtFetch'] ||
          !isEqual(dynamicData['@paramsAtFetch'], parsedParams)
        ) {
          dynamicData['@isFetching'] = true;
          await setCurrentJSchema(currentJSSchema);
          if (!dynamicData.actOnFormdata) {
            setCurrentFormAtom((prev) => ({
              ...prev,
              dynamicData: {
                ...prev.dynamicData,
                updatedTimestamp: Date.now(),
              },
            }));
          }

          await getOptionsAsync({ ...dynamicData, params: parsedParams }).then(
            async (filteredOptions) => {
              dynamicData['@isFetching'] = false;
              dynamicData['@fetched'] = filteredOptions;
              dynamicData['@paramsAtFetch'] = parsedParams;

              if (
                filteredOptions?.[0]?.label &&
                dynamicData?.actOnFormdata === true
              ) {
                const currentFieldFormData = get(
                  formdata,
                  dynamicData['@source']
                );

                if (
                  currentFieldFormData === undefined ||
                  currentFieldFormData.length === 0
                ) {
                  newFormdata = set(
                    newFormdata,
                    dynamicData['@source'],
                    filteredOptions[0].label
                  );
                }
              }
            }
          );
          await setCurrentJSchema(currentJSSchema);
        }
        if (!dynamicData.actOnFormdata) {
          setCurrentFormAtom((prev) => ({
            ...prev,
            dynamicData: {
              ...prev.dynamicData,
              updatedTimestamp: Date.now(),
            },
          }));
        }
      }
      return null;
    })
  );

  if (!isEqual(formdata, newFormdata)) {
    setFormData(newFormdata);
  }
};

export default extensionDynamicData;
