import { get, isEmpty, cloneDeep } from 'lodash';
import jp from 'jsonpath';
import checkDate from './date';
import findYByX from '../../objectManipulation/findYByX';

const neoValidators = (jsonSchema, formData, uiSchema, errors) => {
  const toValidate = findYByX(jsonSchema, 'id', '@validation');

  const assignError = (path, element, formDataAnswer, message) => {
    if (path && !isEmpty(path)) {
      const formDataContent = get(formData, path);
      let formDataIndex = false;

      if (Array.isArray(formDataContent)) {
        formDataContent.map((content, index) => {
          if (
            content[element.receiver] &&
            formDataAnswer === content[element.receiver]
          ) {
            formDataIndex = index;
          }
          return null;
        });
      }
      const errorPath = get(errors, path);

      if (formDataIndex !== false) {
        errorPath[formDataIndex].addError(message);
      } else {
        errorPath.addError(message);
      }
    } else {
      errors.addError(message);
    }
  };

  toValidate.map((element) => {
    let formDataAnswer = get(formData, element?.content?.receiverPath);
    let formDataAnswers = false;
    let formDataPaths = false;

    if (!formDataAnswer && element?.content?.receiverPath) {
      formDataAnswers = jp.query(formData, element.content.receiverPath);
      if (formDataAnswers.length > 0) {
        formDataPaths = jp.paths(formData, element.content.receiverPath);
      }
    }

    let path = '';
    if (formDataAnswers && formDataPaths && formDataAnswers.length > 0) {
      formDataAnswers.forEach((fdAnswer, index) => {
        formDataAnswer = fdAnswer;
        if (formDataPaths[index].length > 1) {
          formDataPaths[index].shift();
          path = formDataPaths[index].join('.');
        }

        if (formDataAnswer) {
          if (
            element?.content?.minLength &&
            formDataAnswer.length < element.content.minLength
          ) {
            assignError(
              path,
              element.content,
              formDataAnswer,
              `La réponse doit comporter au minimum ${element.content.minLength} caractères.`
            );
          }
          if (
            element.content?.maxLength &&
            formDataAnswer.length > element.content.maxLength
          ) {
            assignError(
              path,
              element.content,
              formDataAnswer,
              `La réponse ne doit pas comporter plus de ${element.content.maxLength} caractères.`
            );
          }
          if (
            element.content?.regex &&
            !new RegExp(element.content.regex).test(formDataAnswer)
          ) {
            assignError(
              path,
              element.content,
              formDataAnswer,
              element.content?.regexMessage
                ? element.content.regexMessage
                : 'Format incorrect'
            );
          }
          if (element.content?.minimum > formDataAnswer) {
            assignError(
              path,
              element.content,
              formDataAnswer,
              `Le nombre doit être supérieur ou égal à ${element.content.minimum}`
            );
          }
          if (element.content?.maximum < formDataAnswer) {
            assignError(
              path,
              element.content,
              formDataAnswer,
              `Le nombre doit être inférieur ou égal à ${element.content.maximum}`
            );
          }
          checkDate(
            element.content,
            path,
            formDataAnswer,
            formData,
            assignError
          );
        }
      });
    } else if (formDataAnswer) {
      path = cloneDeep(element?.content?.receiverPath);

      if (
        element?.content?.minLength &&
        formDataAnswer.length < element.content.minLength
      ) {
        assignError(
          path,
          element.content,
          formDataAnswer,
          `La réponse doit comporter au minimum ${element.content.minLength} caractères.`
        );
      }
      if (
        element.content?.maxLength &&
        formDataAnswer.length > element.content.maxLength
      ) {
        assignError(
          path,
          element.content,
          formDataAnswer,
          `La réponse ne doit pas comporter plus de ${element.content.maxLength} caractères.`
        );
      }
      if (
        element.content?.regex &&
        !new RegExp(element.content.regex).test(formDataAnswer)
      ) {
        assignError(
          path,
          element.content,
          formDataAnswer,
          element.content?.regexMessage
            ? element.content.regexMessage
            : 'Format incorrect'
        );
      }
      if (
        typeof formDataAnswer === 'number' &&
        element.content?.minimum > formDataAnswer
      ) {
        assignError(
          path,
          element.content,
          formDataAnswer,
          `Le nombre doit être supérieur ou égal à ${element.content.minimum}`
        );
      }
      if (
        typeof formDataAnswer === 'number' &&
        element.content?.maximum < formDataAnswer
      ) {
        assignError(
          path,
          element.content,
          formDataAnswer,
          `Le nombre doit être inférieur ou égal à ${element.content.maximum}`
        );
      }
      checkDate(element.content, path, formDataAnswer, formData, assignError);
    }
    return null;
  });
  return errors;
};

export default neoValidators;
