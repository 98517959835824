import Keycloak from 'keycloak-js';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';

const UserService = ({ setApiUserService, atomKeycloak, setAtomKeycloak }) => {
  const kc = new Keycloak({
    realm: atomKeycloak.realm ? atomKeycloak.realm : 'job62',
    url: atomKeycloak.url
      ? atomKeycloak.url
      : 'https://auth.neolink.link/auth/',
    clientId: atomKeycloak.clientId ?? 'neolink',
  });

  let retryValue = 1;
  const doLogin = kc.login;
  const initKeycloak = (onAuthenticatedCallback) => {
    kc.init({
      onLoad: 'check-sso',
      checkLoginIframe: false,
    })
      .then((authenticatedInKC) => {
        if (authenticatedInKC) {
          // la redirection me fait perdre l'atome... je le met donc a jour
          const clone = cloneDeep(atomKeycloak);
          clone.realm = kc.realm;
          clone.clientId = kc.clientId;
          clone.token = kc.token;
          clone.refreshToken = kc.refreshToken;
          clone.url = kc.authServerUrl;
          clone.uid = kc.subject;
          setAtomKeycloak(clone);

          if (onAuthenticatedCallback) {
            onAuthenticatedCallback(authenticatedInKC);
          }
        } else {
          doLogin();
        }
      })
      .catch((error) => {
        console.log(`KC Init Error! ${error}`);
        console.log(retryValue);
        if (retryValue > 0) {
          retryValue = 0;
          initKeycloak(onAuthenticatedCallback);
        }
      });
  };

  /**
   * Automatically refresh the token when expired
   */
  kc.onTokenExpired = () => {
    kc.updateToken(5)
      .then(() => {
        setAtomKeycloak((prevAtoKeycloak) => ({
          ...prevAtoKeycloak,
          token: kc.token,
          uid: kc.subject,
          refreshToken: kc.refreshToken,
        }));
      }) // nothing special when successfully refreshed
      .catch(doLogin);
  };
  const getRealm = () => atomKeycloak.realm;

  const InitQuickly = () => {
    kc.init({
      // onLoad: 'login-required',
      onLoad: 'check-sso',
      checkLoginIframe: false,
      // checkLoginIframe: false, // remove checkLoginIframe here
    });
  };
  const getToken = () => kc.token;

  const doLogout = () => {
    // prévenir une erreur si kc n'est pas initialisé correctement
    if (getToken()) {
      kc.logout({
        redirectUri: `${window.location.protocol}//${window.location.host}/realms`,
      });
    }
  };

  const doLogoutManual = () => {};

  const isLoggedIn = () => !!kc.token;

  const updateToken = (successCallback) =>
    kc.updateToken(5).then(successCallback).catch(doLogin);

  const getUsername = () => kc.tokenParsed?.preferred_username;

  const hasRole = (roles) =>
    roles.some((roleTransmitted) => kc.hasRealmRole(roleTransmitted));

  const apiUserService = {
    getRealm,
    InitQuickly,
    initKeycloak,
    doLogin,
    doLogout,
    doLogoutManual,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
  };

  useEffect(() => {
    setApiUserService(apiUserService);
  }, []);

  return null;
};
UserService.propTypes = {
  setApiUserService: PropTypes.func.isRequired,
  setAtomKeycloak: PropTypes.func.isRequired,
  atomKeycloak: PropTypes.object.isRequired,
};
export default UserService;
