import React from 'react';
import PropTypes from 'prop-types';

import { utils } from '@rjsf/core';

import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Remove from '@material-ui/icons/Remove';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';

const { isMultiSelect, getDefaultRegistry } = utils;

const mappingsIcons = {
  remove: Remove,
  plus: AddIcon,
  'arrow-up': ArrowUpward,
  'arrow-down': ArrowDownward,
};

const IconButton = (props) => {
  const { icon, iconProps, ...otherProps } = props;
  const IconComp = mappingsIcons[icon];
  return (
    <Button {...otherProps} size='small'>
      <IconComp {...iconProps} />
    </Button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.any,
  className: PropTypes.any,
  iconProps: PropTypes.any,
};

const FieldArray = (props) => {
  const { schema, registry = getDefaultRegistry() } = props;

  if (isMultiSelect(schema, registry.rootSchema)) {
    return <DefaultFixedArrayFieldTemplate {...props} />;
  }
  return <DefaultNormalArrayFieldTemplate {...props} />;
};

FieldArray.propTypes = {
  schema: PropTypes.any,
  registry: PropTypes.any,
};

const ArrayFieldTitle = ({ TitleField, idSchema, title, required }) => {
  if (!title) {
    return null;
  }

  const id = `${idSchema.$id}__title`;
  return <TitleField id={id} required={required} title={title} />;
};

ArrayFieldTitle.propTypes = {
  TitleField: PropTypes.any,
  idSchema: PropTypes.any,
  title: PropTypes.string,
  required: PropTypes.bool,
};

const ArrayFieldDescription = ({ DescriptionField, idSchema, description }) => {
  if (!description) {
    return null;
  }

  const id = `${idSchema.$id}__description`;
  return <DescriptionField description={description} id={id} />;
};

ArrayFieldDescription.propTypes = {
  DescriptionField: PropTypes.any,
  idSchema: PropTypes.any,
  description: PropTypes.string,
};

// Used in the two templates
const DefaultArrayItem = (props) => {
  const {
    key,
    children,
    hasToolbar,
    hasMoveUp,
    hasMoveDown,
    hasRemove,
    disabled,
    readonly,
    index,
    onReorderClick,
    onDropIndexClick,
  } = props;

  const btnStyle = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: 'bold',
    minWidth: 0,
  };
  return (
    <Grid container alignItems='center' key={key}>
      <Grid item xs style={{ overflow: 'auto' }}>
        <Box mb={2}>
          <Paper variant='outlined'>
            <Box p={2}>{children}</Box>
          </Paper>
        </Box>
      </Grid>

      {hasToolbar && (
        <Grid item>
          {(hasMoveUp || hasMoveDown) && (
            <IconButton
              className='array-item-move-up'
              disabled={disabled || readonly || !hasMoveUp}
              icon='arrow-up'
              iconProps={{ fontSize: 'small' }}
              style={btnStyle}
              tabIndex={-1}
              onClick={onReorderClick(index, index - 1)}
            />
          )}

          {(hasMoveUp || hasMoveDown) && (
            <IconButton
              disabled={disabled || readonly || !hasMoveDown}
              icon='arrow-down'
              iconProps={{ fontSize: 'small' }}
              style={btnStyle}
              tabIndex={-1}
              onClick={onReorderClick(index, index + 1)}
            />
          )}

          {hasRemove && (
            <IconButton
              disabled={disabled || readonly}
              icon='remove'
              iconProps={{ fontSize: 'small' }}
              style={btnStyle}
              tabIndex={-1}
              onClick={onDropIndexClick(index)}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

DefaultArrayItem.propTypes = {
  key: PropTypes.any,
  children: PropTypes.any,
  hasToolbar: PropTypes.any,
  hasMoveUp: PropTypes.any,
  hasMoveDown: PropTypes.any,
  hasRemove: PropTypes.any,
  disabled: PropTypes.any,
  readonly: PropTypes.any,
  index: PropTypes.any,
  onReorderClick: PropTypes.func,
  onDropIndexClick: PropTypes.func,
};

const DefaultFixedArrayFieldTemplate = (props) => {
  const {
    className,
    idSchema,
    required,
    uiSchema,
    title,
    TitleField,
    schema,
    items,
    canAdd,
    disabled,
    readonly,
    onAddClick,
  } = props;

  return (
    <fieldset className={className}>
      <ArrayFieldTitle
        idSchema={idSchema}
        key={`array-field-title-${idSchema.$id}`}
        required={required}
        title={uiSchema['ui:title'] || title}
        TitleField={TitleField}
      />

      {(uiSchema['ui:description'] || schema.description) && (
        <div
          className='field-description'
          key={`field-description-${idSchema.$id}`}
        >
          {uiSchema['ui:description'] || schema.description}
        </div>
      )}

      <div
        className='row array-item-list'
        key={`array-item-list-${idSchema.$id}`}
      >
        {items && items.map(DefaultArrayItem)}
      </div>

      {canAdd && (
        <Button
          className='array-item-add'
          color='secondary'
          disabled={disabled || readonly}
          onClick={onAddClick}
        >
          <AddIcon />
          {uiSchema['ui:addText'] || 'Ajouter'}
        </Button>
      )}
    </fieldset>
  );
};

DefaultFixedArrayFieldTemplate.propTypes = {
  className: PropTypes.any,
  idSchema: PropTypes.any,
  required: PropTypes.any,
  uiSchema: PropTypes.any,
  title: PropTypes.any,
  TitleField: PropTypes.any,
  schema: PropTypes.any,
  items: PropTypes.any,
  canAdd: PropTypes.any,
  disabled: PropTypes.any,
  readonly: PropTypes.any,
  onAddClick: PropTypes.func,
};

const DefaultNormalArrayFieldTemplate = (props) => {
  const {
    idSchema,
    required,
    uiSchema,
    title,
    TitleField,
    DescriptionField,
    schema,
    items,
    canAdd,
    disabled,
    readonly,
    onAddClick,
  } = props;

  return (
    <Paper elevation={2}>
      <Box p={2}>
        <ArrayFieldTitle
          idSchema={idSchema}
          key={`array-field-title-${idSchema.$id}`}
          required={required}
          title={uiSchema['ui:title'] || title}
          TitleField={TitleField}
        />

        {(uiSchema['ui:description'] || schema.description) && (
          <ArrayFieldDescription
            description={uiSchema['ui:description'] || schema.description}
            DescriptionField={DescriptionField}
            idSchema={idSchema}
            key={`array-field-description-${idSchema.$id}`}
          />
        )}

        <Grid container key={`array-item-list-${idSchema.$id}`}>
          {items && items.map((p) => DefaultArrayItem(p))}

          {canAdd && (
            <Grid container justify='flex-end'>
              <Grid item>
                <Box mt={2}>
                  <Button
                    className='array-item-add'
                    color='secondary'
                    disabled={disabled || readonly}
                    onClick={onAddClick}
                  >
                    <AddIcon />
                    {uiSchema?.['ui:options']?.addText || 'Add'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

DefaultNormalArrayFieldTemplate.propTypes = {
  idSchema: PropTypes.any,
  required: PropTypes.any,
  uiSchema: PropTypes.any,
  title: PropTypes.any,
  TitleField: PropTypes.any,
  DescriptionField: PropTypes.any,
  schema: PropTypes.any,
  items: PropTypes.any,
  canAdd: PropTypes.any,
  disabled: PropTypes.any,
  readonly: PropTypes.any,
  onAddClick: PropTypes.func,
};

export default FieldArray;
