import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  CardActions,
  IconButton,
  CardActionArea,
  Button,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import withUseResource from './Hoc/withUseResource';
import PopupDialog from '../../PopupDialog';
import SimpleForm from './SimpleForm';
import ApiClient from '../Utils/ApiClient';

const SimpleProduct = ({
  resourceData,
  setResourceData,
  resourceState,
  catalog,
  resource,
}) => {
  const [actions, setActions] = useState([]);
  const [previewLink, setPreviewLink] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({});
  const { t } = useTranslation();
  const submitFormRef = createRef();

  const onValidateEdit = (response) => {
    if (response.status === 200) {
      setDialogOpen(false);
      setResourceData(response.data);
    }

    if (response?.data?.flash) {
      catalog.flash(response.data.flash);
    }

    // CTFs sur les formulaires
    catalog.flag('onEditProduct', { editResponse: response });
  };

  const onError = (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      if (error.response?.data?.flash) {
        catalog.flash(error.response.data.flash);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
      catalog.flash({
        status: 'error',
        message: 'No response received',
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message);
      catalog.flash({
        status: 'error',
        message: error?.message || 'Unexpected error',
      });
    }
    // console.log(error.config);
  };

  const handleDelete = (product) => {
    ApiClient.delete(product.href, {
      headers: {
        Authorization: `Bearer ${catalog.authUserService.getToken()}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        catalog.remove(response.data._links.deleted);
        setDialogOpen(false);
      }

      if (response?.data?.flash) {
        catalog.flash(response.data.flash);
      }
    });
  };

  const handleEdit = (request, fd) => {
    if (request && request.url && fd) {
      ApiClient.request({
        ...request,
        data: fd,
        headers: {
          Authorization: `Bearer ${catalog.authUserService.getToken()}`,
        },
      })
        .then((response) => {
          onValidateEdit(response);
        })
        .catch((error) => {
          onError(error);
        });
    }
  };

  const relations = [
    {
      name: 'delete',
      icon: <DeleteForeverIcon />,
      action: (product) => {
        // onClick
        // define ConfirmationDialog
        setDialogConfig({
          title: 'Confirmation',
          content: 'Etes-vous sûr de vouloir supprimer cet élément ?',
          actions: (
            <>
              <Button
                autoFocus
                color='primary'
                onClick={() => setDialogOpen(false)}
              >
                {t('Global.Cancel')}
              </Button>
              <Button color='primary' onClick={() => handleDelete(product)}>
                {t('Global.Submit')}
              </Button>
            </>
          ),
        });
        // open ConfirmationDialog
        setDialogOpen(true);
      },
    },
    {
      name: 'edit',
      icon: <EditIcon />,
      action: (product) => {
        const res = resource.go(product.href);
        res
          .get()
          .then((resState) => {
            if (resState.links.has('edit-form')) {
              res.clearCache();
              // define EditionDialog
              setDialogConfig({
                title: 'Edition',
                content: (
                  <SimpleForm
                    handleSubmit={(fd) =>
                      handleEdit(resState?.data?.request, fd)
                    }
                    resource={res.follow('edit-form')}
                    submitFormRef={submitFormRef}
                  />
                ),
                actions: (
                  <>
                    <Button
                      autoFocus
                      color='primary'
                      onClick={() => setDialogOpen(false)}
                    >
                      {t('Global.Cancel')}
                    </Button>
                    <Button
                      color='primary'
                      onClick={() => {
                        submitFormRef.current.click();
                      }}
                    >
                      {t('Global.Submit')}
                    </Button>
                  </>
                ),
              });
              // open EditionDialog
              setDialogOpen(true);
            } else {
              catalog.show(product.href);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  ];

  // Preview
  useEffect(() => {
    setPreviewLink(
      resourceState.links.has('preview')
        ? resourceState.links.get('preview').href
        : resourceState.links.get('self').href
    );
  }, [resourceState]);

  // Actions
  useEffect(() => {
    relations.forEach((rel, index) => {
      if (resourceState.links.has(rel.name)) {
        setActions((oldActions) => [
          ...oldActions,
          <IconButton
            key={index}
            onClick={() => rel.action(resourceState.links.get(rel.name))}
          >
            {rel.icon}
          </IconButton>,
        ]);
      }
    });

    return () => {
      setActions([]);
    };
  }, [resourceState]);

  return (
    <>
      <Grid item>
        <Card>
          <CardActionArea onClick={() => catalog.show(previewLink)}>
            <CardContent>
              <Typography gutterBottom noWrap variant='subtitle1'>
                <Box
                  component='div'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  width={1}
                >
                  {resourceData.name}
                </Box>
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>{actions}</CardActions>
        </Card>
      </Grid>
      <PopupDialog
        config={dialogConfig}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

const Product = withUseResource(SimpleProduct);

SimpleProduct.propTypes = {
  resourceData: PropTypes.object.isRequired,
  setResourceData: PropTypes.func,
  resourceState: PropTypes.object.isRequired,
  catalog: PropTypes.object,
  resource: PropTypes.object,
};

export default Product;
