import { cloneDeep, get, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import findYByX from '../../../../../../Utils/objectManipulation/findYByX';
import findDuplicateInArray from '../../../../../../Utils/objectManipulation/findDuplicateInArray';
import traverseObject from '../../../../../../Utils/objectManipulation/traverseObject';
import prettierBuilderPath from '../../../../../../Utils/builderManipulation/prettierBuilderPath';
import findPath from '../../../../../../Utils/objectManipulation/findPath';

const Dependency = () => {
  const { t } = useTranslation();

  /**
   * Local field's data and meta spec
   */
  let localField = null;
  let localMetaSpec = null;

  /**
   *
   */
  let fieldForms = null;

  /**
   * Internal states
   */
  const availableDep = [];
  const availableDepNames = [];

  const availableDeepDep = [];
  const availableDeepDepNames = [];

  const availableDateFields = [];
  const availableDateFieldsNames = [];

  /**
   * Simple reducer to manipulate some arrays.
   * @param {*[]} state State to manipulate
   * @param {object} action Object which contains the `type` of the action, and the `data` to use
   */
  const reducer = (state, action) => {
    switch (action.type) {
      case 'add':
        state.push(action.data);
        break;

      case 'replace':
        // eslint-disable-next-line no-param-reassign
        state.splice(0, state.length);
        if (Array.isArray(action.data)) {
          if (action.data.length > 0) {
            action.data.forEach((element) => {
              state.push(element);
            });
          }
        } else {
          state.push(action.data);
        }
        break;

      case 'set':
        // eslint-disable-next-line no-param-reassign
        state[action.index] = action.data;
        break;

      case 'reset':
        state.splice(0, state.length);
        break;

      default:
        break;
    }
  };

  /**
   * Reset all internal states
   */
  const resetAll = () => {
    reducer(availableDep, { type: 'reset' });
    reducer(availableDepNames, { type: 'reset' });
    reducer(availableDeepDep, { type: 'reset' });
    reducer(availableDeepDepNames, { type: 'reset' });
    reducer(availableDateFields, { type: 'reset' });
    reducer(availableDateFieldsNames, { type: 'reset' });
  };

  /**
   * Get the action whose id corresponds to `type` from an array of actions of a field.
   * @param {string} type
   * @param {*} actions
   * @returns An object corresponding to the `type` action if found, else null
   */
  const getAction = (type, actions) => {
    let action = null;
    if (type && actions) {
      action = actions.find((x) => x.id === type);
    }
    return action;
  };

  /**
   * Return an object containing the enum and enumNames values parsed from the data `data`.
   * @param {*} data
   * @returns Return an object containing the enum and enumNames values parsed from the data `data`
   */
  const enumValues = (data) => {
    if (
      data.enum &&
      data.enum.length > 0 &&
      data.enum[0].key &&
      data.enum[0].value
    ) {
      return {
        enum: data.enum.map((element) => element.key),
        enumNames: data.enum.map((element) => element.value),
      };
    }
    if (data.enum && data?.enum.length > 0) {
      return { enum: data.enum };
    }
    return {
      enum: [true, false],
      enumNames: data.enumNames,
    };
  };

  /**
   * Parse a menu schema in order to retrieve the sibling fields from which the local field could be dependent. Can also retrieve date fields.
   * @param {*} menuReceived
   * @returns Return true if the local field was retrieve, in which case it internally set the data which will be returned at the end of the initialisation. Else it return false.
   */
  const parseMenuScope = (menuReceived) => {
    let idFound = false;

    if (menuReceived?.elements) {
      Object.keys(menuReceived.elements).map((element) => {
        if (
          menuReceived?.elements[element]?.blueprint?.id ===
          localField.blueprint.id
        ) {
          fieldForms = getAction(
            'edit',
            menuReceived?.elements[element]?.blueprint?.actions
          );
          idFound = true;
        } else if (
          menuReceived.elements[element]?.id &&
          menuReceived.elements[element]?.blueprint?.actions &&
          menuReceived.elements[element]?.blueprint?.id &&
          menuReceived.elements[element]?.blueprint?.title &&
          menuReceived.elements[element]?.blueprint?.id !==
            localField.blueprint.id &&
          menuReceived.elements[element]?.blueprint?.group !== 'layout'
        ) {
          const DepFormData = getAction(
            'edit',
            menuReceived.elements[element].blueprint.actions
          )?.formData;

          if (
            menuReceived.elements[element].id === 'neoform-field-string-date'
          ) {
            reducer(availableDateFields, {
              type: 'add',
              data: menuReceived.elements[element].blueprint.id,
            });

            reducer(availableDateFieldsNames, {
              type: 'add',
              data: `${DepFormData?.tabs?.data?.label} - ${DepFormData?.tabs?.data?.id}`,
            });
          } else {
            reducer(availableDep, {
              type: 'add',
              data: menuReceived.elements[element].blueprint.id,
            });

            reducer(availableDepNames, {
              type: 'add',
              data: `${DepFormData?.tabs?.data?.label} - ${DepFormData?.tabs?.data?.id}`,
            });
          }
        }
        return null;
      });
    }

    return idFound;
  };

  /**
   * Search for deep elements from which we can be dependant.
   */
  const searchDeepElements = () => {
    traverseObject(localMetaSpec.menus.formBuilder, (k, v) => {
      if (
        v?.id &&
        v?.title &&
        v?.actions &&
        v.id !== localField?.blueprint?.id &&
        v?.group !== 'layout'
      ) {
        const DepFormData = getAction('edit', v.actions)?.formData;

        reducer(availableDeepDep, {
          type: 'add',
          data: v?.id,
        });

        reducer(availableDeepDepNames, {
          type: 'add',
          data: `${DepFormData?.tabs?.data?.label} - ${DepFormData?.tabs?.data?.id}`,
        });
      }
    });

    // Deduplicate deep dependencies elements
    const duplicatedDeepElements = findDuplicateInArray(availableDeepDep);
    if (duplicatedDeepElements.length) {
      duplicatedDeepElements.map((duplicate) => {
        const duplicatedPaths = findYByX(
          localMetaSpec?.menus?.formBuilder,
          'id',
          duplicate
        );

        let i = 0;
        reducer(availableDeepDep, {
          type: 'replace',
          data: availableDeepDep.map((element, index) => {
            if (element === duplicate) {
              const currentI = i;
              i += 1;
              reducer(availableDeepDepNames, {
                type: 'set',
                data: prettierBuilderPath(
                  localMetaSpec.menus.formBuilder,
                  duplicatedPaths[currentI]?.path
                ),
                index,
              });
              return `${duplicatedPaths[currentI]?.path} ${element}`;
            }
            return element;
          }),
        });
        return null;
      });
    }
  };

  /**
   * Search for sibling elements from which we can be dependant.
   */
  const searchElements = () => {
    if (localMetaSpec?.menus?.formBuilder?.elements) {
      if (parseMenuScope(localMetaSpec.menus.formBuilder) === false) {
        const findMenuPath = findPath(localMetaSpec, localField.blueprint.id);

        if (findMenuPath?.length > 0) {
          // Here we get the path thanks to an id from a property of a dependencie
          // So we move back 1 to arrive on the property of dependencie,
          // then 1 again to obtain the dependencie item, and 1 and 1 again to reach the full object path
          findMenuPath.splice(-4, 4);
        }

        const findMenu =
          findMenuPath?.length > 0 ? get(localMetaSpec, findMenuPath) : false;

        reducer(availableDep, { type: 'reset' });
        reducer(availableDepNames, { type: 'reset' });
        if (findMenu) {
          parseMenuScope(findMenu);
        }
      }
    }
  };

  /**
   * Clean deep and sibling elements from which we can be dependant.
   * Here clean means we should't find the same element in both dependency types.
   */
  const cleanDependenciesElements = () => {
    const cleanedDeepDep = availableDeepDep.filter(
      (item) => !availableDep.includes(item)
    );
    const cleanedDeepDepNames = availableDeepDepNames.filter(
      (item) => !availableDepNames.includes(item)
    );

    reducer(availableDeepDep, { type: 'replace', data: cleanedDeepDep });
    reducer(availableDeepDepNames, {
      type: 'replace',
      data: cleanedDeepDepNames,
    });
  };

  /**
   * Parse deep and sibling dependency elements.
   */
  const parseDependenciesElements = () => {
    // PRE REMPLISSAGE DEPENDANCES SIMPLES
    if (availableDep.length > 0 || availableDeepDep.length > 0) {
      const selectAvailableDepPath = findPath(
        localField,
        'dependencieActivation'
      );

      if (selectAvailableDepPath) {
        // Here we get the path thanks to an id 'dependencieActivation' from a property of a dependencie
        // So we move back 1 to arrive on the property of dependencie,
        // then 1 again to obtain the dependencie item, and 1 again to reach the full object path
        selectAvailableDepPath.splice(-3, 3);
        const selectAvailableDep = get(localField, selectAvailableDepPath);
        selectAvailableDep.properties.dependencieActivation = {
          ...selectAvailableDep.properties.dependencieActivation,
          enum: [
            ...(availableDep.length > 0 ? ['Yes'] : []),
            ...(availableDeepDep.length > 0 ? ['YesDeeper'] : []),
            'No',
          ],
          enumNames: [
            ...(availableDep.length > 0 ? [t('Global.Yes')] : []),
            ...(availableDeepDep.length > 0
              ? [t('NeoBuilder.Edit.Dependencies.YesDeeper')]
              : []),
            t('Global.No'),
          ],
        };
        selectAvailableDep.dependencies = {
          ...selectAvailableDep.dependencies,
          dependencieActivation: {
            oneOf: [
              {
                properties: {
                  dependencieActivation: {
                    enum: ['No'],
                  },
                },
              },
            ],
          },
        };

        if (availableDep.length > 0) {
          selectAvailableDep.dependencies.dependencieActivation.oneOf.push({
            required: ['availableDependencies'],
            properties: {
              dependencieActivation: {
                enum: ['Yes'],
              },
              availableDependencies: {
                id: 'availableDependencies',
                type: 'string',
                title: 'Parent Field',
                enum: availableDep,
                enumNames: availableDepNames,
              },
            },
          });
        }
        if (availableDeepDep.length > 0) {
          selectAvailableDep.dependencies.dependencieActivation.oneOf.push({
            required: ['availableDeepDependencies'],
            properties: {
              dependencieActivation: {
                enum: ['YesDeeper'],
              },
              availableDeepDependencies: {
                id: 'availableDeepDependencies',
                type: 'string',
                title: 'Parent Field',
                enum: availableDeepDep,
                enumNames: availableDeepDepNames,
              },
            },
          });
        }
      }
    }
    // PRE REMPLISSAGE DATE DEFAULT LISTE
    if (availableDateFields.length > 0 || availableDateFieldsNames.length > 0) {
      const priorDateFieldPath = findPath(
        localField,
        '@priorDateFieldDependency'
      );
      const posteriorDateFieldPath = findPath(
        localField,
        '@posteriorDateFieldDependency'
      );

      if (priorDateFieldPath) {
        // Here we get the path thanks to an id '@dateFieldDependency' from a property of a dependencie
        // So we move back 1 to arrive on the property of dependencie,
        // then 1 again to obtain the dependencie item, and 1 again to reach the full object path
        priorDateFieldPath.splice(-3, 3);
        const priorDateField = get(localField, priorDateFieldPath);
        if (
          !priorDateField?.properties?.priorFieldDate?.enum ||
          !isEqual(
            priorDateField.properties.priorFieldDate.enum,
            availableDateFields
          )
        ) {
          priorDateField.properties.priorFieldDate = {
            ...priorDateField.properties.priorFieldDate,
            enum: [...availableDateFields],
            enumNames: [...availableDateFieldsNames],
          };
        }
      }

      if (posteriorDateFieldPath) {
        // Here we get the path thanks to an id '@dateFieldDependency' from a property of a dependencie
        // So we move back 1 to arrive on the property of dependencie,
        // then 1 again to obtain the dependencie item, and 1 again to reach the full object path
        posteriorDateFieldPath.splice(-3, 3);
        const posteriorDateField = get(localField, posteriorDateFieldPath);
        if (
          !posteriorDateField?.properties?.posteriorFieldDate?.enum ||
          !isEqual(
            posteriorDateField.properties.posteriorFieldDate.enum,
            availableDateFields
          )
        ) {
          posteriorDateField.properties.posteriorFieldDate = {
            ...posteriorDateField.properties.posteriorFieldDate,
            enum: [...availableDateFields],
            enumNames: [...availableDateFieldsNames],
          };
        }
      }
    }
  };

  /**
   * Init dependency
   * @param {*} field
   * @param {*} metaSpec
   */
  const init = (field, metaSpec) => {
    resetAll();

    localField = field;
    localMetaSpec = metaSpec;

    searchDeepElements();
    searchElements();

    cleanDependenciesElements();
    parseDependenciesElements();

    return fieldForms;
  };

  /**
   * Checks for which types of dependencies the local field could have with the other fields.
   * @param {*} formData
   */
  const checkPossibilities = (formData) => {
    if (formData?.tabs?.dependencie?.dependencieActivation) {
      switch (formData.tabs.dependencie.dependencieActivation) {
        case 'No':
          // eslint-disable-next-line no-param-reassign
          delete formData.tabs.dependencie.availableDeepDependencies;
          // eslint-disable-next-line no-param-reassign
          delete formData.tabs.dependencie.availableDependencies;
          break;

        case 'Yes':
          // eslint-disable-next-line no-param-reassign
          delete formData.tabs.dependencie.availableDeepDependencies;
          break;

        case 'YesDeeper':
          // eslint-disable-next-line no-param-reassign
          delete formData.tabs.dependencie.availableDependencies;
          break;

        default:
          break;
      }
    }
  };

  /**
   * Search for all nodes of the local schema which have the id `id`.
   * @param {*} id
   * @returns An array of nodes with the id `id`
   */
  const getNodesWithId = (id) => {
    let objectFound = null;
    if (id && localMetaSpec?.menus?.formBuilder) {
      objectFound = findYByX(localMetaSpec.menus.formBuilder, 'id', id);
    }
    return objectFound;
  };

  /**
   * Retrieve the form data corresponding to the "edit" action of the node with the id `id` among all the nodes `nodes`.
   * @param {*} nodes
   * @param {*} id
   * @param {*} mode
   * @returns An object representing the form data of the "edit" action of the found node
   */
  const getFormDataFetched = (nodes, id, _mode = '') => {
    let formDataFetched = {};

    // switch (mode) {
    // case 'deep':
    // formDataFetched = nodes[0]?.content;
    //   break;

    // default:
    nodes.forEach((element) => {
      const actions = element?.content?.actions;
      const editAction = getAction('edit', actions);
      if (actions && editAction) {
        formDataFetched = editAction?.formData?.tabs?.data;
      } else if (element?.content?.id === id && !actions) {
        formDataFetched = element.content;
      }
    });
    // break;
    // }

    return formDataFetched;
  };

  /**
   * Retrieve the json schema corresponding to the "edit" action among all the nodes `nodes`.
   * @param {*} nodes
   * @returns An object representing the json schema of the found node
   */
  const getJsonSchemaFetched = (nodes) => {
    let jsonSchemaFetched = [];
    if (nodes?.length > 0) {
      const actions = nodes[0]?.content?.actions;
      const editAction = getAction('edit', actions);

      if (editAction?.jsonSchema) {
        jsonSchemaFetched = editAction.jsonSchema;
      }
    }
    return jsonSchemaFetched;
  };

  /**
   * Finds the path to the `to` destination in the json schema `currentJSchema`.
   * @param {*} currentJSchema
   * @param {*} to
   * @returns An array of string representing the path to the destination
   */
  const getAvailablePath = (currentJSchema, to) => findPath(currentJSchema, to);

  /**
   * Gets the type of dynamic dependency among all the nodes `nodes`
   * @param {*} nodes
   * @returns A string corresponding to the type of dynamic dependency found. Could be `undefined`.
   */
  const getDynamicDependencyType = (nodes) => {
    const JsonSchemaFetched = getJsonSchemaFetched(nodes);

    const contentTypeRestrictedPath = findPath(
      JsonSchemaFetched,
      'enumDataTypePath'
    );

    if (contentTypeRestrictedPath) {
      contentTypeRestrictedPath.splice(-1, 1);
    }

    const newFetchedJsonSchema = get(
      JsonSchemaFetched,
      contentTypeRestrictedPath
    );

    return newFetchedJsonSchema?.properties?.enum?.items?.type;
  };

  /**
   * Checks if this is a dynamic choice list of strings.
   * @param {*} mode
   * @param {*} nodes
   * @param {*} formDataFetched
   * @returns True if it is, else false
   */
  const isDynamicMultiChoiceListString = (mode, nodes, formDataFetched) => {
    let ok = false;

    if (mode === 'deep' && formDataFetched.dynamicData) {
      ok = true;
    } else if (
      nodes[0].content.type === 'string' &&
      formDataFetched.dynamicData
    ) {
      ok = true;
    }

    return ok;
  };

  /**
   * Checks if this is a simple choice liste of strings.
   * @param {*} mode
   * @param {*} nodes
   * @param {*} formDataFetched
   * @param {*} newSchema
   * @returns True if it is, else false
   */
  const isMultiChoiceListString = (mode, nodes, formDataFetched, newSchema) => {
    let ok = false;

    if (mode === 'deep') {
      if (
        !newSchema?.dependencies?.availableDeepDependencies?.properties
        // ||
        // newSchema.dependencies.availableDeepDependencies.properties
        //   ?.trigger?.default !== formDataFetched.id
      ) {
        ok = true;
      }
    } else if (
      nodes[0].content.type === 'string' &&
      formDataFetched.enum &&
      (!newSchema?.dependencies?.availableDependencies ||
        !isEqual(
          newSchema.dependencies.availableDependencies.properties
            ?.dynamicDependencies?.items?.enum,
          formDataFetched.enum
        ))
    ) {
      ok = true;
    }

    return ok;
  };

  /**
   * Checks if this is a simple choice liste of boolean.
   * @param {*} mode
   * @param {*} nodes
   * @param {*} formDataFetched
   * @param {*} newSchema
   * @returns True if it is, else false
   */
  const isMultiChoiceListBoolean = (
    mode,
    nodes,
    formDataFetched,
    newSchema
  ) => {
    let ok = false;

    if (
      mode !== 'deep' &&
      nodes[0].content.type === 'boolean' &&
      (!newSchema?.dependencies?.availableDependencies ||
        !isEqual(
          newSchema.dependencies.availableDependencies.properties
            ?.dynamicDependencies?.items?.enumNames,
          formDataFetched.enumNames
        ))
    ) {
      ok = true;
    }

    return ok;
  };

  /**
   * Updates the dependency portion of the json schema to enact a dynamic choice list of strings.
   * @param {*} mode
   * @param {*} availableType
   * @param {*} formDataFetched
   * @param {*} triggerPath
   * @returns An object corresponding to the new dependency portion of the json schema
   */
  const updateDependencySchemaForDynamicStringList = (
    mode,
    availableType,
    formDataFetched,
    triggerPath
  ) => ({
    [availableType]: {
      properties: {
        ...(formDataFetched !== 'free'
          ? {
              dynamicDependencies: {
                type: 'array',
                title: t('NeoBuilder.Edit.Dependencies.TrigerringValues'),
                '@dynamicData': {
                  '@dependentParams': false,
                  ...formDataFetched.dynamicData,
                },
                uniqueItems: true,
                items: {
                  type: 'string',
                },
              },
              ...(mode === 'deep'
                ? {
                    trigger: {
                      title: 'Trigger',
                      type: 'string',
                      default: triggerPath,
                    },
                  }
                : {}),
            }
          : {}),
      },
    },
  });

  /**
   * Updates the dependency portion of the json schema to enact a choice list of strings.
   * @param {*} mode
   * @param {*} availableType
   * @param {*} formDataFetched
   * @param {*} dynamicDepType
   * @param {*} triggerPath
   * @returns An object corresponding to the new dependency portion of the json schema
   */
  const updateDependencySchemaForStringList = (
    mode,
    availableType,
    formDataFetched,
    dynamicDepType,
    triggerPath
  ) => ({
    [availableType]: {
      properties: {
        ...(((formDataFetched?.enum && formDataFetched?.enum?.length > 0) ||
          (formDataFetched?.enumNames &&
            formDataFetched?.enumNames?.length > 0)) &&
        formDataFetched.contentType !== 'free'
          ? {
              dynamicDependencies: {
                type: 'array',
                title: t('NeoBuilder.Edit.Dependencies.TrigerringValues'),
                items: {
                  ...(dynamicDepType
                    ? { type: dynamicDepType }
                    : {
                        ...(formDataFetched?.enum &&
                        formDataFetched?.enum?.length > 0
                          ? { type: 'string' }
                          : { type: 'boolean' }),
                      }),
                  ...enumValues(formDataFetched),
                },
                uniqueItems: true,
              },
              ...(mode === 'deep'
                ? {
                    trigger: {
                      type: 'string',
                      title: 'Trigger',
                      default: triggerPath,
                    },
                  }
                : {}),
            }
          : {
              ...(mode === 'deep'
                ? {
                    trigger: {
                      type: 'string',
                      title: 'Trigger',
                      default: triggerPath,
                    },
                  }
                : {}),
            }),
      },
    },
  });

  /**
   * Updates the dependency portion of the json schema to enact a choix list of booleans.
   * @param {*} _mode
   * @param {*} availableType
   * @param {*} formDataFetched
   * @param {*} dynamicDepType
   * @param {*} _triggerPath
   * @returns An object corresponding to the new dependency portion of the json schema
   */
  const updateDependencySchemaForBooleanList = (
    _mode,
    availableType,
    formDataFetched,
    dynamicDepType,
    _triggerPath
  ) => ({
    [availableType]: {
      properties: {
        ...(((formDataFetched?.enum && formDataFetched?.enum?.length > 0) ||
          (formDataFetched?.enumNames &&
            formDataFetched?.enumNames?.length > 0)) &&
        formDataFetched.contentType !== 'free'
          ? {
              dynamicDependencies: {
                type: 'array',
                title: t('NeoBuilder.Edit.Dependencies.TrigerringValues'),
                items: {
                  ...(dynamicDepType
                    ? { type: dynamicDepType }
                    : {
                        ...(formDataFetched.enum &&
                        formDataFetched?.enum.length > 0
                          ? { type: 'string' }
                          : { type: 'boolean' }),
                      }),
                  ...enumValues(formDataFetched),
                },
                uniqueItems: true,
              },
            }
          : {
              dynamicDependencies: {
                type: 'array',
                title: t('NeoBuilder.Edit.Dependencies.TrigerringValues'),
                items: {
                  type: 'boolean',
                  enum: [true, false],
                },
                uniqueItems: true,
              },
            }),
      },
    },
  });

  /**
   * Checks for all available dependencies in the neighborhood of the local field, based on the `mode`.
   * @param {*} mode - Type of neighborhood dependencies to search.
   * @param {*} cpySchema
   * @param {*} formData
   * @param {*} currentJSchema
   * @param {*} setJSchema
   * @param {*} currentUiSchema
   * @param {*} setCurrentUiSchema
   * @param {*} setFormData
   * @param {*} _oldFormData
   */
  const checkDependencies = (
    mode,
    cpySchema,
    formData,
    currentJSchema,
    setJSchema,
    currentUiSchema,
    setCurrentUiSchema,
    setFormData,
    _oldFormData
  ) => {
    const availableType =
      mode === 'deep' ? 'availableDeepDependencies' : 'availableDependencies';

    if (formData && formData?.tabs?.dependencie?.[availableType]) {
      const ID = formData.tabs.dependencie[availableType];
      const nodes = getNodesWithId(ID);

      if (nodes?.length > 0) {
        const availableDepPath = getAvailablePath(
          currentJSchema,
          availableType
        );

        const formDataFetched = getFormDataFetched(nodes, ID, mode);

        if (availableDepPath) {
          // Here we get the path thanks to an id 'availableType' from a property of a dependencie
          // So we move back 1 to arrive on the property of dependencie,
          // then 1 again to reach the full object path
          availableDepPath.splice(-2, 2);

          const newSchema = get(cpySchema, availableDepPath);
          const triggerPath =
            mode === 'deep'
              ? prettierBuilderPath(
                  localMetaSpec.menus.formBuilder,
                  nodes[0]?.path,
                  '.',
                  false
                )
              : {};

          const dynamicDepType = getDynamicDependencyType(nodes);

          if (!isEqual(formData.tabs.dependencie.trigger, triggerPath)) {
            // eslint-disable-next-line no-param-reassign
            formData.tabs.dependencie.dynamicDependencies = [];
            // eslint-disable-next-line no-param-reassign
            formData.tabs.dependencie.trigger = triggerPath;
          }

          if (isDynamicMultiChoiceListString(mode, nodes, formDataFetched)) {
            newSchema.dependencies = updateDependencySchemaForDynamicStringList(
              mode,
              availableType,
              formDataFetched,
              triggerPath
            );

            const newUiSchema = cloneDeep(currentUiSchema);

            newUiSchema.tabs.dependencie.dynamicDependencies = {
              'ui:field': 'NeoDynamicBoxes',
            };

            setCurrentUiSchema(newUiSchema);
            // setJSchema(cpySchema);
            // setFormData(formData);
          } else if (
            isMultiChoiceListString(mode, nodes, formDataFetched, newSchema)
          ) {
            newSchema.dependencies = updateDependencySchemaForStringList(
              mode,
              availableType,
              formDataFetched,
              dynamicDepType,
              triggerPath
            );

            // setJSchema(cpySchema);
            // setFormData(formData);
          } else if (
            isMultiChoiceListBoolean(mode, nodes, formDataFetched, newSchema)
          ) {
            newSchema.dependencies = updateDependencySchemaForBooleanList(
              mode,
              availableType,
              formDataFetched,
              dynamicDepType,
              triggerPath
            );

            // setJSchema(cpySchema);
            // setFormData(formData);
          }
        } else if (mode !== 'deep') {
          // eslint-disable-next-line no-param-reassign
          formData.tabs.dependencie.dependencieActivation = 'No';
          // Code mort suite aux refactorisations ???
          // } else {
          // eslint-disable-next-line no-param-reassign
          // formData.tabs.dependencie.trigger = formDataFetched.id;
        }

        if (formData.tabs.dependencie?.dynamicDependencies) {
          let itemIndex = 0;

          // eslint-disable-next-line no-restricted-syntax
          for (const value of formData.tabs.dependencie.dynamicDependencies) {
            if (
              formDataFetched?.enum &&
              !formDataFetched.enum.includes(value)
            ) {
              formData.tabs.dependencie.dynamicDependencies.splice(
                itemIndex,
                1
              );
              // Code mort suite aux refactorisations ???
              // } else if (
              //   mode === 'deep' &&
              //   !formDataFetched?.enum &&
              //   value !== true &&
              //   value !== false
              // ) {
              //   formData.tabs.dependencie.dynamicDependencies.splice(
              //     itemIndex,
              //     1
              //   );
            }
            itemIndex += 1;
          }
        }
        setJSchema(cpySchema);
        setFormData(formData);
      }
    }
  };

  /**
   * Checks for all dependencies modifications to apply based on the user choices during the configuration of the local field.
   */
  const handleChange = (
    cpySchema,
    formData,
    currentJSchema,
    setJSchema,
    currentUiSchema,
    setCurrentUiSchema,
    setFormData,
    oldFormData
  ) => {
    checkPossibilities(formData);
    checkDependencies(
      'simple',
      cpySchema,
      formData,
      currentJSchema,
      setJSchema,
      currentUiSchema,
      setCurrentUiSchema,
      setFormData,
      oldFormData
    );
    checkDependencies(
      'deep',
      cpySchema,
      formData,
      currentJSchema,
      setJSchema,
      currentUiSchema,
      setCurrentUiSchema,
      setFormData,
      oldFormData
    );
  };

  /**
   * Publics methods of the dependency' tab of the edit action.
   */
  return {
    /** Tab's initialisation */
    init,
    /** Dependency changes management */
    handleChange,
  };
};

export default Dependency;
