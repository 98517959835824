/**
 * CTF: Création d'un formulaire
 */
export default ({ createResponse }) => {
  let flagName = null;

  if (createResponse?.data?._links?.self?.href?.includes('/forms/')) {
    flagName = 'createForm';
  }

  return flagName;
};
