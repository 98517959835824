const findByKeyValue = (tree, y, results = []) => {
  const r = results;
  if (typeof tree !== 'undefined' && tree !== null) {
    if (
      tree &&
      Object.keys(tree).find((element) => element === y) &&
      tree[y] &&
      (r.length < 1 || r[r.length - 1] !== tree[y])
    ) {
      r.push(tree[y]);
      return r;
    }
    Object.keys(tree).forEach((key) => {
      const node = tree[key];
      if (
        node &&
        Object.keys(node).find((element) => element === y) &&
        (node[y] === false || node[y])
      ) {
        r.push(node[y]);
      }

      if (typeof node === 'object') {
        findByKeyValue(node, y, r);
      }
    });
  }
  return r;
};

export default findByKeyValue;
