/* eslint-disable no-param-reassign */
import { cloneDeep, get } from 'lodash';
import { utils } from '@rjsf/core';
import findYByX from '../../../objectManipulation/findYByX';
import findByKey from '../../../objectManipulation/findByKey';
import getUsedFormData from '../../../objectManipulation/getUsedFormData';
import getFieldNames from '../../../objectManipulation/getFieldNames';

const extensionDeepDependencie = async (
  formdata,
  uiSchema,
  jsonSchema,
  setCurrentUiSchema,
  setFormData,
  setCurrentFormAtom
) => {
  let change = false;
  const deepDependencies = findYByX(jsonSchema, 'id', '@deepDependencie');

  const hide = (deepDependencie, newSchemaUi) => {
    if (deepDependencie?.object) {
      if (!(`@HiddenObject@${deepDependencie.receiver}` in newSchemaUi)) {
        newSchemaUi[`@HiddenObject@${deepDependencie.receiver}`] =
          newSchemaUi[deepDependencie.receiver];
      }
      newSchemaUi[deepDependencie.receiver] = {
        'ui:field': 'NeoInvisible',
      };
      change = true;
    } else {
      if (!(`@Hidden@${deepDependencie.receiver}` in newSchemaUi)) {
        newSchemaUi[`@Hidden@${deepDependencie.receiver}`] =
          newSchemaUi[deepDependencie.receiver];
      }
      newSchemaUi[deepDependencie.receiver] = { 'ui:widget': 'hidden' };
      change = true;
    }
  };

  const show = (deepDependencie, newSchemaUi) => {
    if (deepDependencie?.object) {
      if (`@HiddenObject@${deepDependencie.receiver}` in newSchemaUi) {
        const val = newSchemaUi[`@HiddenObject@${deepDependencie.receiver}`];
        newSchemaUi[deepDependencie.receiver] = val;
        change = true;
      }
    } else if (`@Hidden@${deepDependencie.receiver}` in newSchemaUi) {
      const val = newSchemaUi[`@Hidden@${deepDependencie.receiver}`];
      newSchemaUi[deepDependencie.receiver] = val;
      change = true;
    }
  };

  if (deepDependencies.length > 0) {
    const retrievedSchema = utils.retrieveSchema(
      jsonSchema,
      jsonSchema,
      formdata
    );
    const pathSchema = utils.toPathSchema(
      retrievedSchema,
      '',
      jsonSchema,
      formdata
    );

    const fieldNames = getFieldNames(pathSchema, formdata);
    const filteredFormData = getUsedFormData(formdata, fieldNames);
    deepDependencies.map((deepDependencie) => {
      let formDataAnswer = findByKey(
        filteredFormData,
        deepDependencie.content.trigger
      );

      if (!formDataAnswer || formDataAnswer.length === 0) {
        formDataAnswer = get(
          filteredFormData,
          deepDependencie?.content?.trigger
        );
      }
      const receiverPathArr = deepDependencie.content.receiverPath.split('.');

      if (
        receiverPathArr.length > 0 &&
        receiverPathArr[receiverPathArr.length - 1] ===
          deepDependencie.content.receiver
      ) {
        receiverPathArr.pop();
      }

      let newSchemaUi = uiSchema;
      if (receiverPathArr.length > 0) {
        const receiverPath = receiverPathArr.join('.').replace('[*]', '');
        newSchemaUi = get(uiSchema, receiverPath);
      }

      if (deepDependencie.content.onValues) {
        let found = false;
        deepDependencie.content.onValues.map((value) => {
          if (
            Array.isArray(formDataAnswer) &&
            formDataAnswer &&
            formDataAnswer.includes(value)
          ) {
            found = true;
          } else if (typeof formDataAnswer === 'object') {
            formDataAnswer.map((arrayContent) => {
              if (Array.isArray(arrayContent) && arrayContent.includes(value)) {
                found = true;
              }
              return null;
            });
          } else if (
            typeof formDataAnswer !== 'undefined' &&
            formDataAnswer === value
          ) {
            found = true;
          }
          return null;
        });
        if (found) {
          show(deepDependencie.content, newSchemaUi);
        } else {
          hide(deepDependencie.content, newSchemaUi);
        }
      } else if (formDataAnswer && formDataAnswer.length > 0) {
        show(deepDependencie.content, newSchemaUi);
      } else {
        hide(deepDependencie.content, newSchemaUi);
      }
      return null;
    });
    if (change) {
      const cpyUiSchema = cloneDeep(uiSchema);
      setFormData(cloneDeep(formdata));
      await setCurrentUiSchema(cpyUiSchema);
      setCurrentFormAtom((prev) => ({
        ...prev,
        deepDependencies: {
          ...prev.deepDependencies,
          updatedTimestamp: Date.now(),
        },
      }));
    }
    const inputs = document.getElementsByClassName('NeoInvisible');

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      const grandParent = input.parentElement.parentElement;
      grandParent.classList.add('GP-NeoInvisible');
      grandParent.classList.remove('GP-NeoVisible');
    }

    const gpInputs = document.getElementsByClassName('GP-NeoInvisible');

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < gpInputs.length; i++) {
      const gpInput = gpInputs[i];
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < gpInput.childNodes.length; j++) {
        // eslint-disable-next-line no-plusplus
        for (let k = 0; k < gpInput.childNodes[j].childNodes.length; k++) {
          if (
            !gpInput.childNodes[j].childNodes[k].classList.contains(
              'NeoInvisible'
            )
          ) {
            gpInput.classList.remove('GP-NeoInvisible');
            gpInput.classList.add('GP-NeoVisible');
          }
        }
      }
    }
  }
};

export default extensionDeepDependencie;
