import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import components from './config/components';

const NeoMarkdown = ({ content }) => (
  <ReactMarkdown
    components={components}
    escapeHtml={false}
    remarkPlugins={[gfm]}
  >
    {content}
  </ReactMarkdown>
);

NeoMarkdown.propTypes = {
  content: PropTypes.string.isRequired,
};

export default NeoMarkdown;
