import React, { createRef } from 'react';
import Button from '@material-ui/core/Button';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import NeoFormUse from '../../../NeoFormUse';
import traverseObject from '../../../../../Utils/objectManipulation/traverseObject';

const exportAction = (
  handlePreExportField,
  handleExportField,
  setDialogConfig,
  setDialogOpen,
  setAtomSnackbar
) => {
  const { t } = useTranslation();
  const submitFormRef = createRef();

  const flagElement = (field, _menu) => {
    const fieldDeepCloned = cloneDeep(field);

    traverseObject(fieldDeepCloned, (k, v) => {
      if (k === 'blueprint' && v?.id) {
        // eslint-disable-next-line no-param-reassign
        v.id = uuidv4();
      }
    });

    const formFlagElement = (extraErrors, formData) => ({
      title: '',
      content: (
        <NeoFormUse
          builder
          customSubmit={
            <Button
              ref={submitFormRef}
              style={{ display: 'none' }}
              type='submit'
            >
              submitForm
            </Button>
          }
          extraErrors={extraErrors}
          formDataReceived={formData}
          handleChange={handlePreExportField}
          // eslint-disable-next-line no-use-before-define
          handleSubmit={handleFlagValidation}
          JsonSchema={{
            type: 'object',
            title: 'Partager un champ',
            required: ['id', 'name'],
            properties: {
              id: {
                type: 'string',
                title: 'Identifiant du champ',
              },
              name: {
                type: 'string',
                title: 'Libellé du champ',
              },
              notes: {
                type: 'string',
                title: 'Commentaire',
              },
            },
          }}
          UiSchema={{
            id: {
              'ui:autofocus': true,
            },
            notes: {
              'ui:widget': 'textarea',
            },
          }}
        />
      ),
      actions: (
        <>
          <Button
            color='primary'
            onClick={() => {
              setDialogOpen(false);
            }}
          >
            {t('Global.Cancel')}
          </Button>
          <Button
            color='primary'
            onClick={() => {
              submitFormRef.current.click();
            }}
          >
            {t('Global.Submit')}
          </Button>
        </>
      ),
    });

    const handleFlagValidation = async (formData) => {
      const apiResponse = await handleExportField(formData, fieldDeepCloned);

      if (apiResponse) {
        if (apiResponse.status < 200 || apiResponse.status >= 300) {
          setDialogConfig(
            formFlagElement(
              {
                id: { __errors: [apiResponse.data.flash.message] },
              },
              formData
            )
          );
        } else {
          setDialogOpen(false);
          setAtomSnackbar((prevAtomSnackbar) => ({
            ...prevAtomSnackbar,
            open: true,
            text: apiResponse.data.flash.message,
            severity: 'success',
          }));
        }
      } else {
        setAtomSnackbar((prevAtomSnackbar) => ({
          ...prevAtomSnackbar,
          open: true,
          text: 'An error occured',
          severity: 'error',
        }));
      }
    };

    setDialogConfig(formFlagElement({}, {}));
    setDialogOpen(true);
  }; // fin flagElement

  return flagElement;
};

export default exportAction;
