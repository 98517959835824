function safelyParseJSON(json) {
  let parsed;

  try {
    if (typeof json === 'string') {
      parsed = JSON.parse(json);
    } else {
      parsed = json;
    }
  } catch (e) {
    parsed = json;
    // Oh well, but whatever...
  }

  return parsed;
}

export default safelyParseJSON;
