/**
 * CTF: Formulaire Autocomplétion
 *
 * Dans le cadre de ce challenge, le jsonSchema devrait ressembler à quelque chose comme ça:
 *
 * {
 *   type: "object",
 *   required: [
 *   ],
 *   properties: {
 *     neolinkMetiers: {
 *       type: "string",
 *       "@source": "neolinkMetiers",
 *       "@dynamicData": {
 *         method: "POST",
 *         params: [
 *           {
 *             key: "filter",
 *             value: "@filter",
 *             required: true,
 *           },
 *         ],
 *         url: "https://www.bossetvous.fr/api/referentiels/offreEmploiSaisieRome",
 *         search: true,
 *         minimumChar: true,
 *         stringLength: 3,
 *         schemaJsonPath: "$.resultats[*]",
 *         bindKeysTo: "code_appellation",
 *         bindValuesTo: "autocompletion",
 *         "@dependentParams": false,
 *         "@source": "neolinkMetiers",
 *       },
 *       "@verificatorData": {
 *         method: "GET",
 *         "@dependentParams": false,
 *       },
 *       title: "Métier recherché",
 *       description: "Saisissez au moins 3 caractères pour lancer la recherche",
 *     },
 *   },
 * }
 */
export default ({ jsonSchema }) => {
  let flagName = null;

  if (jsonSchema?.properties?.neolinkMetiers) {
    const dependency = jsonSchema.properties.neolinkMetiers;

    if (
      dependency &&
      dependency?.['@dynamicData'] &&
      dependency?.['@dynamicData']?.method === 'POST' &&
      dependency?.['@dynamicData']?.params &&
      dependency?.['@dynamicData']?.params.length === 1 &&
      dependency?.['@dynamicData']?.params[0]?.key === 'filter' &&
      dependency?.['@dynamicData']?.params[0]?.value === '@filter' &&
      dependency?.['@dynamicData']?.params[0]?.required === true &&
      dependency?.['@dynamicData']?.url ===
        'https://www.bossetvous.fr/api/referentiels/offreEmploiSaisieRome' &&
      dependency?.['@dynamicData']?.search === true &&
      dependency?.['@dynamicData']?.minimumChar === true &&
      dependency?.['@dynamicData']?.stringLength === 3 &&
      dependency?.['@dynamicData']?.schemaJsonPath === '$.resultats[*]' &&
      dependency?.['@dynamicData']?.bindKeysTo === 'code_appellation' &&
      dependency?.['@dynamicData']?.bindValuesTo === 'autocompletion'
    ) {
      flagName = 'dynamicAutocompletion';
    }
  }

  return flagName;
};
