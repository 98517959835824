import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEqual, get } from 'lodash';
import SaveIcon from '@material-ui/icons/Save';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import { useSetRecoilState } from 'recoil';
import snackbarAtom from '../../Atoms/snackbarAtom';

import FormEngineSchema from '../../Schemas/FormEngineSchema';
import findPathById from '../../Utils/objectManipulation/findPathById';
import SimpleTabs from '../../Components/SimpleTabs';
import NeoBuilder from '../../Components/NeoForm/NeoBuilder';
import NeoFormAccess from '../../Components/NeoForm/NeoFormAccess';
import NeoFormPreview from '../../Components/NeoForm/NeoFormPreview';
import NeoFormRevision from '../../Components/NeoForm/NeoFormRevision';
import NeoSpeedDial from '../../Components/NeoButtons/SpeedDial';
import FormEmbedView from '../FormEmbedView';

const NeoFormBuilderStyle = makeStyles(() => ({
  'alert-unsaved': {
    marginBottom: 8,
  },
}));

const parseFormFields = (menu) => {
  const formattedMenu = {
    id: 'fieldsList',
    class: 'small-menu',
    title: 'Cliquez et déposez un champ',
    draggable: true,
    elements: [],
  };
  if (menu) {
    Object.entries(menu).forEach(([_key, group]) => {
      if (group && group.length) {
        group.map((field) => {
          formattedMenu.elements.push(field);
          return null;
        });
      }
    });
  }

  return formattedMenu;
};

const FormBuilderView = ({
  fields,
  formId,
  formName,
  formPublication,
  formSchema,
  selected,
  actions,
}) => {
  const classes = NeoFormBuilderStyle();
  const { t } = useTranslation();

  const setAtomSnackbar = useSetRecoilState(snackbarAtom);
  const [BuilderSchema, setBuilderSchema] = useState(formSchema);
  const [localMetaSpec, setLocalMetaSpec] = useState({
    ...FormEngineSchema,
    formName,
    menus: { fieldsList: parseFormFields(fields), formBuilder: BuilderSchema },
  });
  const [builderZoom, setBuilderZoom] = useState(false);
  const [unsavedChange, setUnsavedChange] = useState(false);
  const [savedBuilderSchema, setSavedBuilderSchema] = useState(
    cloneDeep(BuilderSchema)
  );
  const [savedFormName, setSavedFormName] = useState(formName);

  const {
    handleExportField,
    handlePreExportField,
    handleSaveBuilder,
    handlePublishVersion,
    getFormRevision,
    getBuilderSchema,
    payloadUri,
    getToken,
    importFieldBuilderSchemas,
    unlockFlag,
  } = actions;

  const { formPublished, setFormPublished } = formPublication;

  useEffect(() => {
    if (localMetaSpec?.menus?.formBuilder) {
      setBuilderSchema(localMetaSpec?.menus?.formBuilder);

      if (builderZoom?.blueprint?.id) {
        const builderIdPath = findPathById(
          localMetaSpec,
          builderZoom.blueprint.id
        );
        if (builderIdPath) {
          // on supprime les 2 derniers éléments : blueprint.id
          builderIdPath.splice(-2, 2);
          const builderObject = get(localMetaSpec, builderIdPath);
          setBuilderZoom(builderObject);
        } else {
          setBuilderZoom(false);
        }
      }

      if (!isEqual(savedBuilderSchema, localMetaSpec.menus.formBuilder)) {
        if (!unsavedChange) setUnsavedChange(true);
      } else if (unsavedChange) setUnsavedChange(false);
    }
  }, [localMetaSpec.updateTimestamp]);

  const handleSubmit = (result, Schema) => {
    console.log('Schema :', Schema);
    console.log('Result :', result);
    // eslint-disable-next-line no-alert
    alert(t('NeoBuilder.Preview.NotSaved'));
  };

  const saveJsonSchemaAsFile = (filename, jsonToWrite) => {
    const blob = new Blob([JSON.stringify(jsonToWrite)], { type: 'text/json' });
    const link = document.createElement('a');

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ['text/json', link.download, link.href].join(
      ':'
    );

    const evt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    link.dispatchEvent(evt);

    setAtomSnackbar((prevAtomSnackbar) => ({
      ...prevAtomSnackbar,
      open: true,
      text: t('Snackbar.exportForm'),
      severity: 'success',
    }));

    unlockFlag('onExportForm', { method: 'export' });
  };

  const getFileAsJsonSchema = (content) => {
    const fileJson = JSON.parse(content);

    const cpySchema = cloneDeep(localMetaSpec);
    cpySchema.menus.formBuilder = fileJson;
    cpySchema.updateTimestamp = Date.now();
    setLocalMetaSpec(cpySchema);

    setAtomSnackbar((prevAtomSnackbar) => ({
      ...prevAtomSnackbar,
      open: true,
      text: t('Snackbar.importForm'),
      severity: 'success',
    }));

    unlockFlag('onExportForm', { method: 'import' });
  };

  const handleSaveBuilderView = (newMetaSpec) => {
    const data = {
      name: newMetaSpec.formName,
      schema: newMetaSpec.menus.formBuilder,
    };
    handleSaveBuilder(data);
    setSavedBuilderSchema(cloneDeep(newMetaSpec.menus.formBuilder));
    setSavedFormName(cloneDeep(newMetaSpec.formName));
    setUnsavedChange(false);
  };

  return (
    <>
      {unsavedChange && (
        <Alert
          className={classes['alert-unsaved']}
          severity='warning'
          variant='filled'
        >
          {t('NeoBuilder.Edit.UnsavedContent')}
        </Alert>
      )}
      {!unsavedChange && !formPublished && (
        <Alert className={classes['alert-unsaved']} severity='info'>
          {t('NeoBuilder.Edit.DraftVersion')}
        </Alert>
      )}
      <SimpleTabs
        selected={selected}
        tabs={[
          {
            name: t('NeoBuilder.Tabs.Edit'),
            component: (
              <NeoBuilder
                builderZoomParent={{ builderZoom, setBuilderZoom }}
                handleExportField={handleExportField}
                handlePreExportField={handlePreExportField}
                handleSaveBuilder={handleSaveBuilderView}
                importFieldBuilderSchemas={importFieldBuilderSchemas}
                localMetaSpecParent={{ localMetaSpec, setLocalMetaSpec }}
                setBuilderSchema={setBuilderSchema}
                unsaved={{
                  unsavedChange,
                  setUnsavedChange,
                  savedBuilderSchema,
                  setSavedBuilderSchema,
                  savedFormName,
                  setSavedFormName,
                }}
              />
            ),
          },
          {
            name: t('NeoBuilder.Tabs.Preview'),
            component: (
              <NeoFormPreview
                BuilderSchema={BuilderSchema}
                formId={formId}
                handleSubmit={handleSubmit}
                roles={BuilderSchema?.roles}
              />
            ),
          },
          {
            name: t('NeoBuilder.Tabs.Revisions'),
            component: (
              <NeoFormRevision
                formId={formId}
                getBuilderSchema={getBuilderSchema}
                getFormRevision={getFormRevision}
                handlePublishVersion={handlePublishVersion}
                localMetaSpecParent={{ localMetaSpec, setLocalMetaSpec }}
                setFormPublished={setFormPublished}
              />
            ),
          },
          {
            name: t('NeoBuilder.Tabs.Embed'),
            component: (
              <FormEmbedView
                formId={formId}
                getToken={getToken}
                uri={payloadUri()}
              />
            ),
          },
          {
            name: t('NeoBuilder.Tabs.Access'),
            component: (
              <NeoFormAccess
                handleSaveBuilder={handleSaveBuilderView}
                localMetaSpecParent={{ localMetaSpec, setLocalMetaSpec }}
                rolesReceived={BuilderSchema?.roles}
                setBuilderSchema={setBuilderSchema}
                unsaved={{
                  unsavedChange,
                  setUnsavedChange,
                  savedBuilderSchema,
                  setSavedBuilderSchema,
                }}
              />
            ),
          },
        ]}
      />
      <NeoSpeedDial
        actions={[
          {
            icon: <SaveIcon />,
            name: t('NeoBuilder.Edit.Save'),
            onClick: () => {
              handleSaveBuilder(BuilderSchema);
              setSavedBuilderSchema(cloneDeep(BuilderSchema));
              setUnsavedChange(false);
            },
          },
          {
            icon: <PublishIcon />,
            name: t('NeoBuilder.Edit.Export'),
            onClick: () =>
              saveJsonSchemaAsFile(
                `${formName}_${new Date()}.json`,
                BuilderSchema
              ),
          },
          {
            icon: <GetAppIcon />,
            name: t('NeoBuilder.Edit.Import'),
            getFile: (content) => getFileAsJsonSchema(content),
          },
        ]}
        icon={<MoreVertIcon />}
      />
    </>
  );
};

FormBuilderView.propTypes = {
  fields: PropTypes.object.isRequired,
  formId: PropTypes.string,
  formName: PropTypes.string,
  formPublication: PropTypes.shape({
    formPublished: PropTypes.bool,
    setFormPublished: PropTypes.func,
  }),
  formSchema: PropTypes.object,
  selected: PropTypes.number,
  actions: PropTypes.shape({
    handleExportField: PropTypes.func,
    handlePreExportField: PropTypes.func,
    handleSaveBuilder: PropTypes.func,
    handlePublishVersion: PropTypes.func,
    getFormRevision: PropTypes.func,
    getBuilderSchema: PropTypes.func,
    payloadUri: PropTypes.func,
    getToken: PropTypes.func,
    importFieldBuilderSchemas: PropTypes.func,
    unlockFlag: PropTypes.func,
  }),
};

export default FormBuilderView;
