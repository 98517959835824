const findByKey = (tree, x, results = []) => {
  const r = results;
  if (typeof tree !== 'undefined' && tree !== null) {
    Object.keys(tree).forEach((key) => {
      const node = tree[key];
      if (typeof node !== 'undefined' && key === x) {
        r.push(node);
      }

      if (typeof node === 'object') {
        findByKey(node, x, r);
      }
    });
  }
  return r;
};

export default findByKey;
