/* eslint-disable no-constant-condition */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
// Test FormBuilder
import React, { useEffect } from 'react';
import { cloneDeep } from 'lodash';

import { useRecoilValue } from 'recoil';
import currentFormAtom from '../../../../../Atoms/currentForm';

import SimpleTabs from '../../../../SimpleTabs';

const FieldSimpleTabs = (props) => {
  const { schema, uiSchema, registry, formData, onChange, errorSchema } = props;
  const { SchemaField } = registry.fields;
  const currentForm = useRecoilValue(currentFormAtom);

  // console.log(schema);
  // console.log(uiSchema);

  useEffect(() => {}, [
    currentForm.deepDependencies.updatedTimestamp,
    currentForm.dynamicData.updatedTimestamp,
  ]);

  let tabs;
  if (schema && schema.properties) {
    tabs = Object.entries(schema.properties).map((el, index) => {
      // console.log(el);
      let id;
      let element;
      [id, element] = el;

      const { title } = element;

      const clonedElement = cloneDeep(element);
      clonedElement.title = '';

      const onChangeFieldSimpleTabs = (e) => {
        const cloneFormData = cloneDeep(formData);
        cloneFormData[id] = e;
        onChange(cloneFormData, errorSchema);
      };

      return {
        name: title,
        component: (
          <div key={index} name={title}>
            <SchemaField
              {...props}
              formData={(formData || {})[id]}
              registry={registry}
              schema={clonedElement}
              uiSchema={uiSchema[id]}
              validate={() => {}}
              onChange={onChangeFieldSimpleTabs}
            />
          </div>
        ),
      };
    });
  }

  let previousNext = false;
  if (
    uiSchema?.['ui:options']?.previousNextbuttons &&
    uiSchema?.['ui:options']?.previousTitle &&
    uiSchema?.['ui:options']?.nextTitle
  ) {
    previousNext = {
      previousTitle: uiSchema['ui:options'].previousTitle,
      nextTitle: uiSchema['ui:options'].nextTitle,
    };
  }

  const renderField = () => {
    if (tabs && tabs.length > 0) {
      return (
        <SimpleTabs
          parentHandleChange={() => {
            onChange(formData, errorSchema);
          }}
          previousNext={previousNext}
          tabs={tabs}
        />
      );
    }
    return null;
  };

  return renderField();
};

export default FieldSimpleTabs;
