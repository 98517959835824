import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import axios from 'axios';
import NeoFormUse from '../NeoFormUse';
import NeoFormSkeleton from '../NeoFormSkeleton';
import uuidV4RegEx from '../../../Utils/regex/uuidv4';

const NeoFormHosted = ({
  callback,
  uri,
  id,
  handleSubmit,
  formData,
  submitName,
  handleChange = null,
  handleValidation = null,
}) => {
  const conditionalProps = {
    ...(id && { id }),
    ...(handleChange && { handleChange }),
    ...(handleValidation && {
      handleValidation,
    }),
  };

  const [data, setData] = useState({});
  const [uriValidation, setUriValidation] = useState({
    state: true,
    errors: null,
  });

  /**
   * Check if an URI is valid and complete. To do so, it should have an UUID v4 as the last part
   * of the path, and a non empty role and version in the query params.
   *
   * @param {string} localUri - URI to check
   *
   * @returns {Object} Return an object with 2 properties : state and errors.
   *  The `state` property is a boolean, which value is true if the URI is valid and complete,
   *    otherwise false.
   *  The `errors` property is an object, with properties corresponding with every part of the
   *    URI that are needed : an uuid path, and 2 query params (role and version). Each value
   *    is a boolean that indicates which part is missing (value false), or not (value true).
   *    Could be null if state is true.
   */
  const checkUri = (localUri) => {
    let uuidIsOk = false;
    let paramsAreOk = false;
    let paramsError = {};

    if (localUri) {
      const localUrl = new URL(localUri);

      // Check for uuid
      const shouldBeUuid = localUrl.pathname.split('/').pop();
      if (shouldBeUuid.match(uuidV4RegEx)) {
        uuidIsOk = true;
      }

      // Check for role and version parameters
      const params = localUrl.searchParams;
      const role = params.has('role') ? params.get('role') : null;
      const version = params.has('version') ? params.get('version') : null;

      if (role && (version || version === 0)) {
        paramsAreOk = true;
      }

      paramsError = {
        role: !!role,
        version: !!version,
      };
    }

    const uriIsOk = uuidIsOk && paramsAreOk;

    return {
      state: uriIsOk,
      errors: uriIsOk
        ? null
        : {
            uuid: uuidIsOk,
            ...paramsError,
          },
    };
  };

  // console.log(`URI : ${uri}`);
  useEffect(() => {
    // check if URI exist and is complete
    if (uri) {
      const result = checkUri(uri);
      // console.log('checkUri : ', result);

      if (result.state) {
        const ApiClient = axios.create({
          maxContentLength: 20971520,
          maxBodyLength: 20971520,
        });

        ApiClient.request({
          url: uri,
        })
          .then(async (response) => {
            setData(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // bad uri
        setUriValidation(result);
      }
    }
  }, [uri]);

  const addFormInfoInData = (result, schema) => {
    // eslint-disable-next-line no-param-reassign
    let ResultWithMeta = result;
    ResultWithMeta = {
      ...result,
      '@uri': uri,
      '@id': data?.id,
      '@version': data?.version,
      '@role': data?.role,
    };
    handleSubmit(ResultWithMeta, schema);
  };

  /**
   * Show URI errors message
   */
  const showUriErrors = () => {
    const paramsState = [
      `<li>UUID : ${uriValidation.errors.uuid ? 'OK' : 'NOK'}</li>`,
      `<li>role : ${uriValidation.errors.role ? 'OK' : 'NOK'}</li>`,
      `<li>version : ${uriValidation.errors.version ? 'OK' : 'NOK'}</li>`,
    ];

    return (
      <Alert severity='error'>
        <AlertTitle>Error</AlertTitle>
        The provided URI is not valid :<ul>{paramsState}</ul>
      </Alert>
    );
  };

  return data?.jsonSchema ? (
    <NeoFormUse
      customSubmit={
        <Button color='primary' type='submit' variant='contained'>
          {submitName}
        </Button>
      }
      extensions={['rating', 'literary', 'deepDependencies', 'dynamicData']}
      formAccess={data?.formAccess || { read: true, write: true }}
      formDataReceived={formData}
      handleSubmit={addFormInfoInData}
      JsonSchema={data?.jsonSchema || {}}
      muiTheme={data?.muiTheme || {}}
      UiSchema={data?.uiSchema || {}}
      {...conditionalProps}
      callback={callback}
    />
  ) : uriValidation.state ? (
    <NeoFormSkeleton />
  ) : (
    showUriErrors()
  );
};

NeoFormHosted.defaultProps = {
  callback: () => {},
  formData: {},
  submitName: 'SUBMIT',
  handleChange: null,
  handleValidation: null,
  id: '',
};

NeoFormHosted.propTypes = {
  callback: PropTypes.func,
  uri: PropTypes.string.isRequired,
  id: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  handleValidation: PropTypes.func,
  formData: PropTypes.object,
  submitName: PropTypes.string,
};

export default NeoFormHosted;
