import React from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';
import NeoBrowser from '../../Components/NeoBrowser';
import snackbarAtom from '../../Atoms/snackbarAtom';
// CTFs sur les formulaires
import flagAtom from '../../Atoms/flagAtom';
import {
  ctfVerificatorOnCreateProduct,
  ctfVerificatorOnEditProduct,
  ctfVerificatorOnSaveForm,
  ctfVerificatorOnExportForm,
  ctfVerificatorOnPublishForm,
  ctfVerificatorOnExportField,
} from '../../Utils/ctf';

const NeoBrowserView = ({ apiUserService }) => {
  const setAtomFlag = useSetRecoilState(flagAtom);
  const setAtomSnackbar = useSetRecoilState(snackbarAtom);

  const showFlash = (flash) => {
    setAtomSnackbar((prevAtomSnackbar) => ({
      ...prevAtomSnackbar,
      open: true,
      text: flash.message,
      severity: flash.status,
    }));
  };

  const unlockFlag = async (category, params) => {
    if (apiUserService.getRealm() === 'CTF') {
      let flagName = null;

      const newParams = {
        ...params,
        authUserService: apiUserService,
      };

      switch (category) {
        case 'onCreateProduct':
          flagName = ctfVerificatorOnCreateProduct(newParams);
          break;

        case 'onEditProduct':
          flagName = ctfVerificatorOnEditProduct(newParams);
          break;

        case 'onSaveForm':
          flagName = await ctfVerificatorOnSaveForm(newParams);
          break;

        case 'onExportForm':
          flagName = ctfVerificatorOnExportForm(newParams);
          break;

        case 'onPublishForm':
          flagName = ctfVerificatorOnPublishForm(newParams);
          break;

        case 'onExportField':
          flagName = ctfVerificatorOnExportField(newParams);
          break;

        default:
          flagName = null;
          break;
      }

      if (flagName) {
        setAtomFlag((prevAtomFlag) => ({
          ...prevAtomFlag,
          fire: true,
          currentChallenge: flagName,
        }));
      }
    }
  };

  return (
    <NeoBrowser
      authUserService={apiUserService}
      flag={unlockFlag}
      flash={showFlash}
    />
  );
};

NeoBrowserView.propTypes = {
  apiUserService: PropTypes.object,
};

export default NeoBrowserView;
