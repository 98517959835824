import { cloneDeep } from 'lodash';
import findPath from './findPathById';

const findYByX = (tree, x, y, results = [], originalTree = tree) => {
  const r = results;
  if (typeof tree !== 'undefined' && tree !== null) {
    Object.keys(tree).forEach((key) => {
      const node = tree[key];
      if (node && node[x] === y) {
        r.push({ content: node, path: findPath(originalTree, y, x) });
        // eslint-disable-next-line no-param-reassign
        tree[key] = {};
      }

      if (typeof node === 'object') {
        findYByX(node, x, y, r, originalTree);
      }
    });
  }
  return r;
};

const init = (tree, x, y) => {
  const clonedTree = cloneDeep(tree);
  return findYByX(clonedTree, x, y);
};

export default init;
