import { get } from 'lodash';
import moment from 'moment';

const checkPosteriorDate = (
  object,
  path,
  formData,
  originalFormdata,
  assignError
) => {
  let date = null;

  let customErrorMessage = object.posteriorToDateErrorMessage || null;

  if (object.posteriorToDateType === 'datenow') {
    date = moment(new Date(), 'YYYY-MM-DD', true).startOf('day');
    if (
      object?.posteriorToDateAdd === true &&
      object?.posteriorToDateAddNumber &&
      object?.posteriorToDateAddUnit
    ) {
      date = moment(date, 'YYYY-MM-DD', true).add(
        object.posteriorToDateAddNumber,
        object.posteriorToDateAddUnit
      );
    }
  } else if (
    object.posteriorToDateType === 'fieldDate' &&
    object?.posteriorFieldDate &&
    path
  ) {
    const arrayPath = path.split('.');

    if (arrayPath.length > 0) {
      arrayPath[arrayPath.length - 1] = object.posteriorFieldDate;
    }

    const posteriorFieldFormDataAnswer = get(
      originalFormdata,
      arrayPath.join('.')
    );

    date = moment(posteriorFieldFormDataAnswer, 'YYYY-MM-DD', true);
    if (
      object?.posteriorToDateAdd === true &&
      object?.posteriorToDateAddNumber &&
      object?.posteriorToDateAddUnit
    ) {
      date = moment(date, 'YYYY-MM-DD', true).add(
        object?.posteriorToDateAddNumber,
        object?.posteriorToDateAddUnit
      );
    }
  } else if (
    object.posteriorToDateType === 'fixeddate' &&
    object?.posteriorDefaultDate
  ) {
    date = moment(object?.posteriorDefaultDate, 'YYYY-MM-DD', true);
  }

  if (customErrorMessage !== null) {
    customErrorMessage = customErrorMessage.replace(
      '@dateDeReference',
      date.format('DD/MM/YYYY')
    );
  }

  if (date === null || !date.isValid()) {
    assignError(
      path,
      object,
      formData,
      `Format de date de référence incorrect.`
    );
  } else {
    const dateAfter = moment(formData, 'YYYY-MM-DD', true);
    if (!dateAfter.isValid()) {
      assignError(path, object, formData, `Format de date incorrect.`);
    } else if (
      date.isAfter(dateAfter, 'day') &&
      date.format('YYYY-MM-DD') !== dateAfter.format('YYYY-MM-DD')
    ) {
      assignError(
        path,
        object,
        formData,
        customErrorMessage ||
          `La date doit être postérieure ou égale au ${date.format(
            'DD/MM/YYYY'
          )}.`
      );
    }
  }
};

const checkPriorDate = (
  object,
  path,
  formData,
  originalFormdata,
  assignError
) => {
  let date = null;

  let customErrorMessage = object.priorToDateErrorMessage || null;

  if (object.priorToDateType === 'datenow') {
    date = moment(new Date(), 'YYYY-MM-DD', true).startOf('day');
    if (
      object?.priorToDateAdd === true &&
      object?.priorToDateAddNumber &&
      object?.priorToDateAddUnit
    ) {
      date = moment(date, 'YYYY-MM-DD', true).add(
        object.priorToDateAddNumber,
        object.priorToDateAddUnit
      );
    }
  } else if (
    object.priorToDateType === 'fieldDate' &&
    object?.priorFieldDate &&
    path
  ) {
    const arrayPath = path.split('.');

    if (arrayPath.length > 0) {
      arrayPath[arrayPath.length - 1] = object.priorFieldDate;
    }

    const priorFieldFormDataAnswer = get(originalFormdata, arrayPath.join('.'));

    date = moment(priorFieldFormDataAnswer, 'YYYY-MM-DD', true);
    if (
      object?.priorToDateAdd === true &&
      object?.priorToDateAddNumber &&
      object?.priorToDateAddUnit
    ) {
      date = moment(date, 'YYYY-MM-DD', true).add(
        object?.priorToDateAddNumber,
        object?.priorToDateAddUnit
      );
    }
  } else if (
    object.priorToDateType === 'fixeddate' &&
    object?.priorDefaultDate
  ) {
    date = moment(object?.priorDefaultDate, 'YYYY-MM-DD', true);
  }

  if (customErrorMessage !== null) {
    customErrorMessage = customErrorMessage.replace(
      '@dateDeReference',
      date.format('DD/MM/YYYY')
    );
  }

  if (date === null || !date.isValid()) {
    assignError(
      path,
      object,
      formData,
      `Format de date de référence incorrect.`
    );
  } else {
    const dateMax = moment(formData, 'YYYY-MM-DD', true);
    if (!dateMax.isValid()) {
      assignError(path, object, formData, `Format de date incorrect.`);
    } else if (
      date.isBefore(dateMax, 'day') &&
      date.format('YYYY-MM-DD') !== dateMax.format('YYYY-MM-DD')
    ) {
      assignError(
        path,
        object,
        formData,
        customErrorMessage ||
          `La date doit être antérieure ou égale au ${date.format(
            'DD/MM/YYYY'
          )}.`
      );
    }
  }
};

const checkDate = (object, path, formData, originalFormdata, assignError) => {
  // Format de la date à respecter (non implémenté pour le moment)
  if (object?.dateFormat) {
    const date = moment(formData, object.dateFormat, true);
    if (!date.isValid()) {
      assignError(path, object, formData, `Format de date incorrect.`);
    }
  }
  // Vérification date postérieure à la date de référence
  if (object?.posteriorToDate === true && object?.posteriorToDateType) {
    checkPosteriorDate(object, path, formData, originalFormdata, assignError);
  }

  // Vérification date antérieur à la date de référence
  if (object?.priorToDate === true && object?.priorToDateType) {
    checkPriorDate(object, path, formData, originalFormdata, assignError);
  }
};

export default checkDate;
