import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  '@global': {
    '.GP-NeoInvisible': {
      display: 'none',
    },
    '.GP-NeoVisible': {
      display: 'block',
    },
  },
}));

const Invisible = () => {
  const classes = useStyles();
  return <div className={`NeoInvisible ${classes.root}`} />;
};

export default Invisible;
