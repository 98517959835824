import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useTranslation } from 'react-i18next';

import withUseResource from './Hoc/withUseResource';
import PopupDialog from '../../PopupDialog';
import SimpleForm from './SimpleForm';
import ApiClient from '../Utils/ApiClient';

const SimpleAddProduct = ({
  resource,
  resourceData,
  resourceState,
  catalog,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({});
  const [request, setRequest] = useState(null);
  const { t } = useTranslation();
  const submitFormRef = createRef();

  useEffect(() => {
    // request must contain, at least, an <url> property.
    // By default, request will be a 'GET', if none other
    // method is specified by a <method> property
    setRequest(resourceData.request);

    return () => {
      setRequest(null);
    };
  }, [resourceData]);

  const onValidate = (response) => {
    // console.log(response);
    if (response.status === 201) {
      setDialogOpen(false);
      catalog.add(response.data._links.self);
    }

    if (response?.data?.flash) {
      catalog.flash(response.data.flash);
    }

    catalog.flag('onCreateProduct', { createResponse: response });
  };

  const onError = (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      if (error.response?.data?.flash) {
        catalog.flash(error.response.data.flash);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
      catalog.flash({
        status: 'error',
        message: 'No response received',
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message);
      catalog.flash({
        status: 'error',
        message: error?.message || 'Unexpected error',
      });
    }
    // console.log(error.config);
  };

  const handleSubmit = (fd) => {
    if (request && request.url && fd) {
      ApiClient.request({
        ...request,
        data: fd,
        headers: {
          Authorization: `Bearer ${catalog.authUserService.getToken()}`,
        },
      })
        .then((response) => {
          onValidate(response);
        })
        .catch((error) => {
          onError(error);
        });
    }
  };

  useEffect(() => {
    if (resourceState.links.has('create-form')) {
      setDialogConfig({
        title: '',
        content: (
          <SimpleForm
            handleSubmit={handleSubmit}
            resource={resource.follow('create-form')}
            submitFormRef={submitFormRef}
          />
        ),
        actions: (
          <>
            <Button
              color='primary'
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              {t('Global.Cancel')}
            </Button>
            <Button
              color='primary'
              onClick={() => {
                submitFormRef.current.click();
              }}
            >
              {t('Global.Submit')}
            </Button>
          </>
        ),
      });
    }

    return () => {
      setDialogConfig({});
    };
  }, [dialogOpen]);

  return (
    <>
      <Grid item>
        <Card>
          <CardActionArea onClick={() => setDialogOpen(true)}>
            <CardContent>
              <AddCircleIcon />
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <PopupDialog
        config={dialogConfig}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

const AddProduct = withUseResource(SimpleAddProduct);

SimpleAddProduct.propTypes = {
  resource: PropTypes.object,
  resourceData: PropTypes.object,
  resourceState: PropTypes.object,
  catalog: PropTypes.object,
};

export default AddProduct;
