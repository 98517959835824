import { isEqual } from 'lodash';

const reducePath = (path) => {
  let lastElements = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < path.length; i++) {
    if (path[i] === 'elements') {
      lastElements = i;
    }
  }
  const newPath = path.slice(0, lastElements);
  return newPath;
};

const duplicateWithSameParent = (objectFounds) => {
  let returnValue = false;
  if (objectFounds.length > 0) {
    objectFounds.map((objectFound, index) => {
      if (objectFound?.path) {
        const reducedPath = reducePath(objectFound?.path);

        objectFounds.map((other, otherIndex) => {
          if (other?.path) {
            const reducedOtherPath = reducePath(other?.path);

            if (
              reducedPath.length === reducedOtherPath.length &&
              isEqual(reducedPath, reducedOtherPath) &&
              !isEqual(objectFound?.path, other?.path) &&
              index !== otherIndex
            ) {
              returnValue = true;
            }
          }
          return null;
        });
      }
      return null;
    });
  }
  return returnValue;
};

export default duplicateWithSameParent;
