import { makeStyles } from '@material-ui/core/styles';

const NeoFormHostedStyle = makeStyles(() => ({
  skeletonTitle: {
    marginTop: 32,
    marginBottom: 8,
    marginLeft: 8,
    marginRight: 8,
  },
  skeletonField: {
    marginBottom: 32,
    marginLeft: 8,
    marginRight: 8,
  },
  skeletonButton: {
    marginTop: 64,
    marginBottom: 32,
    marginLeft: 8,
    marginRight: 8,
  },
}));

export default NeoFormHostedStyle;
