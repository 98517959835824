import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Link } from '@material-ui/core';

const FieldAlertMessage = ({ schema }) => {
  const { title, description } = schema;

  const checkLink = (element) => {
    // Regex de recherche des liens
    const linkRegex = /(<a\s+[^>]*>.*?<\/a>)/gi;
    // Regex de capture des différentes composantes d'un lien
    const linkFragmentsRegex = /(<a\s+[^>]*>)(.*?)(<\/a>)/gi;
    // Regex de capture des différents attributs d'un lien et de leurs valeurs
    const attrRegex = /(\w+)=("[^"]*")/gi;

    // Recherche de lien
    const links = element.match(linkRegex);

    if (links?.length) {
      // Au moins un lien est présent, on décompose l'élément
      const splittedElement = element.split(linkRegex).filter(Boolean);
      // Ensemble des parties constituant le nouvel element
      const newElement = [];

      splittedElement.map((fragment) => {
        if (fragment.match(linkRegex)) {
          // eslint-disable-next-line no-unused-vars
          const [openTag, tagContent, _closeTag] = fragment
            .split(linkFragmentsRegex)
            .filter(Boolean);

          let href = '';
          let target = '';

          let attribute;
          // eslint-disable-next-line no-cond-assign
          while ((attribute = attrRegex.exec(openTag)) !== null) {
            // eslint-disable-next-line no-unused-vars
            const [_attr, key, value] = attribute;

            switch (key) {
              case 'href':
                // suppression des " en début et fin
                href = value.slice(1, -1);
                break;

              case 'target':
                target = value;
                break;

              default:
                break;
            }
          }

          newElement.push(
            target ? (
              <Link href={href} target={target}>
                {tagContent}
              </Link>
            ) : (
              <Link href={href}>{tagContent}</Link>
            )
          );
        } else {
          newElement.push(fragment);
        }
        return null;
      });
      return newElement;
    }
    return element;
  };

  return (
    <Alert severity={title} variant='filled'>
      {description
        .split('***')
        .map((element, index) =>
          index % 2 ? (
            <strong key={index}>{checkLink(element)}</strong>
          ) : (
            checkLink(element)
          )
        )}
    </Alert>
  );
};

FieldAlertMessage.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default FieldAlertMessage;
