import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role='tabpanel'
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const SimpleTabs = ({ tabs, selected, previousNext, parentHandleChange }) => {
  const mySimpleTabsRef = createRef();
  const classes = useStyles();
  const [value, setValue] = React.useState(selected || 0);

  const scrollToTopOfTabs = () => {
    mySimpleTabsRef.current.scrollIntoView();
  };

  const handleChange = (event, newValue) => {
    parentHandleChange();
    setValue(newValue);
  };

  const setSelectedTab = (newValue) => {
    setValue(newValue);
  };

  const previousNextButtons = () => {
    if (previousNext === false) return null;
    return (
      <Grid container spacing={2}>
        <Grid item>
          <Button
            {...(value === 0 && { disabled: true })}
            color='primary'
            variant='contained'
            onClick={() => {
              parentHandleChange();
              setSelectedTab((value + tabs.length - 1) % tabs.length);
              scrollToTopOfTabs();
            }}
          >
            {previousNext?.previousTitle ? previousNext.previousTitle : 'Back'}
          </Button>
        </Grid>
        <Grid item>
          <Button
            {...(value === tabs.length - 1 && { disabled: true })}
            color='primary'
            variant='contained'
            onClick={() => {
              parentHandleChange();
              setSelectedTab((value + 1) % tabs.length);
              scrollToTopOfTabs();
            }}
          >
            {previousNext?.nextTitle ? previousNext.nextTitle : 'Next'}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Tabs
          aria-label='simple tabs'
          ref={mySimpleTabsRef}
          scrollButtons='on'
          value={value}
          variant='scrollable'
          onChange={handleChange}
        >
          {tabs.map((tab, key) => (
            <Tab key={key} label={tab.name} {...a11yProps(key)} />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tab, key) => (
        <TabPanel index={key} key={key} value={value}>
          {tab.component}
        </TabPanel>
      ))}
      {previousNextButtons()}
    </div>
  );
};

SimpleTabs.defaultProps = {
  previousNext: false,
  parentHandleChange: () => {},
};

SimpleTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  selected: PropTypes.number,
  previousNext: PropTypes.any,
  parentHandleChange: PropTypes.func,
};

export default SimpleTabs;
