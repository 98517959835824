import React, { useState, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Confetti from 'react-confetti';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Grid } from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy';
import flagAtom from '../../Atoms/flagAtom';
import snackbarAtom from '../../Atoms/snackbarAtom';
import { FlagsNames, Flags } from '../../Utils/ctf/challenges';

const FlagPrinterStyle = makeStyles({
  flag: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 50,
  },
  copyButton: {
    marginLeft: 10,
  },
});

const FlagPrinter = () => {
  const { t } = useTranslation();
  const classes = FlagPrinterStyle();

  const [atomFlag, setAtomFlag] = useRecoilState(flagAtom);
  const setAtomSnackbar = useSetRecoilState(snackbarAtom);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setAtomFlag((prevAtomFlag) => ({
      ...prevAtomFlag,
      fire: false,
    }));
  };

  useEffect(() => {
    if (atomFlag?.fire === true) {
      setOpen(true);
    }
  }, [atomFlag?.fire]);

  if (atomFlag?.fire) {
    return (
      <>
        <Confetti />
        <Dialog
          aria-labelledby='simple-dialog-title'
          open={open}
          // onClose={handleClose}
        >
          <DialogTitle id='simple-dialog-title' justifyContent='center'>
            Challenge réussi : {FlagsNames[atomFlag?.currentChallenge]}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container className={classes.flag}>
                <Typography variant='body1'>
                  {Flags[atomFlag?.currentChallenge]}
                </Typography>
                <CopyToClipboard text={Flags[atomFlag?.currentChallenge]}>
                  <Button
                    className={classes.copyButton}
                    startIcon={<FileCopy />}
                    variant='contained'
                    onClick={() => {
                      setAtomSnackbar((prevAtomSnackbar) => ({
                        ...prevAtomSnackbar,
                        open: true,
                        text: t('Snackbar.CopiedToClipboard'),
                        severity: 'success',
                      }));
                    }}
                  >
                    Copier
                  </Button>
                </CopyToClipboard>
              </Grid>
            </DialogContentText>
            <DialogContentText>
              <Typography variant='caption'>
                Copiez et collez ce mot de passe dans le challenge correspondant
                sur{' '}
                <a href='https://ctf.neoform.neolink.link/'>
                  ctf.neoform.neolink.link
                </a>
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus color='primary' onClick={handleClose}>
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  return null;
};

export default FlagPrinter;
